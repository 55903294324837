import React, { FC, useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import { formatISO } from 'date-fns';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import { ExcelExportButton } from '../../organisms/ExcelExportButton';

const REQUEST_PAGE_SIZE = 1000;

type Props = {
    equipmentLength: number;
    sort: string;
    filter: string;
};

interface EquipmentListResponse {
    records: EquipmentListItemI[];
    totalRecords?: number;
    totalReplacementCost?: number;
    filters?: { [key: string]: string[] };
}

export const EquipmentExcelExport: FC<Props> = ({ equipmentLength, sort, filter }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setErrorMessage('');
    }, [equipmentLength]);

    const onDownload = async () => {
        try {
            setIsLoading(true);
            const totalPages = equipmentLength / REQUEST_PAGE_SIZE;
            const fetchPromises: Promise<EquipmentListResponse>[] = [];

            for (let i = 0; i < totalPages; i += 1) {
                const pageRequestConfig: CrewRequestConfig = {
                    headers: {
                        sort,
                        filter,
                        limit: REQUEST_PAGE_SIZE,
                        skip: i * REQUEST_PAGE_SIZE,
                    },
                    path: `equipment`,
                };

                fetchPromises.push(crewAPI.get<EquipmentListResponse>(pageRequestConfig));
            }

            const allEquipmentItems = (await Promise.all(fetchPromises)).flatMap(
                response => response.records
            );

            const sheet = xlsx.utils.json_to_sheet(allEquipmentItems);

            xlsx.writeFile(
                { Sheets: { Sheet1: sheet }, SheetNames: ['Sheet1'] },
                `crew_app_equipment_export_${formatISO(new Date())}.xlsx`
            );
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ExcelExportButton onClick={onDownload} isLoading={isLoading} errorMessage={errorMessage} />
    );
};
