const operationOptions: { [key: string]: Operation[] } = {
    varchar: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'string' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'string' } },
        { label: 'exists', value: 'exists', inputs: { number: 0 } },
        { label: 'does not exist', value: 'not_exists', inputs: { number: 0 } },
    ],
    char: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'string' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'string' } },
        { label: 'exists', value: 'exists', inputs: { number: 0 } },
        { label: 'does not exist', value: 'not_exists', inputs: { number: 0 } },
    ],
    date: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'date' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'date' } },
        { label: 'greater or equal to', value: 'ge', inputs: { number: 1, format: 'date' } },
        { label: 'less or equal to', value: 'le', inputs: { number: 1, format: 'date' } },
        { label: 'is between', value: 'between', inputs: { number: 2, format: 'date' } },
        { label: 'is not between', value: 'not_between', inputs: { number: 2, format: 'date' } },
    ],
    int: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'string' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'string' } },
        { label: 'greater or equal to', value: 'ge', inputs: { number: 1, format: 'number' } },
        { label: 'less or equal to', value: 'le', inputs: { number: 1, format: 'number' } },
        { label: 'is between', value: 'between', inputs: { number: 2, format: 'number' } },
        { label: 'is not between', value: 'not_between', inputs: { number: 2, format: 'number' } },
    ],
    datetime: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'datetime' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'datetime' } },
        { label: 'greater or equal to', value: 'ge', inputs: { number: 1, format: 'datetime' } },
        { label: 'less or equal to', value: 'le', inputs: { number: 1, format: 'datetime' } },
        { label: 'is between', value: 'between', inputs: { number: 2, format: 'datetime' } },
        {
            label: 'is not between',
            value: 'not_between',
            inputs: { number: 2, format: 'datetime' },
        },
    ],
    option: [{ label: 'Equals One Of', value: 'eq', inputs: { number: 1, format: 'options' } }],
    map: [
        { label: 'equals', value: 'eq', inputs: { number: 1, format: 'map' } },
        { label: 'not equals', value: 'not_eq', inputs: { number: 1, format: 'map' } },
    ],
};

export default operationOptions;

export interface Operation {
    label: string;
    value: string;
    inputs: {
        number: number;
        format?: string;
    };
}
