import * as appActions from './app/app.actions';
import * as authActions from './auth/auth.actions';
import * as buildingsActions from './buildings/buildings.actions';
import * as cityStateOverridesActions from './cityStateOverrides/cityStateOverrides.actions';
import * as dashboardActions from './dashboard/dashboard.actions';
import * as equipmentTemplateActions from './equipmentTemplate/equipmentTemplate.actions';
import * as equipmentActions from './equipment/equipment.actions';
import * as adminSettingsActions from './adminSettings/adminSettings.actions';
import * as inspectionsActions from './inspections/inspections.actions';
import * as mepActions from './mep/mep.actions';
import * as mepOverridesActions from './mepOverrides/mepOverrides.actions';
import * as optionsActions from './options/options.actions';
import * as userActions from './user/user.actions';

const actions = {
    app: appActions,
    auth: authActions,
    buildings: buildingsActions,
    cityStateOverrides: cityStateOverridesActions,
    dashboard: dashboardActions,
    equipment: equipmentActions,
    equipmentTemplate: equipmentTemplateActions,
    adminSettings: adminSettingsActions,
    inspections: inspectionsActions,
    mep: mepActions,
    mepOverrides: mepOverridesActions,
    options: optionsActions,
    user: userActions,
};

export default actions;
