/* eslint-disable no-param-reassign */
import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';
import { appRedirect } from 'store/app/app.actions';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import InspectionsTypes, { InspectionI } from './inspections.types';

interface InspectionsError {
    message: string;
    inspectionID: string;
}

export type InspectionsAction =
    | { type: typeof InspectionsTypes.GET_INSPECTION_FAIL; payload: InspectionsError }
    | { type: typeof InspectionsTypes.GET_INSPECTION_LOADING }
    | {
          type: typeof InspectionsTypes.GET_INSPECTION_SUCCESS;
          payload: { inspection: InspectionI };
      }
    | {
          type: typeof InspectionsTypes.CREATE_INSPECTION_SUCCESS;
          payload: { inspectionID: string };
      }
    | { type: typeof InspectionsTypes.CREATE_INSPECTION_FAIL; payload: { errorMessage: string } }
    | {
          type: typeof InspectionsTypes.DELETE_INSPECTION_SUCCESS;
          payload: { inspectionID: string };
      };

export const createInspection = (inspection: InspectionI) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, InspectionsAction>) => {
        delete inspection.ins_updated_date;
        delete inspection.ins_created_by;
        delete inspection.ins_created_date;

        const wasUpdate = !!inspection.ins_id;

        const cleanData: { [key: string]: any } = {};

        Object.entries(inspection).forEach(([key, value]) => {
            if (key.startsWith('ins')) {
                cleanData[key] = value;
            }
        });

        const options: CrewRequestConfig = {
            data: cleanData,
            path: `inspections`,
            method: wasUpdate ? 'PUT' : 'POST',
        };

        return crewAPI
            .request(options)
            .then(data => {
                if (!wasUpdate) {
                    const newInspectionID = data.new_id;
                    dispatch({
                        type: InspectionsTypes.CREATE_INSPECTION_SUCCESS,
                        payload: { inspectionID: newInspectionID },
                    });
                    dispatch(getInspectionDetails(newInspectionID));
                    dispatch(appRedirect(`/assets/inspections/${data.new_id}`));
                    return;
                }
                dispatch(appRedirect(`/assets/inspections/${inspection.ins_id}`));
            })
            .catch(error => {
                console.log('error api  in createInspection', error);
                dispatch({
                    type: InspectionsTypes.CREATE_INSPECTION_FAIL,
                    payload: { errorMessage: `Unable to create your inspections` },
                });
            });
    };
};

export const deleteInspection = (inspectionID: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, InspectionsAction>) => {
        try {
            await crewAPI.delete({ path: `inspections/${inspectionID}` });

            dispatch({
                type: InspectionsTypes.DELETE_INSPECTION_SUCCESS,
                payload: { inspectionID },
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };
};

export const getInspectionDetails = (inspectionID: string) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, InspectionsAction>,
        getState: () => RootState
    ) => {
        const rootState = getState();
        if (rootState.inspections.inspectionDetails[inspectionID]) {
            return;
        }
        dispatch({
            type: InspectionsTypes.GET_INSPECTION_LOADING,
        });

        return crewAPI
            .get({ path: `inspections/${inspectionID}` })
            .then(res => {
                console.log(' in res of getinspectionList', res);
                dispatch({
                    type: InspectionsTypes.GET_INSPECTION_SUCCESS,
                    payload: { inspection: res },
                });
            })
            .catch(error => {
                console.log('error api', error);
                dispatch({
                    type: InspectionsTypes.GET_INSPECTION_FAIL,
                    payload: { inspectionID, message: `Unable to get your inspections` },
                });
            });
    };
};
