import * as attachments from './attachments';

export const needsUpdate = (
    newRecord: { [key: string]: any },
    oldRecord: { [key: string]: any }
) => {
    try {
        Object.entries(newRecord).forEach(([key, value]) => {
            if (oldRecord[key] !== value) throw Error('needs updating');
        });
        return false;
    } catch {
        return true;
    }
};

const numberWithCommas = (value: number, places = 0) => {
    return Number(value)
        .toFixed(places)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const ttn = {
    corporate: 'Corporate',
    region: 'Portfolio',
    district: 'Region',
    sector: 'Sub-Region',
};
export type Tier = keyof typeof ttn;
const tierToName = (tier: keyof typeof ttn) => {
    return ttn[tier] || 'Group';
};

const promiseTimer = (time: number) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const utils = {
    needsUpdate,
    numberWithCommas,
    tierToName,
    attachments,
    promiseTimer,
};
export default utils;
