import { Button, InputAdornment, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRedirect } from 'store/app/app.actions';
import { createOrUpdateCityStateOverride } from 'store/cityStateOverrides/cityStateOverrides.actions';
import { CityStateListItemI } from 'store/cityStateOverrides/cityStateOverrides.types';

import './OCSFullDetails.scss';

interface Props {
    details: CityStateListItemI;
    editMode: boolean;
    create?: boolean;
}

const OCSFullDetails: React.FC<Props> = ({ details: initialDetails, editMode, create }) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState(initialDetails as CityStateListItemI);

    const detailsChanges = (field: string, value: any) => {
        setDetails({ ...details, [field]: value });
    };

    useEffect(() => {
        setDetails(initialDetails);
    }, [initialDetails]);

    const submit = () => {
        console.log('details on submit', details);
        dispatch(createOrUpdateCityStateOverride(details));
    };

    const cancel = () => {
        setDetails(initialDetails);
        if (create) {
            dispatch(appRedirect(`/admin/overrides/city-state`));
        } else {
            dispatch(appRedirect(`/admin/overrides/city-state/${details.ocs_id}`));
        }
    };

    return (
        <div className="mepCostingDetails">
            <Paper>
                {create ? null : (
                    <TextField
                        id="ocs_id"
                        required
                        label="ID"
                        value={details.ocs_id}
                        disabled
                        onChange={e => detailsChanges('ocs_id', e.target.value)}
                    />
                )}
                <TextField
                    id="ocs_city"
                    required
                    label="City"
                    value={details.ocs_city}
                    InputProps={{
                        readOnly: true,
                    }}
                    onChange={e => detailsChanges('ocs_city', e.target.value)}
                />
                <TextField
                    id="ocs_state"
                    required
                    label="State"
                    value={details.ocs_state}
                    InputProps={{
                        readOnly: true,
                    }}
                    onChange={e => detailsChanges('ocs_state', e.target.value)}
                />
                <TextField
                    id="ocs_multiplier"
                    required
                    label="Multiplier"
                    value={Number(details.ocs_multiplier) * 100}
                    InputProps={{
                        readOnly: !editMode,
                        inputProps: { min: '0', step: '1' },
                        endAdornment: <InputAdornment position="end"> %</InputAdornment>,
                    }}
                    type="number"
                    onChange={e => detailsChanges('ocs_multiplier', Number(e.target.value) / 100)}
                />

                {editMode ? (
                    <div className="bottomDetailButtons">
                        <Button variant="contained" color="primary" onClick={submit}>
                            Save
                        </Button>

                        <Button variant="contained" color="secondary" onClick={cancel}>
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="bottomDetailButtons">
                        <Link to={`/admin/overrides/city-state/${details.ocs_id}/edit`}>
                            <Button variant="contained" color="primary">
                                Edit
                            </Button>
                        </Link>
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default OCSFullDetails;
