import { BuildingsAction } from 'store/buildings/buildings.actions';
import EquipmentsState from './equipment.state';
import { EquipmentsAction } from './equipment.actions';
import EquipmentsTypes from './equipment.types';

const defaultState: EquipmentsState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,
    saved: false,

    attachment: {
        loading: false,
        loaded: false,
        error: false,
    },
    override: {
        errorMessage: '',
    },

    equipmentDetails: {},
    equipmentList: [],
    equipmentListCount: 0,
    totalReplacementCost: '0.00',
};

const equipmentsReducer = (
    state: EquipmentsState = defaultState,
    action: EquipmentsAction | BuildingsAction
): EquipmentsState => {
    switch (action.type) {
        case EquipmentsTypes.GET_EQUIPMENT_LOADING:
            return { ...state, loading: true, loaded: false };
        case EquipmentsTypes.GET_EQUIPMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                error: false,
                loading: false,
                loaded: true,
                message: '',
            };
        case EquipmentsTypes.GET_EQUIPMENT_FAIL:
            return { ...defaultState, ...action.payload, error: true };
        case EquipmentsTypes.GET_EQUIPMENT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                equipmentDetails: { ...state.equipmentDetails, ...action.payload },
            };
        case EquipmentsTypes.GET_EQUIPMENT_DETAILS_FAIL:
            return { ...state, equipmentDetails: { ...state.equipmentDetails, ...action.payload } };

        case EquipmentsTypes.LIST_EQUIPMENT_LOADING:
            return {
                ...state,
                equipmentList: [],
                loaded: false,
                loading: true,
                totalReplacementCost: '0.00',
            };

        case EquipmentsTypes.LIST_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipmentList: [...action.payload.records],
                loaded: true,
                error: false,
                loading: false,
                message: '',
                totalReplacementCost:
                    action.payload.totalReplacementCost || state.totalReplacementCost,
                equipmentListCount:
                    typeof action.payload?.totalRecordCount === 'number'
                        ? action.payload.totalRecordCount
                        : state.equipmentListCount,
            };

        case EquipmentsTypes.CREATE_EQUIPMENTS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case EquipmentsTypes.CREATE_EQUIPMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload.errorMessage,
                saved: true,
            };
        case EquipmentsTypes.CREATE_EQUIPMENTS_SUCCESS:
            return { ...state, loading: false };
        case EquipmentsTypes.CLEAR_EQUIPMENT_SAVED:
            return {
                ...state,
                saved: false,
            };

        case EquipmentsTypes.UPLOAD_ATTACHMENT_LOADING:
            return {
                ...state,
                attachment: {
                    loading: true,
                    type: action.payload.type,
                    loaded: false,
                    error: false,
                    errorMessage: undefined,
                },
            };

        case EquipmentsTypes.UPLOAD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                attachment: {
                    loading: false,
                    loaded: true,
                    type: action.payload.type,
                    error: false,
                    errorMessage: undefined,
                },
            };
        case EquipmentsTypes.UPLOAD_ATTACHMENT_FAILURE:
            return {
                ...state,
                attachment: {
                    loading: false,
                    loaded: false,
                    type: action.payload.type,
                    error: true,
                    errorMessage: action.payload.errorMessage,
                },
            };
        case EquipmentsTypes.UPDATE_TAGS:
            return {
                ...state,
                equipmentDetails: {
                    ...state.equipmentDetails,
                    [action.payload.equipmentID]: {
                        ...state.equipmentDetails[action.payload.equipmentID],
                        equipment: {
                            ...state.equipmentDetails[action.payload.equipmentID].equipment,
                            ept_tag: action.payload.tags,
                            equ_tags: action.payload.tags,
                        },
                    },
                },
            };

        case EquipmentsTypes.UPDATE_OVERRIDE_ERROR:
            return {
                ...state,
                override: {
                    errorMessage: action.payload.errorMessage,
                },
            };
        case EquipmentsTypes.CLEAR_OVERRIDE_ERROR:
            return {
                ...state,
                override: {
                    errorMessage: '',
                },
            };

        default:
            return state;
    }
};

export default equipmentsReducer;
