import React, { useEffect, useMemo, useState } from 'react';
import { BuildingI } from 'store/buildings/buildings.types';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    CircularProgress,
    Typography,
} from '@material-ui/core';

import utils from 'utils';
import hooks from 'hooks';
import { AppFeatures } from 'store/app/app.state';
import { useDispatch } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import { crewAPI } from 'utils/API';
import { saveAs } from 'file-saver';
import { createOrUpdateBuilding } from '../../../store/buildings/buildings.actions';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

const REQUIRED_FIELDS = [
    'bld_name',
    'bld_address_line1',
    'bld_responder_code',
    'bld_city',
    'bld_state',
    'bld_zip',
    'sec_id',
];

interface Props {
    details: BuildingI;
    editMode: boolean;
    isCreate: boolean;
}

const BuildingFullDetails: React.FC<Props> = ({ details: initialDetails, editMode, isCreate }) => {
    const [details, setDetails] = useState(initialDetails);
    const [isEditing, setIsEditing] = useState(editMode);
    const { isSaving, error, message } = hooks.buildings.useBuildingState();
    const { regionOptions } = hooks.options.useOptionsState();
    const globalClasses = hooks.style.useGlobalStyles();
    const hasFeature = hooks.user.useHasFeature();
    const userCanEdit = hasFeature(
        AppFeatures.BUILDINGS.ID,
        AppFeatures.BUILDINGS.subfeatures.update
    );
    const dispatch = useDispatch();

    const isDetailsEditValid = useMemo(() => {
        return REQUIRED_FIELDS.every(field => !!details[field]);
    }, [details]);

    const onDetailsChanged = (changedDetails: Partial<BuildingI>) =>
        setDetails({ ...details, ...changedDetails });

    const onSaveChanges = async () => {
        await dispatch(createOrUpdateBuilding(details, isCreate));
        setIsEditing(false);
    };

    const onCancelEdit = () => {
        setDetails(initialDetails);
        setIsEditing(false);
    };

    const downloadForOffline = async () => {
        try {
            const data = await crewAPI.downloadBlob({
                path: `/offline/export/${details.bld_id}`,
                headers: {
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            saveAs(data, `${details.bld_name}_${new Date().toISOString().slice(0, 10)}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setDetails(initialDetails);
    }, [initialDetails]);

    return (
        <Box>
            <ErrorMessage hasError={error} message={message} />
            <TextField
                className={globalClasses.textField}
                id="bld_name"
                label="Building Name"
                fullWidth
                required
                value={details.bld_name}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_name: e.target.value,
                    })
                }
            />
            <img
                src={details.bld_image_url || '/buildingPhotoPlaceholder.png'}
                style={{ maxWidth: '400px', maxHeight: '400px' }}
            />

            <Box maxWidth={450}>
                <TextField
                    className={globalClasses.textField}
                    label="Portfolio Directors"
                    id="portfolio_managers"
                    value={details.portfolio_managers}
                    style={{ width: '100%' }}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            portfolio_managers: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="Regional Facilities Managers"
                    id="region_managers"
                    style={{ width: '100%' }}
                    value={details.region_managers}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            region_managers: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="Sr. Facilities Managers"
                    id="subregion_managers"
                    style={{ width: '100%' }}
                    value={details.subregion_managers}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            subregion_managers: e.target.value,
                        })
                    }
                />
            </Box>
            <Box maxWidth={250}>
                <FormControl fullWidth>
                    <InputLabel id="bld_leased_owned-label">Leased or Owned</InputLabel>
                    <Select
                        fullWidth
                        className={globalClasses.select}
                        id="bld_leased_owned"
                        labelId="bld_leased_owned-label"
                        value={details.bld_leased_owned ?? ''}
                        readOnly={!isEditing}
                        onChange={e =>
                            onDetailsChanged({
                                bld_leased_owned: e.target.value as string,
                            })
                        }
                    >
                        <MenuItem value="owned">Owned</MenuItem>
                        <MenuItem value="leased">Leased</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <TextField
                className={globalClasses.textField}
                label="Grouping ID"
                id="bld_glc"
                required
                value={details.bld_glc}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_glc: e.target.value,
                    })
                }
            />
            <TextField
                className={globalClasses.textField}
                label="Responder Code"
                id="bld_responder_code"
                required
                value={details.bld_responder_code}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_responder_code: e.target.value,
                    })
                }
            />
            <h3>Location</h3>
            <FormControl>
                <InputLabel required id="reg_id-label">{`${utils.tierToName(
                    'region'
                )} ID`}</InputLabel>
                <Select
                    className={globalClasses.select}
                    labelId="reg_id-label"
                    id="reg_id"
                    readOnly={!isEditing}
                    value={details.reg_id || ''}
                    onChange={e => {
                        onDetailsChanged({
                            reg_id: e.target.value as string,
                            dis_id: '',
                            sec_id: '',
                        });
                    }}
                >
                    {regionOptions.map(region => (
                        <MenuItem key={`regionOption-${region.id}`} value={region.id}>
                            {region.id}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel required id="dis_id-label">{`${utils.tierToName(
                    'district'
                )} ID`}</InputLabel>
                <Select
                    className={globalClasses.select}
                    labelId="dis_id-label"
                    id="dis_id"
                    readOnly={!isEditing}
                    value={details.dis_id || ''}
                    required
                    disabled={!details.reg_id}
                    onChange={e => {
                        onDetailsChanged({
                            dis_id: e.target.value as string,
                            sec_id: '',
                        });
                    }}
                >
                    {regionOptions
                        .find(region => region.id === details.reg_id)
                        ?.districts.map(district => (
                            <MenuItem key={`districtOption-${district.id}`} value={district.id}>
                                {district.id}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel required id="sec_id-label">{`${utils.tierToName(
                    'sector'
                )} ID`}</InputLabel>
                <Select
                    className={globalClasses.select}
                    labelId="sec_id-label"
                    id="sec_id"
                    readOnly={!isEditing}
                    required
                    value={details.sec_id}
                    disabled={!details.dis_id}
                    onChange={e => {
                        onDetailsChanged({
                            sec_id: e.target.value as string,
                            bld_sec_id: e.target.value as string,
                        });
                    }}
                >
                    {regionOptions
                        .find(region => region.id === details.reg_id)
                        ?.districts.find(district => district.id === details.dis_id)
                        ?.sectors.map(sector => (
                            <MenuItem key={`sectorOption-${sector.id}`} value={sector.id}>
                                {sector.id}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <h3>
                Address{' '}
                <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${details.bld_address_line1} ${details.bld_city} ${details.bld_zip}`}
                    rel="noreferrer"
                >
                    <RoomIcon />
                </a>
            </h3>

            <div>
                <TextField
                    className={globalClasses.textField}
                    required
                    id="bld_address_line1"
                    label="Line 1"
                    value={details.bld_address_line1}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            bld_address_line1: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="Line 2"
                    id="bld_address_line2"
                    value={details.bld_address_line2}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            bld_address_line2: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="City"
                    required
                    id="bld_city"
                    value={details.bld_city}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            bld_city: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="State"
                    id="bld_state"
                    required
                    value={details.bld_state}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            bld_state: e.target.value,
                        })
                    }
                />
                <TextField
                    className={globalClasses.textField}
                    label="ZIP"
                    required
                    id="bld_zip"
                    value={details.bld_zip}
                    InputProps={{
                        readOnly: !isEditing,
                    }}
                    onChange={e =>
                        onDetailsChanged({
                            bld_zip: e.target.value,
                        })
                    }
                />
            </div>
            <h3>Building Details</h3>
            <TextField
                className={globalClasses.textField}
                label="Yard Id"
                id="bld_yard_id"
                required
                value={details.bld_yard_id}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_yard_id: e.target.value,
                    })
                }
            />
            <TextField
                className={globalClasses.textField}
                label="Sq Foot"
                id="bld_sf"
                required
                value={details.bld_sf}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_sf: Number(e.target.value),
                    })
                }
            />
            <TextField
                className={globalClasses.textField}
                label="Stories Serviced"
                id="bld_stories_serviced"
                required
                value={details.bld_stories_serviced}
                InputProps={{
                    readOnly: !isEditing,
                }}
                onChange={e =>
                    onDetailsChanged({
                        bld_stories_serviced: Number(e.target.value),
                    })
                }
            />
            {userCanEdit &&
                (isEditing ? (
                    <Box mt={1}>
                        {!isDetailsEditValid && (
                            <Box mb={1}>
                                <Typography variant="body1" color="textSecondary">
                                    Can&apos;t save as missing required fields. See fields marked
                                    with *
                                </Typography>
                            </Box>
                        )}
                        <Button
                            className={globalClasses.button}
                            disabled={isSaving || !isDetailsEditValid}
                            variant="contained"
                            color="primary"
                            onClick={onSaveChanges}
                        >
                            Save
                        </Button>
                        <Button
                            className={globalClasses.button}
                            onClick={onCancelEdit}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        {isSaving && <CircularProgress size={24} />}
                    </Box>
                ) : (
                    <Box mt={1}>
                        <Button
                            className={globalClasses.button}
                            onClick={() => setIsEditing(true)}
                            variant="contained"
                            color="primary"
                        >
                            Edit
                        </Button>
                        <Button
                            className={globalClasses.button}
                            onClick={() => downloadForOffline()}
                            variant="contained"
                            color="primary"
                        >
                            Download for Off-Line Mode
                        </Button>
                    </Box>
                ))}
        </Box>
    );
};

export default BuildingFullDetails;
