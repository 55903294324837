import React, { useEffect, useState } from 'react';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import { Box, Paper, Snackbar } from '@material-ui/core';
import hooks from 'hooks';
import { clearEquipmentSaved } from 'store/equipment/equipment.actions';
import { useDispatch } from 'react-redux';
import MEPCostingCreate from 'components/molecules/MEPCostingCreate';
import MEPCostingDetails from 'components/molecules/MEPCostingDetails';
import Alert from '@material-ui/lab/Alert';
import ListOfChips from 'components/molecules/ListOfChips';
import { AppFeatures } from 'store/app/app.state';
import actions from 'store/actions';
import EquipmentDetails from 'components/molecules/EquipmentDetails';

interface Props {
    details: EquipmentListItemI;
    isCreateView?: boolean;
    isTemplateView?: boolean;
}

const EquipmentFullDetails: React.FC<Props> = ({
    details: initialDetails,
    isCreateView,
    isTemplateView,
}) => {
    const dispatch = useDispatch();
    const globalClasses = hooks.style.useGlobalStyles();
    const [details, setDetails] = useState(initialDetails as any);
    const equipmentError = hooks.equipment.useSaved();
    const [showError, setShowError] = useState(false);
    const hasFeature = hooks.user.useHasFeature();
    const overrideError = hooks.equipment.useEquipmentOverrideError();
    const [errorMessage, setErrorMessage] = useState('');
    const { isTemplateEditEnabled } = hooks.adminSettings.useAdminSettingsState();
    const { tags: tagOptions } = hooks.options.useOptionsState();
    const isTemplateContext = isTemplateEditEnabled || !!isTemplateView;

    const [editMode, setEditMode] = useState<'NONE' | 'MEP' | 'TAGS' | 'EQUIPMENT_DETAILS'>(
        isCreateView ? 'EQUIPMENT_DETAILS' : 'NONE'
    );

    useEffect(() => {
        if (!equipmentError.saved) return;
        setShowError(equipmentError.error);
        setErrorMessage(equipmentError.message);
        dispatch(clearEquipmentSaved());
    }, [dispatch, equipmentError]);

    useEffect(() => {
        setDetails({
            ...details,
            ...{
                oes_dollars: initialDetails.oes_dollars || 0,
                oes_reason: initialDetails.oes_reason || '',
                total_replacement_cost: initialDetails.total_replacement_cost,
            },
        });
        setShowError(!!overrideError);
        setErrorMessage(overrideError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overrideError, initialDetails]);

    useEffect(() => {
        setDetails(initialDetails);
    }, [initialDetails]);

    const closeWarningMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(actions.equipment.clearOverrideError());
        setShowError(false);
    };

    const showMEPCostingsCreate =
        editMode === 'MEP' &&
        (hasFeature(AppFeatures.EQUIPMENT.ID, AppFeatures.EQUIPMENT.subfeatures.assignMEP) ||
            (details.mep_id &&
                hasFeature(AppFeatures.EQUIPMENT.ID, AppFeatures.EQUIPMENT.subfeatures.updateMEP)));

    const saveEquipmentOverride = (override: {
        oes_dollars: number;
        oes_reason: string;
        oes_equ_id: string;
    }) => {
        setDetails({
            ...details,
            ...override,
            total_replacement_cost: Number(details.total_replacement_cost) + override.oes_dollars,
        });

        dispatch(actions.equipment.updateEquipmentOverride(override));
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} mx={0.5}>
            <Box flexGrow="1" mx={0.5}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={showError}
                    autoHideDuration={null}
                    onClose={closeWarningMessage}
                >
                    <Alert onClose={closeWarningMessage} severity="error">
                        {errorMessage || 'There has been an error saving the equipment'}
                    </Alert>
                </Snackbar>
                {!isCreateView && !isTemplateContext && (
                    <Paper className={globalClasses.paper}>
                        <ListOfChips
                            chips={details.equ_tags || []}
                            chipOptions={tagOptions}
                            onSave={tags => {
                                dispatch(
                                    actions.equipment.saveEquipmentTags({
                                        tags,
                                        equipmentID: details.equ_id,
                                    })
                                );
                            }}
                            onStartEdit={() => setEditMode('TAGS')}
                            onEndEdit={() => setEditMode('NONE')}
                            isEditing={editMode === 'TAGS'}
                            isEditable={
                                (editMode === 'NONE' || editMode === 'TAGS') &&
                                hasFeature(
                                    AppFeatures.EQUIPMENT.ID,
                                    AppFeatures.EQUIPMENT.subfeatures.manageTags
                                )
                            }
                            noItemsLabel="No tags"
                            inputLabel="Add tag"
                        />
                    </Paper>
                )}

                <EquipmentDetails
                    details={details}
                    isCreateView={isCreateView}
                    isTemplateView={isTemplateView}
                    onStartEdit={() => setEditMode('EQUIPMENT_DETAILS')}
                    onEndEdit={() => setEditMode('NONE')}
                    isEditing={editMode === 'EQUIPMENT_DETAILS'}
                />
            </Box>

            {!isCreateView && !isTemplateContext && (
                <Box width={{ xs: 'auto', md: '40%' }} flexShrink={0} mx={0.5}>
                    <Paper className={globalClasses.paper}>
                        <h2>MEP Costing</h2>
                        {showMEPCostingsCreate ? (
                            <MEPCostingCreate
                                details={details}
                                onEndEdit={() => setEditMode('NONE')}
                            />
                        ) : (
                            <MEPCostingDetails
                                details={details}
                                isEditable={editMode === 'NONE' || editMode === 'MEP'}
                                onStartEdit={() => setEditMode('MEP')}
                                saveOverride={saveEquipmentOverride}
                            />
                        )}
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

export default EquipmentFullDetails;
