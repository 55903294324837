import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const Logout: React.FC = () => {
    const [showLinks, setShowLinks] = useState<boolean>(false);

    const showNavLinks = () => {
        setShowLinks(true);
    };
    const hideNavLinks = () => {
        setShowLinks(false);
    };

    const signOut = () => {
        Auth.signOut();
        window.location.reload();
    };

    return (
        <div className="nav-element" onMouseEnter={showNavLinks} onMouseLeave={hideNavLinks}>
            <div className="icon-container" onClick={signOut}>
                <ExitToAppIcon color="primary" />
                {!showLinks ? null : (
                    <div className="links-container">
                        <h1>Logout</h1>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Logout;
