import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/state';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import { UsersActions, UsersActionTypes } from './users.types';

export const getUsers = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, UsersActions>) => {
        dispatch({ type: UsersActionTypes.GET_USERS_LOADING });

        try {
            const data = await crewAPI.get({ path: `users` });

            dispatch({
                type: UsersActionTypes.GET_USERS_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            console.log('get users error api', error);
            dispatch({
                type: UsersActionTypes.GET_USERS_FAIL,
                payload: {
                    errorMessage: 'Unable to get users - please wait 30s and refresh the page',
                },
            });
        }
    };
};

export const getUser = (userID: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, UsersActions>) => {
        dispatch({ type: UsersActionTypes.GET_USER_LOADING });

        try {
            const data = await crewAPI.get({ path: `users/${userID}` });

            dispatch({
                type: UsersActionTypes.GET_USER_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            console.log('get user error api', error);
            dispatch({
                type: UsersActionTypes.GET_USER_FAIL,
                payload: {
                    errorMessage: 'Unable to get user - please wait 30s and refresh the page',
                },
            });
        }
    };
};

export const addRole = (userID: string, roleID: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, UsersActions>) => {
        dispatch({ type: UsersActionTypes.UPDATING_ROLE_LOADING });

        try {
            await crewAPI.post({
                path: `users/role`,
                data: { url_usr_id: userID, url_rol_id: roleID },
            });

            dispatch(getUser(userID));

            dispatch({ type: UsersActionTypes.UPDATING_ROLE_SUCCESS });
        } catch (error) {
            console.log('add role error api', error);

            dispatch({
                type: UsersActionTypes.UPDATING_ROLE_FAIL,
                payload: {
                    errorMessage: 'Unable to add role',
                },
            });
        }
    };
};

export const removeRole = (userID: string, roleID: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, UsersActions>) => {
        dispatch({ type: UsersActionTypes.UPDATING_ROLE_LOADING });

        try {
            const getRequestConfig: CrewRequestConfig = {
                method: 'DELETE',
                path: `users/role`,
                data: { url_usr_id: userID, url_rol_id: roleID },
            };

            await crewAPI.request(getRequestConfig);

            dispatch(getUser(userID));

            dispatch({ type: UsersActionTypes.UPDATING_ROLE_SUCCESS });
        } catch (error) {
            console.log('add role error api', error);

            dispatch({
                type: UsersActionTypes.UPDATING_ROLE_FAIL,
                payload: {
                    errorMessage: 'Unable to remove role',
                },
            });
        }
    };
};

export const updateAccessTier = ({
    userID,
    accessTierID,
    accessTierType,
}: {
    userID: string;
    accessTierID: string;
    accessTierType: string;
}) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, UsersActions>) => {
        // dispatch({ type: UsersActionTypes.UPDATING_USER_ACCESS_TIER_LOADING });

        try {
            await crewAPI.request({
                method: 'PATCH',
                path: `/users/assign/orginazation/position`,
                data: {
                    usr_id: userID,
                    usr_tier_id: accessTierID,
                    usr_tier_type: accessTierType,
                },
            });

            dispatch(getUser(userID));

            dispatch({ type: UsersActionTypes.UPDATING_USER_ACCESS_TIER_SUCCESS });
        } catch (error) {
            console.log('add role error api', error);

            dispatch({
                type: UsersActionTypes.UPDATING_USER_ACCESS_TIER_FAIL,
                payload: {
                    errorMessage: 'Unable to add role',
                },
            });
        }
    };
};
