import { PermissionFeature } from './auth.state';

enum AuthTypes {
    SUCCESS = 'SUCCESS',
}

export default AuthTypes;

export interface RoleI {
    rol_id: string;
    rol_description: string;
}

export interface UserI {
    usr_id: string;
    usr_email: string;
    usr_aws_id: string;
    usr_tier_type: string;
    usr_tier_id: string;
    usr_fname: string;
    usr_lname: string;
    usr_title: string;
    usr_status: string;
    usr_created_date: string;
    usr_is_system_admin: number;
    usr_is_user_admin: number;
    roles: RoleI[];
    features: { [key: string]: PermissionFeature };
}
