import {
    Box,
    Paper,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
} from '@material-ui/core';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { addRole, getUser, removeRole, updateAccessTier } from 'store/users/users.actions';
import { useDispatch } from 'react-redux';
import { useUpdatingRoles, useUserData, useUsersLoading } from 'hooks/users';
import { useRolesData } from 'hooks/roles';
import { getRoles } from 'store/roles/roles.actions';
import hooks from 'hooks';
import { AppFeatures } from 'store/app/app.state';
import { Permissions } from '../../components/molecules/Permissions/Permissions';

type Props = RouteComponentProps<{ userID: string }>;

export const UsersDetails: React.FC<Props> = ({ match }) => {
    const hasFeature = hooks.user.useHasFeature();
    const dispatch = useDispatch();

    const { userID } = match.params;

    const isLoadingUsers = useUsersLoading();
    const user = useUserData();
    const roles = useRolesData();
    const { error: updatingRolesError, loading: updatingRolesLoading } = useUpdatingRoles();

    const [editRoles, setEditRoles] = useState(false);
    const [editAccessTier, setEditAccessTier] = useState(false);
    const { regionOptions } = hooks.options.useOptionsState();
    const tierIDs: { id: string; region: string; district?: string; sector?: string }[] = [];
    regionOptions.forEach(region => {
        tierIDs.push({ id: region.id, region: region.id });
        region.districts.forEach(district => {
            tierIDs.push({ id: district.id, district: district.id, region: region.id });
            district.sectors.forEach(sector => {
                tierIDs.push({
                    id: sector.id,
                    sector: sector.id,
                    district: district.id,
                    region: region.id,
                });
            });
        });
    });
    const tierIdsObject = Object.fromEntries(tierIDs.map(tier => [tier.id, tier]));

    useEffect(() => {
        dispatch(getUser(userID));
        dispatch(getRoles());
    }, [dispatch, userID]);

    const TeamDetail: FC<{ label: string; value?: string; style?: CSSProperties }> = ({
        label,
        value,
        style,
    }) => (
        <TextField
            label={label}
            defaultValue={value}
            InputProps={{ readOnly: true }}
            style={style}
        />
    );

    const canEdit = hasFeature(AppFeatures.USERS.ID, AppFeatures.USERS.subfeatures.update);

    const AddOrEditRoles = () => {
        if (!canEdit) return null;

        if (updatingRolesLoading) return <CircularProgress />;

        if (editRoles) {
            return (
                <>
                    <FormControl style={{ marginLeft: '5px', minWidth: '140px' }}>
                        <InputLabel id="add-role">Add Role</InputLabel>
                        <Select
                            labelId="add-role"
                            id="demo-simple-select"
                            onChange={e => dispatch(addRole(userID, e.target.value as string))}
                        >
                            {roles.map(r => (
                                <MenuItem key={r.rol_id} value={r.rol_id}>
                                    {r.rol_description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {updatingRolesError && <div>{updatingRolesError}</div>}
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setEditRoles(false);
                        }}
                    >
                        Cancel
                    </Button>
                </>
            );
        }

        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setEditRoles(true);
                }}
            >
                Edit Roles
            </Button>
        );
    };

    const EditAccessTier = () => {
        const tierTypes = ['corporate', 'sectors'];

        const [tempTierId, setTempTierId] = useState(user?.usr_tier_id);
        const [tempTierType, setTempTierType] = useState(user?.usr_tier_type);

        const [tempTier, setTempTier] = useState(tierIdsObject[tempTierId || '']);

        useEffect(() => {
            setTempTier(tierIdsObject[tempTierId || '']);
            console.log(tempTier);
        }, [tempTierId]);

        const saveAccessTier = () => {
            if (tempTierId && tempTierType) {
                dispatch(
                    updateAccessTier({
                        userID,
                        accessTierID: tempTierId,
                        accessTierType: tempTierType,
                    })
                );
                setEditAccessTier(false);
            }
        };

        return (
            <>
                <Box display="flex" justifyContent="space-between">
                    <Box width="50%" marginRight="10px" marginBottom="10px">
                        <FormControl style={{ marginLeft: '5px', minWidth: '140px' }}>
                            <InputLabel id="editTierId">Tier ID</InputLabel>
                            <Select
                                labelId="editTierId"
                                onChange={e => setTempTierId(e.target.value as string)}
                                value={tempTierId}
                            >
                                {tierIDs.map(({ id }) => (
                                    <MenuItem key={id} value={id}>
                                        {id}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width="50%" marginRight="10px">
                        <TextField label="Portfolio" value={tempTier?.region || ''} />
                    </Box>
                    <Box width="50%" marginRight="10px">
                        <TextField label="Region" value={tempTier?.district || ''} />
                    </Box>
                    <Box width="50%" marginRight="10px">
                        <TextField label="Sub-Region" value={tempTier?.sector || ''} />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box width="50%" marginRight="10px">
                        <FormControl style={{ marginLeft: '5px', minWidth: '140px' }}>
                            <InputLabel id="editTierType">Tier Type</InputLabel>
                            <Select
                                labelId="editTierType"
                                onChange={e => setTempTierType(e.target.value as string)}
                                value={tempTierType}
                            >
                                {tierTypes.map(tierType => (
                                    <MenuItem key={tierType} value={tierType}>
                                        {tierType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="contained" color="primary" onClick={saveAccessTier}>
                        Save
                    </Button>
                </Box>
            </>
        );
    };

    return (
        <Box paddingX="10px">
            <h1>User Details</h1>

            {isLoadingUsers || !user ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box display="flex">
                        <Paper style={{ marginRight: '10px', width: '50%', padding: '10px' }}>
                            <h3>User Info</h3>
                            <Box display="flex" justifyContent="space-between" mb="25px">
                                <TeamDetail label="First name" value={user.usr_fname} />
                                <TeamDetail label="Last name" value={user.usr_lname} />
                                <TeamDetail label="Status" value={user.usr_status} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" mb="50px">
                                <Box width="33%">
                                    <TeamDetail label="Title" value={user.usr_title} />
                                </Box>

                                <Box width="66%" paddingLeft="50px">
                                    <TeamDetail
                                        label="Email"
                                        value={user.usr_email}
                                        style={{ width: '300px' }}
                                    />
                                </Box>
                            </Box>
                            <h3>Access Tier</h3>
                            {editAccessTier ? (
                                <EditAccessTier />
                            ) : (
                                <Box display="flex" justifyContent="space-between">
                                    <Box width="50%" marginRight="10px">
                                        <TeamDetail
                                            label="Tier ID"
                                            value={user.usr_tier_id}
                                            style={{}}
                                        />
                                    </Box>
                                    <Box width="50%" marginRight="10px">
                                        <TeamDetail label="Tier Type" value={user.usr_tier_type} />
                                    </Box>
                                    {canEdit && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setEditAccessTier(true)}
                                        >
                                            Edit Access
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Paper>

                        <Paper style={{ width: '50%', padding: '10px' }}>
                            <h3>User Roles</h3>

                            <Table style={{ marginBottom: '10px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Description</TableCell>
                                        {editRoles ? <TableCell /> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {user.roles.map(r => (
                                        <TableRow key={r.rol_id}>
                                            <TableCell component="th" scope="row">
                                                {r.rol_description}
                                            </TableCell>
                                            <TableCell>{r.rol_long_description}</TableCell>

                                            {editRoles ? (
                                                <TableCell>
                                                    <Button
                                                        onClick={() =>
                                                            dispatch(removeRole(userID, r.rol_id))
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {updatingRolesLoading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <AddOrEditRoles />
                                    {updatingRolesError && <div>{updatingRolesError}</div>}
                                </>
                            )}
                        </Paper>
                    </Box>
                    <Box display="flex">
                        <Paper
                            style={{
                                marginTop: '10px',
                                marginRight: '10px',
                                width: '50%',
                                padding: '10px',
                            }}
                        >
                            <h3>User Permissions</h3>

                            <Permissions permissions={user.permissions} />
                        </Paper>
                    </Box>
                </>
            )}
        </Box>
    );
};
