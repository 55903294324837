import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useGlobalStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                '& .MuiTextField-root': {
                    margin: theme.spacing(1),
                    width: '25ch',
                },
            },
            button: {
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            formControl: {
                margin: theme.spacing(1),
                minWidth: 120,
            },
            selectEmpty: {
                marginTop: theme.spacing(2),
            },
            paper: {
                padding: theme.spacing(2),
                marginBottom: theme.spacing(1),
            },
            select: {
                minWidth: 120,
                marginBottom: theme.spacing(2),
                marginRight: theme.spacing(2),
            },
            textField: {
                minWidth: 120,
                marginBottom: theme.spacing(2),
                marginRight: theme.spacing(2),
            },
            tableActionButton: {
                padding: theme.spacing(1),
                color: theme.palette.primary.main,
            },
        }),
    // This fixes an issue with CSS injection order in production builds, which causes these styles to be overridden
    // by MUI defaults. We possibly need to upgrade to MUI v5 in order to address this properly.
    // See: https://stackoverflow.com/questions/62473898/material-ui-rendering-bugs-in-production-build
    { index: 1 }
);
