import React, { useState } from 'react';
import { InspectionI } from 'store/inspections/inspections.types';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    IconButton,
    Modal,
    Paper,
    Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deleteInspection } from 'store/inspections/inspections.actions';
import hooks from 'hooks';
import { AppFeatures } from 'store/app/app.state';

interface Props {
    inspections: InspectionI[];
    errorMessage?: string;
    equipmentID: string;
}

const InspectionList: React.FC<Props> = ({ inspections, errorMessage, equipmentID }) => {
    const classes = hooks.style.useGlobalStyles();
    const [open, setOpen] = useState(false);
    const [selectedInspection, setSelectedInspection] = useState('');

    const hasFeature = hooks.user.useHasFeature();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell>Condition</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell align="right">Created Date</TableCell>
                        <TableCell align="right">View</TableCell>
                        <TableCell align="right">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {errorMessage}
                    {inspections?.map(inspection => (
                        <TableRow key={inspection.ins_id}>
                            <TableCell component="th" scope="row">
                                {inspection.ins_condition}
                            </TableCell>
                            <TableCell>{inspection.ins_notes}</TableCell>
                            <TableCell align="right">{inspection.ins_created_date}</TableCell>
                            <TableCell align="right">
                                <Link to={`/assets/inspections/${inspection.ins_id}`}>
                                    <IconButton>
                                        <DescriptionIcon />
                                    </IconButton>
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    onClick={() => {
                                        setSelectedInspection(inspection.ins_id);
                                        setOpen(true);
                                    }}
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {hasFeature(
                AppFeatures.CONDITION_ASSESSMENTS.ID,
                AppFeatures.CONDITION_ASSESSMENTS.subfeatures.create
            ) ? (
                <Box mt={1}>
                    <Link to={`/assets/equipment/${equipmentID}/create-inspection`}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<AddIcon />}
                        />
                    </Link>
                </Box>
            ) : null}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper>
                    <h2>Do you want to delete this inspection?</h2>
                    <div>
                        <Button
                            onClick={() => {
                                deleteInspection(selectedInspection);
                            }}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => handleClose()}>No</Button>
                    </div>
                </Paper>
            </Modal>
        </>
    );
};

export default InspectionList;
