import React, { useMemo, useState } from 'react';
import {
    TableRow,
    TableCell,
    Box,
    Button,
    FormControlLabel,
    Switch,
    Paper,
} from '@material-ui/core';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import { EquipmentTemplateListItemI } from 'store/equipmentTemplate/equipmentTemplate.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import tipTranslations from 'store/equipment/tooltipTranslations';
import hooks from 'hooks';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

const toolTipTranslations = tipTranslations();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldItem: {
            backgroundColor: theme.palette.grey['200'],
            borderRadius: 3,
            textTransform: 'capitalize',
        },
        categoryColumn: {
            width: '25%',
        },
        systemColumn: {
            width: '25%',
        },
        fieldsColumn: {
            width: '40%',
        },
        viewColumn: {
            width: '10%',
        },
    })
);

interface EquipmentTemplateRowData extends EquipmentTemplateListItemI {
    view: string;
}

type TemplateRowProps = {
    item: EquipmentTemplateRowData;
};

const TemplateRowItem: React.FC<TemplateRowProps> = ({ item }) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    return (
        <TableRow>
            <TableCell className={classes.categoryColumn} align="center">
                {item.equ_system_category}
            </TableCell>
            <TableCell className={classes.systemColumn} align="center">
                {item.equ_system_name}
            </TableCell>

            <TableCell className={classes.fieldsColumn} align="center">
                {item.eqt_show_fields ? (
                    <Box display="inline-flex" flexWrap="wrap" justifyContent="center" mb={-1}>
                        {item.eqt_show_fields.map(field => (
                            <Box
                                flexGrow={0}
                                px={1}
                                mb={1}
                                mr={1}
                                className={classes.fieldItem}
                                key={`${item.equ_system_category}-${item.equ_system_name}-${field}`}
                            >
                                {toolTipTranslations[field] || field}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    'No Template Applied'
                )}
            </TableCell>

            <TableCell className={classes.viewColumn} align="center">
                <Button
                    aria-label="Edit template"
                    className={globalClasses.tableActionButton}
                    component={Link}
                    to={`/assets/templates/equipment/${item.equ_system_category}/${item.equ_system_name}`}
                >
                    <DescriptionIcon />
                </Button>
            </TableCell>
        </TableRow>
    );
};

const EquipmentTemplatesList: React.FC = () => {
    const initialSort = 'equ_system_category';
    const { templates, isLoading, loadingError } =
        hooks.equipmentTemplate.useEquipmentTemplateListState();
    const [page, setPage] = React.useState(0);
    const [showAllSystems, setShowAllSystems] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof EquipmentTemplateRowData>(initialSort);
    const classes = useStyles();
    const rawRowData: EquipmentTemplateRowData[] = templates.map(template => {
        return {
            ...template,
            view: 'show',
        };
    });

    const filteredRowData = useMemo(() => {
        return showAllSystems ? rawRowData : rawRowData.filter(item => item.eqt_show_fields);
    }, [rawRowData, showAllSystems]);

    const onOrderByChange = (
        orderByKey: keyof EquipmentTemplateRowData,
        direction: OrderDirection
    ) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    const sortedRowData = stableSort<EquipmentTemplateRowData>(
        filteredRowData,
        getComparator(orderDirection, orderBy)
    );

    const pageData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const headCells: HeadCell<EquipmentTemplateRowData>[] = [
        {
            id: 'equ_system_category',
            numeric: false,
            disablePadding: false,
            label: 'Category',
            className: classes.categoryColumn,
        },
        {
            id: 'equ_system_name',
            numeric: false,
            disablePadding: false,
            label: 'System',
            className: classes.systemColumn,
        },
        {
            id: 'eqt_show_fields',
            numeric: false,
            disablePadding: false,
            disableSort: true,
            label: 'Selected Fields',
            className: classes.fieldsColumn,
        },
        {
            id: 'view',
            numeric: false,
            disablePadding: false,
            disableSort: true,
            label: 'View / Edit',
            className: classes.viewColumn,
        },
    ];

    return (
        <>
            <ErrorMessage
                hasError={!!loadingError}
                message={`Error fetching templates: ${loadingError?.message}`}
            />
            <Paper>
                <Box padding={1}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showAllSystems}
                                onChange={e => setShowAllSystems(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Show Systems Without Templates"
                    />
                </Box>
                <SortTable
                    loadingList={isLoading}
                    rows={pageData}
                    headCells={headCells}
                    rowJSX={item => (
                        <TemplateRowItem
                            key={`${item.equ_system_category}-${item.equ_system_name}`}
                            item={item}
                        />
                    )}
                    totalRowCount={filteredRowData.length}
                    dense
                    page={page}
                    setPage={setPage}
                    orderDirection={orderDirection}
                    orderBy={orderBy}
                    onOrderByChange={onOrderByChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={setRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100].sort((a: number, b: number) => a - b)}
                />
            </Paper>
        </>
    );
};

export default EquipmentTemplatesList;
