import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';
import { appRedirect } from 'store/app/app.actions';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import { AxiosError } from 'axios';
import BuildingsTypes, {
    BuildingI,
    BuildingDetailsI,
    BuildingCreateOrUpdateI,
} from './buildings.types';

interface BuildingsError {
    message: string;
}

export type BuildingsAction =
    | { type: typeof BuildingsTypes.GET_BUILDINGS_FAIL; payload: BuildingsError }
    | { type: typeof BuildingsTypes.GET_BUILDINGS_LOADING }
    | { type: typeof BuildingsTypes.GET_BUILDINGS_SUCCESS; payload: { buildings: BuildingI[] } }
    | {
          type: typeof BuildingsTypes.GET_BUILDING_DETAILS_FAIL;
          payload: {
              [key: string]: {
                  building: BuildingI;
                  errorMessage: string;
              };
          };
      }
    | {
          type: typeof BuildingsTypes.GET_BUILDING_DETAILS_SUCCESS;
          payload: { [key: string]: BuildingDetailsI };
      }
    | { type: typeof BuildingsTypes.SAVE_BUILDING_LOADING }
    | {
          type: typeof BuildingsTypes.SAVE_BUILDING_SUCCESS;
          payload: { isCreate: boolean; building: BuildingI };
      }
    | { type: typeof BuildingsTypes.SAVE_BUILDING_FAIL; payload: { errorMessage: string } }
    | {
          type: typeof BuildingsTypes.UPLOAD_OFFLINE_BUILDING;
      }
    | {
          type: typeof BuildingsTypes.UPLOAD_OFFLINE_BUILDING_SUCCESS;
      }
    | {
          type: typeof BuildingsTypes.UPLOAD_OFFLINE_BUILDING_ERROR;
          payload: {
              fileErrors: { error: string; data?: any }[];
              buildingErrors: { error: string; data?: any }[];
              equipmentErrors: { error: string; data?: any }[];
          };
      }
    | {
          type: typeof BuildingsTypes.UPLOAD_OFFLINE_BUILDING_CLEAR;
      };

export const getBuildingList = (forceUpdate?: boolean) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, BuildingsAction>,
        getState: () => RootState
    ) => {
        const state = getState();
        if (state.buildings.loaded && !forceUpdate) {
            return;
        }

        dispatch({
            type: BuildingsTypes.GET_BUILDINGS_LOADING,
        });

        try {
            const res = await crewAPI.get<BuildingI[]>({ path: 'buildings' });

            dispatch({
                type: BuildingsTypes.GET_BUILDINGS_SUCCESS,
                payload: {
                    buildings: res,
                },
            });
        } catch (error) {
            console.log('error api', error);
            dispatch({
                type: BuildingsTypes.GET_BUILDINGS_FAIL,
                payload: { message: `Unable to get your buildings` },
            });
        }
    };
};

export const getBuildingDetails = (buildingID: string, force?: boolean) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, BuildingsAction>,
        getState: () => RootState
    ) => {
        const rootState = getState();
        if (rootState.buildings.buildingDetails[buildingID] && !force) {
            return;
        }

        try {
            const res = await crewAPI.get<BuildingDetailsI>({
                path: `buildings/${buildingID}/details`,
            });

            if (!res || !res.building) {
                throw Error('invalid response from get building details');
            }

            dispatch({
                type: BuildingsTypes.GET_BUILDING_DETAILS_SUCCESS,
                payload: {
                    [buildingID]: res,
                },
            });
        } catch (error) {
            console.log('error api', error);
            dispatch({
                type: BuildingsTypes.GET_BUILDING_DETAILS_FAIL,
                payload: {
                    [buildingID]: {
                        building: {} as BuildingI,
                        errorMessage: `Error getting the details for a building`,
                    },
                },
            });
        }
    };
};

export const createOrUpdateBuilding = (buildingData: BuildingI, isCreate: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, BuildingsAction>) => {
        dispatch({
            type: BuildingsTypes.SAVE_BUILDING_LOADING,
        });

        const postData: BuildingCreateOrUpdateI = {
            bld_sec_id: buildingData.sec_id,
            bld_name: buildingData.bld_name,
            bld_glc: buildingData.bld_glc,
            bld_responder_code: buildingData.bld_responder_code,
            bld_address_line1: buildingData.bld_address_line1,
            bld_address_line2: buildingData.bld_address_line2,
            bld_city: buildingData.bld_city,
            bld_state: buildingData.bld_state,
            bld_zip: buildingData.bld_zip,
            bld_leased_owned: buildingData.bld_leased_owned,
            ...(!isCreate && { bld_id: buildingData.bld_id }),
        };

        const options: CrewRequestConfig = {
            data: postData,
            method: isCreate ? 'POST' : 'PUT',
            path: 'buildings',
        };

        await crewAPI
            .request<{
                generatedFields: string[];
                numberOfRecordsUpdated: number;
                new_id?: string;
            }>(options)
            .then(async res => {
                dispatch({
                    type: BuildingsTypes.SAVE_BUILDING_SUCCESS,
                    payload: {
                        isCreate,
                        building: {
                            ...buildingData,
                            ...(res.new_id && { bld_id: res.new_id }),
                        },
                    },
                });
                if (isCreate && res.new_id) {
                    dispatch(appRedirect(`/assets/buildings/${res.new_id}`));
                }
            })
            .catch(e => {
                dispatch({
                    type: BuildingsTypes.SAVE_BUILDING_FAIL,
                    payload: { errorMessage: e?.message },
                });
            });
    };
};

export const uploadOfflineBuilding = ({ offlineCsv }: { offlineCsv: File }) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, BuildingsAction>) => {
        dispatch({
            type: BuildingsTypes.UPLOAD_OFFLINE_BUILDING,
            payload: {},
        });

        const options: CrewRequestConfig = {
            data: offlineCsv,
            method: 'POST',
            path: '/offline/import',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Disposition': `attachment; filename=${offlineCsv.name}`,
                'Transfer-Encoding': 'chunked',
            },
        };

        try {
            const res = await crewAPI.request(options);

            await dispatch({
                type: BuildingsTypes.UPLOAD_OFFLINE_BUILDING_SUCCESS,
            });
            await dispatch(getBuildingDetails(res.buildingId, true));
        } catch (e) {
            console.log('errors uploading offline file', (e as AxiosError)?.response);

            if (typeof (e as AxiosError)?.response?.data === 'string') {
                dispatch({
                    type: BuildingsTypes.UPLOAD_OFFLINE_BUILDING_ERROR,
                    payload: {
                        fileErrors: [{ error: (e as AxiosError)?.response?.data }],
                        buildingErrors: [],
                        equipmentErrors: [],
                    },
                });
            } else {
                dispatch({
                    type: BuildingsTypes.UPLOAD_OFFLINE_BUILDING_ERROR,
                    payload: {
                        fileErrors: (e as AxiosError)?.response?.data?.fileErrors || [],
                        buildingErrors: (e as AxiosError)?.response?.data?.buildingErrors || [],
                        equipmentErrors: (e as AxiosError)?.response?.data?.equipmentErrors || [],
                    },
                });
            }
        }
    };
};

export const clearUpload = () => {
    return {
        type: BuildingsTypes.UPLOAD_OFFLINE_BUILDING_CLEAR,
    };
};
