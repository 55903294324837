enum AppTypes {
    INIT_APP_FAIL = 'INIT_APP_FAIL',
    INIT_APP_LOADING = 'INIT_APP_LOADING',
    INIT_APP_SUCCESS = 'INIT_APP_SUCCESS',
    REDIRECT = 'REDIRECT',
    CLEAR_REDIRECT = 'CLEAR_REDIRECT',
    CLEAR_APP_ERROR = 'CLEAR_APP_ERROR',
}

export default AppTypes;
