import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Paper, FormControlLabel, Switch, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';

import CreateIcon from '@material-ui/icons/Create';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';
import queryString from 'query-string';
import { RouteComponentProps, Link } from 'react-router-dom';
import './OCSList.scss';
import { CityStateListItemI } from 'store/cityStateOverrides/cityStateOverrides.types';
import {
    listCityStateOverrides,
    listMissingCityStateOverrides,
} from 'store/cityStateOverrides/cityStateOverrides.actions';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

interface Props {
    location: RouteComponentProps['location'];
}

const CityStateList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const overrideListArray = hooks.cityStateOverrides.useOverrideList();
    const overridesLoaded = hooks.cityStateOverrides.useOverrideListLoaded();
    const overridesLength = hooks.cityStateOverrides.useOverrideListLength();
    // const mepSchema = hooks.mep.useMEPSchema();
    const missingOverridesList = hooks.cityStateOverrides.useMissingOverrideList();
    const missingOverridesLength = hooks.cityStateOverrides.useMissingOverrideListLength();

    const { pagenum } = queryString.parse(location.search) as {
        [key: string]: string | string[];
    };

    const initialSort = 'ocs_state';
    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>(initialSort);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [loadingList, setLoadingList] = useState(true);
    const [showMissingData, setShowMissingData] = useState(false);

    useEffect(
        () => {
            setLoadingList(true);
            dispatch(listCityStateOverrides());
            dispatch(listMissingCityStateOverrides());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            /* orderBy, orderDirection, rowsPerPage, page, filters */
        ]
    );

    useEffect(() => {
        console.log('mep list loaded');
        if (overridesLoaded) {
            setLoadingList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overrideListArray]);

    interface Data extends CityStateListItemI {
        view: string;
        create: boolean;
    }

    const unsortedRowData: Data[] = overrideListArray.map(ocs => {
        return {
            ...ocs,
            view: 'show',
            create: false,
        };
    });

    const unsortedMissingData: Data[] = missingOverridesList.map(ocs => {
        return {
            ...ocs,
            view: undefined,
            create: true,
        } as unknown as Data;
    });

    const sortedRowData = stableSort<Data>(
        showMissingData ? [...unsortedRowData, ...unsortedMissingData] : unsortedRowData,
        getComparator(orderDirection, orderBy)
    );

    const rowData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // TODO Add more columns to the table
    const headCells: HeadCell<Data>[] = [
        // { id: 'ocs_id', numeric: false, disablePadding: false, label: 'ID' },

        { id: 'ocs_state', numeric: true, disablePadding: false, label: 'State' },
        { id: 'ocs_city', numeric: false, disablePadding: false, label: 'City' },

        { id: 'view', numeric: false, disablePadding: false, label: 'View' },
        { id: 'create', numeric: false, disablePadding: false, label: 'Create Override' },
    ];

    if (!showMissingData) headCells.pop();

    const rowJSX = (row: Data) => {
        return (
            <TableRow key={row.ocs_id}>
                {/* <TableCell align="center">{row.ocs_id}</TableCell> */}
                <TableCell align="center">{row.ocs_state}</TableCell>
                <TableCell align="center">{row.ocs_city}</TableCell>

                <TableCell align="center">
                    {row.view ? (
                        <Button
                            aria-label="View details"
                            className={globalClasses.tableActionButton}
                            component={Link}
                            to={`/admin/overrides/city-state/${row.ocs_id}`}
                        >
                            <DescriptionIcon />
                        </Button>
                    ) : null}
                </TableCell>
                {showMissingData ? (
                    <TableCell align="center">
                        {row.create ? (
                            <Button
                                aria-label="Create override"
                                className={globalClasses.tableActionButton}
                                component={Link}
                                to={`/admin/overrides/city-state/create?city=${row.ocs_city}&state=${row.ocs_state}`}
                            >
                                <CreateIcon />
                            </Button>
                        ) : null}
                    </TableCell>
                ) : null}
            </TableRow>
        );
    };

    /*
    const filterParams: EnhancedTableFilterProps = {
        schema: mepSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setPage(0);
            setFilters(filters);
        },
    };
    */

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    return (
        <div className="List">
            <Paper>
                <FormControlLabel
                    className="missingCityStateToggle"
                    control={
                        <Switch
                            checked={showMissingData}
                            onChange={e => setShowMissingData(e.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Show Cities Without Overrides"
                />
                <SortTable<Data>
                    // tableTitle="MEP List"
                    // filterParams={filterParams}
                    rows={rowData}
                    headCells={headCells}
                    rowJSX={rowJSX}
                    totalRowCount={
                        showMissingData ? overridesLength + missingOverridesLength : overridesLength
                    }
                    dense
                    loadingList={loadingList}
                    page={page}
                    setPage={setPage}
                    orderDirection={orderDirection}
                    orderBy={orderBy}
                    onOrderByChange={onOrderByChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={setRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100].sort((a: number, b: number) => a - b)}
                />
                {/* <Link to="/admin/overrides/cityState/create">
                    <Button variant="contained" color="primary">
                        Create New City State Override
                    </Button>
                </Link>
    */}
            </Paper>
        </div>
    );
};

export default CityStateList;
