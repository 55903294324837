import BarChartRow, { BarChartRowProps } from 'components/atoms/BarChartRow';
import React from 'react';

import './BarChart.scss';

interface Props {
    title?: string;
    rows: BarChartRowProps[];
}

const BarChart: React.FC<Props> = ({ rows, title }) => {
    return (
        <div className="barChartContainer">
            {title ? <h3>{title}</h3> : null}
            {rows.map(rowData => (
                <BarChartRow key={rowData.title} {...rowData} />
            ))}
        </div>
    );
};

export default BarChart;

export {};
