import React from 'react';
import './HeaderBar.scss';

const HeaderBar: React.FC = () => {
    return (
        <div className="headerBar">
            <img
                className="cushmanImage"
                src={`${process.env.PUBLIC_URL}/CushmanLogo.svg`}
                alt="Verizon Header"
            />
            <img src={`${process.env.PUBLIC_URL}/VZ_Logo.jpg`} alt="Verizon Header" />
        </div>
    );
};

export default HeaderBar;
