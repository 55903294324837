import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import {
    TextField,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Box,
    CircularProgress,
} from '@material-ui/core';
import hooks from 'hooks';
import { createOrUpdateEquipment, duplicateEquipment } from 'store/equipment/equipment.actions';
import { useDispatch } from 'react-redux';
import { appRedirect } from 'store/app/app.actions';
import {
    getCurrentTemplate,
    updateTemplate,
} from 'store/equipmentTemplate/equipmentTemplate.actions';
import {
    hasPendingChanges,
    requiredCreateFields,
    requiredEquipmentFields,
} from 'store/equipmentTemplate/equipmentTemplate.types';
import EquipmentEditButtons from './EquipmentEditButtons';
import TemplateEditButtons from './TemplateEditButtons';
import { SelectableTextField } from '../../atoms/SelectableComponent';
import {
    detailsEquipmentFields,
    EquipmentFieldDefinition,
    locationEquipmentFields,
    notesField,
    technicalDetailsEquipmentFields,
} from './equipmentFieldDefinitions';

interface Props {
    details: EquipmentListItemI;
    isCreateView?: boolean;
    isTemplateView?: boolean;
    isEditing: boolean;
    onStartEdit: () => void;
    onEndEdit: () => void;
}

const EquipmentDetails: React.FC<Props> = ({
    details: initialDetails,
    isCreateView,
    isTemplateView,
    isEditing,
    onStartEdit,
    onEndEdit,
}) => {
    const dispatch = useDispatch();
    const { template: currentTemplate, isLoading: isLoadingTemplate } =
        hooks.equipmentTemplate.useCurrentEquipmentTemplateState();
    const { equipmentSystemCategories, equipmentStates } = hooks.options.useOptionsState();
    const globalClasses = hooks.style.useGlobalStyles();
    const { isTemplateEditEnabled, isMaintenanceModeEnabled } =
        hooks.adminSettings.useAdminSettingsState();

    const [details, setDetails] = useState(initialDetails as any);
    const [editedTemplate, setEditedTemplate] = useState(currentTemplate);
    const [isTemplatePreviewEnabled, setIsTemplatePreviewEnabled] = useState(false);
    const isTemplateContext = isTemplateEditEnabled || !!isTemplateView;
    const isTemplateSelectionEnabled =
        !!(isTemplateEditEnabled || isTemplateView) && !isTemplatePreviewEnabled;
    const requiredFields = Object.keys(
        isCreateView ? requiredCreateFields : requiredEquipmentFields
    );

    const onDetailsChanged = (changedData: Record<string, any>) => {
        setDetails({ ...details, ...changedData });
    };

    const onTemplateSelectionChanged = (field: string, isSelected: boolean) =>
        setEditedTemplate({ ...editedTemplate, [field]: isSelected });

    const isDetailsEditValid = useMemo(() => {
        return requiredFields.every(f => !!details[f]);
    }, [details, requiredFields]);

    const onSaveDetails = async () => {
        await dispatch(createOrUpdateEquipment(details));
        onEndEdit();
    };

    const onCancelDetailsEdit = () => {
        setDetails(initialDetails);
        if (isCreateView) {
            dispatch(appRedirect(`/assets/buildings/${details.equ_bld_id}`));
        } else {
            onEndEdit();
        }
    };

    const onDuplicateItem = () => {
        const duplicateDetails = { ...details };
        delete duplicateDetails.equ_id;
        duplicateDetails.equ_alias = '';
        delete duplicateDetails.equ_created_by;
        delete duplicateDetails.equ_created_date;
        delete duplicateDetails.equ_updated_by;
        delete duplicateDetails.equ_updated_date;

        console.log(duplicateDetails);

        onStartEdit();

        dispatch(duplicateEquipment(duplicateDetails));
    };

    const onSaveTemplate = () =>
        dispatch(
            updateTemplate(details.equ_system_category, details.equ_system_name, editedTemplate)
        );

    const isTemplateElementVisible = useCallback(
        (key: string) =>
            (!isTemplateContext && currentTemplate[key]) ||
            (isTemplateContext && !isTemplatePreviewEnabled) ||
            (isTemplatePreviewEnabled && editedTemplate[key]),
        [isTemplateContext, isTemplatePreviewEnabled, currentTemplate, editedTemplate]
    );

    useEffect(() => {
        if (!details.equ_system_category || !details.equ_system_name) return;

        dispatch(getCurrentTemplate(details.equ_system_category, details.equ_system_name));
    }, [details.equ_system_category, details.equ_system_name, dispatch]);

    useEffect(() => {
        setEditedTemplate(currentTemplate);
    }, [currentTemplate]);

    useEffect(() => {
        setDetails(initialDetails);
    }, [initialDetails]);

    const renderEquipmentField = (field: EquipmentFieldDefinition) => {
        const elementKey = `equipment-field-${field.key}`;
        const formattedValue = field.valueConverter
            ? field.valueConverter(details[field.key])
            : details[field.key];

        return field.isOptional ? (
            <SelectableTextField
                id={field.key}
                key={elementKey}
                className={globalClasses.textField}
                label={field.label}
                value={formattedValue}
                maxRows={field.maxRows ?? 1}
                minRows={field.minRows ?? 1}
                multiline={!!field.maxRows || !!field.minRows}
                fullWidth={!!field.fullWidth}
                required={requiredFields.includes(field.key)}
                InputProps={{
                    readOnly: field.isReadOnly || !isEditing,
                    type: field.type,
                }}
                boxProps={{
                    width: field.width,
                    display: field.fullWidth ? 'flex' : 'inline-flex',
                }}
                isVisible={isTemplateElementVisible(field.key)}
                isSelectionEnabled={isTemplateSelectionEnabled}
                isSelected={editedTemplate[field.key]}
                isSelectedChanged={isSelected => onTemplateSelectionChanged(field.key, isSelected)}
                onChange={e => onDetailsChanged({ [field.key]: e.target.value })}
            />
        ) : (
            <TextField
                key={elementKey}
                className={globalClasses.textField}
                label={field.label}
                value={formattedValue}
                maxRows={field.maxRows ?? 1}
                minRows={field.minRows ?? 1}
                multiline={!!field.maxRows || !!field.minRows}
                fullWidth={!!field.fullWidth}
                InputProps={{
                    readOnly: true,
                    type: field.type,
                }}
                style={{ width: field.width ? `${field.width}px` : 'auto' }}
                onChange={e => onDetailsChanged({ [field.key]: e.target.value })}
            />
        );
    };

    return (
        <Paper className={globalClasses.paper}>
            <div className="equipmentDetails">
                <>
                    <h3>System</h3>
                    <FormControl>
                        <InputLabel id="equ_system_category-label">Category</InputLabel>
                        <Select
                            required
                            className={globalClasses.select}
                            labelId="equ_system_category-label"
                            id="equ_system_category"
                            value={
                                Object.keys(equipmentSystemCategories).length
                                    ? details.equ_system_category
                                    : ''
                            }
                            readOnly={!isEditing}
                            onChange={e => {
                                if (!isEditing) return;
                                onDetailsChanged({
                                    equ_system_category: e.target.value,
                                    equ_system_name: '',
                                });
                            }}
                        >
                            {Object.entries(equipmentSystemCategories).map(([categoryName]) => (
                                <MenuItem
                                    key={`equipmentSystemCategory-${categoryName}`}
                                    value={categoryName}
                                >
                                    {categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="equ_system_name-label">System</InputLabel>
                        <Select
                            required
                            className={globalClasses.select}
                            labelId="equ_system_name-label"
                            id="equ_system_name"
                            readOnly={!isEditing}
                            value={
                                Object.keys(equipmentSystemCategories).length
                                    ? details.equ_system_name
                                    : ''
                            }
                            onChange={e => {
                                if (!isEditing) return;
                                if (!details.equ_system_category) return;
                                onDetailsChanged({ equ_system_name: e.target.value });
                            }}
                            disabled={!details.equ_system_category}
                        >
                            {(
                                equipmentSystemCategories[
                                    details.equ_system_category as keyof typeof equipmentSystemCategories
                                ] || []
                            ).map(systemName => (
                                <MenuItem
                                    key={`equipmentSystemName-${systemName}`}
                                    value={systemName}
                                >
                                    {systemName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {isMaintenanceModeEnabled ? (
                        <FormControl>
                            <InputLabel id="equ_status-label">Status</InputLabel>
                            <Select
                                required
                                className={globalClasses.select}
                                labelId="equ_status-label"
                                id="equ_status"
                                value={details.equ_status}
                                readOnly={!isEditing}
                                onChange={e => {
                                    if (!isEditing) return;
                                    onDetailsChanged({ equ_status: e.target.value });
                                }}
                            >
                                {equipmentStates.map(({ stateName, stateValue }) => (
                                    <MenuItem
                                        key={`equipmentSystemCategory-${stateValue}`}
                                        value={stateValue}
                                    >
                                        {stateName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                </>

                {!!details.equ_system_category && !!details.equ_system_name && !isLoadingTemplate && (
                    <>
                        <h3>Details</h3>
                        {detailsEquipmentFields.map(renderEquipmentField)}

                        <h3>Location</h3>
                        {locationEquipmentFields.map(renderEquipmentField)}

                        <h3>Technical Details</h3>
                        {technicalDetailsEquipmentFields.map(renderEquipmentField)}

                        {isTemplateElementVisible('equ_refrigerant_type') && (
                            <Box display="flex" alignItems="flex-end" mt={1}>
                                <Box display="inline-block" mr={1}>
                                    <label className="MuiFormLabel-root">Uses Refrigerant</label>
                                    <Box>
                                        <span>No</span>
                                        <Switch
                                            readOnly={!isEditing}
                                            checked={details.equ_uses_refrigerant === 'Yes'}
                                            onChange={e => {
                                                if (!isEditing) return;
                                                console.log(
                                                    e.target.checked,
                                                    details.equ_refrigerant_type
                                                );
                                                onDetailsChanged({
                                                    equ_uses_refrigerant: e.target.checked
                                                        ? 'Yes'
                                                        : 'No',
                                                    equ_refrigerant_type: '',
                                                });
                                            }}
                                            name="equ_uses_refrigerant"
                                        />

                                        <span>Yes</span>
                                    </Box>
                                </Box>

                                <SelectableTextField
                                    label="Refrigerant Type"
                                    value={details.equ_refrigerant_type}
                                    InputProps={{
                                        readOnly: !isEditing,
                                    }}
                                    id="equ_refrigerant_type"
                                    isVisible={isTemplateElementVisible('equ_refrigerant_type')}
                                    isSelectionEnabled={isTemplateSelectionEnabled}
                                    isSelected={editedTemplate.equ_refrigerant_type}
                                    disabled={details.equ_uses_refrigerant !== 'Yes'}
                                    isSelectedChanged={isSelected =>
                                        onTemplateSelectionChanged(
                                            'equ_refrigerant_type',
                                            isSelected
                                        )
                                    }
                                    onChange={e =>
                                        onDetailsChanged({
                                            equ_refrigerant_type: e.target.value,
                                        })
                                    }
                                />
                            </Box>
                        )}

                        {renderEquipmentField(notesField)}

                        {isTemplateContext ? (
                            <TemplateEditButtons
                                onEdit={() => setIsTemplatePreviewEnabled(false)}
                                onReset={() => setEditedTemplate(currentTemplate)}
                                onPreview={() => setIsTemplatePreviewEnabled(true)}
                                onSave={onSaveTemplate}
                                isPreviewEnabled={isTemplatePreviewEnabled}
                                isTemplateView={isTemplateView}
                                hasPendingChanges={hasPendingChanges(
                                    currentTemplate,
                                    editedTemplate
                                )}
                            />
                        ) : (
                            <EquipmentEditButtons
                                onSave={onSaveDetails}
                                onCancel={onCancelDetailsEdit}
                                onEdit={() => onStartEdit()}
                                onDuplicate={onDuplicateItem}
                                editMode={isEditing}
                                isEditValid={isDetailsEditValid}
                                buildingId={details.bld_id}
                            />
                        )}
                    </>
                )}

                {isLoadingTemplate && (
                    <Box display="flex" justifyContent="center" my={2}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        </Paper>
    );
};

export default EquipmentDetails;
