enum EquipmentTemplateActionTypes {
    GET_TEMPLATE_LOADING = 'GET_TEMPLATE_LOADING',
    GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS',
    GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL',
    SAVE_TEMPLATE_LOADING = 'SAVE_TEMPLATE_LOADING',
    SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS',
    SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL',
    GET_TEMPLATE_LIST_LOADING = 'GET_TEMPLATE_LIST_LOADING',
    GET_TEMPLATE_LIST_SUCCESS = 'GET_TEMPLATE_LIST_SUCCESS',
    GET_TEMPLATE_LIST_FAIL = 'GET_TEMPLATE_LIST_FAIL',
    RESET_TEMPLATE_LIST = 'RESET_TEMPLATE_LIST',
}

export const requiredCreateFields = {
    equ_system_name: true,
    equ_system_category: true,
    equ_bld_id: true,
};

export const requiredEquipmentFields = {
    ...requiredCreateFields,
    equ_id: true,
};

export const defaultEquipmentTemplate = {
    ...requiredEquipmentFields,
    equ_alias: true,
    equ_description: true,
    equ_business_id: true,
    equ_approx_yr_installed: true,
    equ_mep_quantity: true,
    equ_floor: true,
    equ_room: true,
    equ_mfg: true,
    equ_model: true,
    equ_vendor: true,
    equ_serial_number: true,
    equ_specs: true,
    equ_ton: true,
    equ_btu: true,
    equ_hp: true,
    equ_volt_amp: true,
    equ_gal: true,
    equ_pod: true,
    equ_dr_rate_percent: true,
    equ_uses_refrigerant: true,
    equ_refrigerant_type: true,
    equ_notes: true,
};

export type EquipmentTemplate = typeof defaultEquipmentTemplate;

export interface EquipmentTemplateListItemI {
    equ_system_category: string;
    equ_system_name: string;
    eqt_show_fields?: string[];
}

export function fromApiResponse(response: string[]): EquipmentTemplate {
    const template = { ...defaultEquipmentTemplate };

    if (!response.length) {
        return template;
    }

    Object.keys(template).reduce((acc, key) => {
        if (!response.includes(key)) acc[key] = false;
        return acc;
    }, template);

    return template;
}

export function toApiRequestPayload(template: EquipmentTemplate) {
    const output: string[] = [];
    return Object.entries(template).reduce(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        output
    );
}

export function hasPendingChanges(
    currentTemplate: EquipmentTemplate,
    editedTemplate: EquipmentTemplate
) {
    const currentTemplateKeys = Object.keys(currentTemplate);
    const editedTemplateKeys = Object.keys(editedTemplate);

    if (currentTemplateKeys.length !== editedTemplateKeys.length) return true;

    return !currentTemplateKeys.every(key => currentTemplate[key] === editedTemplate[key]);
}

export default EquipmentTemplateActionTypes;
