import React from 'react';

import './menu.scss';
import { connect, useDispatch } from 'react-redux';
import { AppFeatures } from 'store/app/app.state';

import ApartmentIcon from '@material-ui/icons/Apartment';
// import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
// import WindPowerIcon from '@mui/icons-material/WindPower';
import ToysIcon from '@material-ui/icons/Toys';

// import FlashOnIcon from '@material-ui/icons/FlashOn';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import hooks from 'hooks';
import { RootState } from 'store/state';

import { Button } from '@material-ui/core';
import actions from 'store/actions';
import Logout from './Logout';
import NavItem, { LinkProps } from './NavItem';

type Props = ReturnType<typeof mapStateToProps>;

export const dashboardLinks: LinkProps[] = [
    /* {
        title: 'Home',
        path: '',
        feature: AppFeatures.DASHBOARD,
    }, */
];

export const equipmentLinks: LinkProps[] = [
    /* {
        title: 'Equipment List',
        path: 'assets/equipment',
        feature: AppFeatures.ASSETS,
    }, */
];

export const buildingLinks: LinkProps[] = [
    /*
    {
        title: 'Building List',
        path: 'assets/buildings',
        feature: AppFeatures.ASSETS,
    }, */
];

/*
export const reportingLinks: LinkProps[] = [
    {
        title: 'Recent Inspections',
        path: 'reports/recent-inspections',
        feature: AppFeatures.REPORTING.ID,
    },
    {
        title: 'Past Due Inspections',
        path: 'reports/past-due-inspections',
        feature: AppFeatures.REPORTING.ID,
    },
    {
        title: 'Upcoming Inspections',
        path: 'reports/upcoming-inspections',
        feature: AppFeatures.REPORTING.ID,
    },
];
*/

// export const teamLinks: LinkProps[] = [
//     {
//         title: 'Assignments',
//         path: 'team/assignments',
//         feature: AppFeatures.TEAM.ID,
//     },
// ];

export const userLinks: LinkProps[] = [
    {
        title: 'Manage Users',
        path: '/users',
        feature: AppFeatures.USERS.ID,
    },
    {
        title: 'Manage Roles',
        path: '/users/roles',
        feature: AppFeatures.USERS.ID,
    },
];

export const adminLinks: LinkProps[] = [
    /*
    {
        title: 'User Management',
        path: 'admin/user-management',
        feature: AppFeatures.ADMIN,
    },
    */
    {
        title: 'MEP Items',
        path: '/admin/mepcosting',
        feature: AppFeatures.MEP.ID,
    },
    {
        title: 'MEP Overrides',
        path: '/admin/overrides/mep',
        feature: AppFeatures.MEP.ID,
    },
    {
        title: 'City State Overrides',
        path: '/admin/overrides/city-state',
        feature: AppFeatures.CITY_STATE_OVERRIDES.ID,
    },
    {
        title: 'Offline Import',
        path: '/offline/import',
        feature: AppFeatures.REPORTING.ID,
        subfeatures: [AppFeatures.REPORTING.subfeatures.bulkInportExport],
    },
    {
        title: 'Administration Settings',
        path: '/admin/settings',
        feature: AppFeatures.EQUIPMENT.ID,
        subfeatures: [AppFeatures.EQUIPMENT.subfeatures.create],
    },
];

const Menu: React.FC<Props> = ({ logo }) => {
    const { lastTier, lastTierID } = hooks.dashboard.useTiers();
    const hasFeature = hooks.user.useHasFeature();
    const { isMaintenanceModeEnabled } = hooks.adminSettings.useAdminSettingsState();
    const dispatch = useDispatch();

    const dashboardLink =
        lastTier && lastTierID ? `/dashboards/${lastTier}/${lastTierID}` : '/dashboards';

    const classes = `nav  ${isMaintenanceModeEnabled ? ' maintenanceMode' : ''}`;

    return (
        <div className={classes}>
            <img id="mainLogo" src={logo} alt="logo" />
            <NavItem
                title="Dashboard"
                links={dashboardLinks}
                icon={
                    <BarChartIcon
                        color="primary"
                        fontSize="large"
                        style={{ transform: 'rotate(90deg)' }}
                    />
                }
                rootLink={dashboardLink}
            />

            <NavItem
                title="FCA"
                links={equipmentLinks}
                icon={<ToysIcon color="primary" />}
                rootLink="/assets/equipment"
            />
            <NavItem
                title="Buildings"
                links={buildingLinks}
                icon={<ApartmentIcon color="primary" />}
                rootLink="/assets/buildings"
            />

            {hasFeature(AppFeatures.USERS.ID) ? (
                <NavItem
                    title="User Management"
                    links={userLinks}
                    icon={<PeopleIcon color="primary" />}
                    rootLink="/users"
                />
            ) : null}

            {/*
            <NavItem title="Reports" links={reportingLinks} icon={ReportIcon} />
            <NavItem title="Team" links={teamLinks} icon={TeamIcon} />
            */}
            {hasFeature(AppFeatures.MEP.ID) || hasFeature(AppFeatures.CITY_STATE_OVERRIDES.ID) ? (
                <NavItem
                    title="Administration"
                    links={adminLinks}
                    icon={<SettingsIcon color="primary" />}
                />
            ) : null}

            <Logout />

            {isMaintenanceModeEnabled && (
                <Button
                    style={{
                        padding: '6px 0px',
                        margin: '0px -5px',
                    }}
                    onClick={() => {
                        console.log('exit maintenance mode');
                        dispatch(actions.adminSettings.setIsMaintenanceModeEnabled(false));
                    }}
                >
                    Exit Mode
                </Button>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        logo: state.app.logoURL,
    };
};

export default connect(mapStateToProps, {})(Menu);
