import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/state';
import { crewAPI } from 'utils/API';
import { RolesActions, RolesActionTypes } from './roles.types';

export const getRoles = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, RolesActions>) => {
        dispatch({ type: RolesActionTypes.GET_ROLES_LOADING });

        try {
            const data = await crewAPI.get({
                path: `roles-permissions/roles`,
            });

            dispatch({
                type: RolesActionTypes.GET_ROLES_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            console.log('get users error api', error);
            dispatch({
                type: RolesActionTypes.GET_ROLES_FAIL,
                payload: {
                    errorMessage: 'Unable to get users - please wait 30s and refresh the page',
                },
            });
        }
    };
};

export const getRolePermissions = (roleID: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, RolesActions>) => {
        dispatch({ type: RolesActionTypes.GET_ROLE_PERMISSIONS_LOADING });

        try {
            const data = await crewAPI.get({
                path: `roles-permissions/role/${roleID}/permissions`,
            });

            dispatch({
                type: RolesActionTypes.GET_ROLE_PERMISSIONS_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            console.log('get users error api', error);
            dispatch({
                type: RolesActionTypes.GET_ROLE_PERMISSIONS_FAIL,
                payload: {
                    errorMessage: 'Unable to get users - please wait 30s and refresh the page',
                },
            });
        }
    };
};
