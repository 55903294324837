import {
    Box,
    Button,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import './MEPCostingDetails.scss';
import hooks from 'hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            border: 'solid 1px',
            borderColor: theme.palette.divider,
        },
    })
);

interface Props {
    details: EquipmentListItemI;
    isEditable: boolean;
    onStartEdit: () => void;
    saveOverride: (override: {
        oes_dollars: number;
        oes_reason: string;
        oes_equ_id: string;
    }) => void;
}

const MEPCostingDetails: React.FC<Props> = ({ details, isEditable, onStartEdit, saveOverride }) => {
    const [editOverride, setEditOverride] = useState(false);
    const [override, setOverride] = useState({
        oes_dollars: details.oes_dollars || 0,
        oes_reason: details.oes_reason || '',
        oes_equ_id: details.equ_id,
    });
    const classes = useStyles();
    const globalClasses = hooks.style.useGlobalStyles();

    const saveEquipmentOverride = () => {
        saveOverride(override);
        setEditOverride(false);
    };

    const perUnitSubTotal = Number(details.mep_replacement_unit_cost).toFixed(2);

    // should we default to quantity of 1?
    const quantity = Number(details.equ_mep_quantity ?? 1).toFixed(0);

    const subTotal = Number(
        details.mep_replacement_cost || details.mep_replacement_unit_cost
    ).toFixed(2);

    const mepOverrides: {
        fixed_amount: string;
        multiplier: string;
        description: string;
    }[] = JSON.parse(details.mep_overrides || '[]');

    return (
        <div>
            <TextField
                className={globalClasses.textField}
                id="mep_system"
                label="MEP System"
                value={details.mep_system}
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                className={globalClasses.textField}
                id="mep_id"
                label="ID"
                value={details.mep_id}
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                id="mep_capacity"
                className={globalClasses.textField}
                label="Capacity"
                value={
                    details.mep_capacity || details.mep_capacity_measure
                        ? `${details.mep_capacity || ''} ${details.mep_capacity_measure || ''}`
                        : undefined
                }
                InputProps={{
                    readOnly: true,
                }}
            />
            <TableContainer>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Equipment Unit Cost</TableCell>
                            <TableCell>{`$${details.mep_cost_per_unit || '0.00'}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Multiplier Override</TableCell>
                            <TableCell>{`$${Number(details.mep_multiplier_overrides_value).toFixed(
                                2
                            )}`}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Fixed Amount Override</TableCell>
                            <TableCell>{`$${Number(details.omp_fixed_amount).toFixed(
                                2
                            )}`}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Per Unit Sub Total</TableCell>
                            <TableCell>{`$${perUnitSubTotal}`}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>MEP Quantity</TableCell>
                            <TableCell>{quantity}</TableCell>
                        </TableRow>

                        <TableRow style={{ border: '2px black solid' }}>
                            <TableCell>
                                <b>MEP Sub Total</b>
                            </TableCell>
                            <TableCell>
                                <b>{`$${subTotal}`}</b>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>City State Override</TableCell>
                            <TableCell>{`$${Number(details.overrrides_city_state_value).toFixed(
                                2
                            )}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Equipment Override</TableCell>
                            <TableCell>
                                {editOverride ? (
                                    <TextField
                                        id="oes_dollars"
                                        required
                                        label="Fixed Amount"
                                        type="number"
                                        value={override.oes_dollars}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                        }}
                                        onChange={e =>
                                            setOverride({
                                                ...override,
                                                oes_dollars: Number(e.target.value),
                                            })
                                        }
                                    />
                                ) : (
                                    `$${Number(details.oes_dollars || 0).toFixed(2)}`
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ border: '2px black solid' }}>
                            <TableCell>
                                <b>Total Replacement Cost</b>
                            </TableCell>
                            <TableCell>
                                <b>{`$${Number(details.total_replacement_cost).toFixed(2)}`}</b>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Applied Overrides</h3>
            <h4>MEP</h4>
            {/* eslint-disable-next-line no-shadow */}
            {mepOverrides.map((override, idx) => (
                <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                >{`${override.description} - ${override.multiplier} ${override.fixed_amount}`}</div>
            ))}
            {mepOverrides.length > 0 ? null : 'No MEP Overrides'}
            <h4>City State</h4>
            {details.ocs_multiplier
                ? `${details.ocs_city} ${details.ocs_state} - ${
                      Number(details.ocs_multiplier) * 100
                  }%`
                : 'No City State Overrides'}
            <h4>Equipment</h4>
            {editOverride ? (
                <TextField
                    className={globalClasses.textField}
                    fullWidth
                    id="oes_reason"
                    label="Override Reason"
                    value={override.oes_reason}
                    multiline
                    onChange={e =>
                        setOverride({
                            ...override,
                            oes_reason: e.target.value,
                        })
                    }
                />
            ) : (
                details.oes_reason
            )}

            {isEditable &&
                (!editOverride ? (
                    <Box mt={1}>
                        <Button
                            className={globalClasses.button}
                            onClick={onStartEdit}
                            variant="contained"
                            color="primary"
                        >
                            Edit
                        </Button>
                        <Button
                            className={globalClasses.button}
                            onClick={() => setEditOverride(true)}
                            variant="contained"
                            color="primary"
                        >
                            Set Equipment Override
                        </Button>
                    </Box>
                ) : (
                    <Box py={1}>
                        <Button
                            className={globalClasses.button}
                            onClick={saveEquipmentOverride}
                            variant="contained"
                            color="primary"
                        >
                            Save Equipment Override
                        </Button>
                        <Button
                            className={globalClasses.button}
                            onClick={() => setEditOverride(false)}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </Box>
                ))}
        </div>
    );
};

export default MEPCostingDetails;
