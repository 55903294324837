import React from 'react';
import {
    Box,
    BoxProps,
    Checkbox,
    createStyles,
    makeStyles,
    TextField,
    TextFieldProps,
    Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkbox: {
            marginRight: theme.spacing(2),
        },
    })
);

interface SelectableProps {
    isSelectionEnabled: boolean;
    isSelected: boolean;
    isSelectedChanged: (isSelected: boolean) => void;
    isVisible: boolean;
    boxProps?: BoxProps;
}

export const SelectableComponent: React.FC<SelectableProps> = ({
    isSelectionEnabled,
    isSelected,
    isSelectedChanged,
    isVisible,
    boxProps,
    children,
}) => {
    const classes = useStyles();

    if (!isVisible) return null;

    return (
        <Box display="inline-flex" alignItems="center" {...boxProps}>
            {children}

            {isSelectionEnabled && (
                <Checkbox
                    className={classes.checkbox}
                    aria-label="Include in template"
                    checked={isSelected}
                    onChange={(e, checked) => isSelectedChanged(checked)}
                />
            )}
        </Box>
    );
};

export const SelectableTextField: React.FC<SelectableProps & TextFieldProps> = ({
    isSelectionEnabled,
    isSelected,
    isSelectedChanged,
    isVisible,
    boxProps,
    ...textFieldProps
}) => {
    return (
        <SelectableComponent
            isSelectionEnabled={isSelectionEnabled}
            isSelected={isSelected}
            isSelectedChanged={isSelectedChanged}
            isVisible={isVisible}
            boxProps={boxProps}
        >
            <TextField {...textFieldProps} />
        </SelectableComponent>
    );
};
