import { useSelector } from 'react-redux';
import { RootState } from 'store/state';
import { AppFeatures } from 'store/app/app.state';
import { PermissionFeature } from 'store/auth/auth.state';

export function useHasFeature() {
    const agentFeatures = useSelector<RootState, RootState['auth']['features']>(
        state => state.auth.features ?? []
    );
    // const appFeatures = useSelector<RootState, RootState['app']['features']>(
    //     state => state.app.features ?? []
    // );

    const isAdmin = agentFeatures[AppFeatures.ALL.ID];
    const hasFeature = (
        feature: PermissionFeature['ID'],
        subfeature?: string | string[]
    ): boolean => {
        // const appFeature = appFeatures.some(f => f.ID === feature);
        // if (!appFeature) return false;

        if (isAdmin) return true;

        const agentFeature = agentFeatures[feature];
        if (!agentFeature) return false;

        if (agentFeature && !subfeature) return true;
        const agentSubfeatures = Object.keys(agentFeature.subfeatures as { [key: string]: string });

        if (Array.isArray(subfeature)) {
            return agentSubfeatures.some(asf => subfeature.some(sf => sf === asf)) ?? false;
        }

        return agentSubfeatures.includes(subfeature!) ?? false;
    };

    return hasFeature;
}
