import React from 'react';
import {
    Select,
    makeStyles,
    Theme,
    createStyles,
    FormControl,
    InputLabel,
    Input,
    MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 250,
        },
        input: {},
    })
);

interface Props {
    onChange: (value: string | number) => void;
    value: string | number;
    label: string;
    optionsMap: Record<string, string | number>;
    disabled?: boolean;
}

const MapSelect: React.FC<Props> = ({ onChange, value, label, optionsMap, disabled = false }) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string | number);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="single-chip-label">{label}</InputLabel>
            <Select
                labelId="single-chip-label"
                value={value}
                onChange={handleChange}
                input={
                    <Input disabled={disabled} /* variant="outlined" */ id="select-multiple-chip" />
                }
                // MenuProps={MenuProps}
            >
                {Object.entries(optionsMap).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {key}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MapSelect;
