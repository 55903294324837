import React from 'react';
import Menu from 'views/Menu';
import Main from 'Routing';

import './main-base.scss';

const MainBase: React.FC = () => {
    return (
        <div className="content">
            <Menu />
            <Main />
        </div>
    );
};

export default MainBase;
