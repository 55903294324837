import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';

import { appRedirect } from 'store/app/app.actions';

import { needsUpdate } from 'utils';
import { TableFilterSchema } from 'components/organisms/EnhancedTableFilter/schemas';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import CityStateTypes, { CityStateListItemI, MissingOCSI } from './cityStateOverrides.types';

interface ListCityStateOverridePayload {
    records: CityStateListItemI[];
    totalRecordCount?: number;
    totalReplacementCost?: string;
    filters?: TableFilterSchema[];
}

export type CityStateAction =
    | {
          type: typeof CityStateTypes.GET_CITY_STATE_LOADING;
      }
    | {
          type: typeof CityStateTypes.LIST_CITY_STATE_FAIL;
          payload: {
              errorMessage: string;
          };
      }
    | {
          type: typeof CityStateTypes.LIST_CITY_STATE_SUCCESS;
          payload: ListCityStateOverridePayload;
      }
    | {
          type: typeof CityStateTypes.MISSING_CITY_STATE_SUCCESS;
          payload: { records: MissingOCSI[]; totalMissingCount: number };
      }
    | { type: typeof CityStateTypes.CREATE_CITY_STATE_LOADING }
    | { type: typeof CityStateTypes.CREATE_CITY_STATE_SUCCESS }
    | {
          type: typeof CityStateTypes.CREATE_CITY_STATE_FAIL;
          payload: { errorMessage: string };
      };

/*
interface GetCityStateI {
    records: CityStateListItemI[];
    totalRecords: {
        totalRecordCount: number;
    };
    filters?: { [key: string]: (string | null)[] };
}
*/
type GetCityStateI = CityStateListItemI[];

export const listCityStateOverrides = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, CityStateAction>) => {
        dispatch({
            type: CityStateTypes.GET_CITY_STATE_LOADING,
        });
        /*
        const filter = enhancedTableFiltersToApiFilters(filters);

        console.log('HEADERS', { sort, filter, limit, skip });

        */

        const data = await crewAPI.get<GetCityStateI>({ path: `overrides/citystate` });

        const payload: ListCityStateOverridePayload = {
            records: data,
            totalRecordCount: data.length,
        };

        /*
        if (data.filters) {
            const options = Object.entries(data.filters).map(([key, values]) => {
                return {
                    COLUMN_NAME: key,
                    COLUMN_LABEL: key.slice(4), //  toolTipTranslations[key], // replace when we get
                    COLUMN_TYPE: 'option',
                    IS_NULLABLE: values.includes(null),
                    OPTIONS: values.filter(value => typeof value == 'string') as string[],
                };
            });

            payload.filters = options;
        }
        */

        dispatch({
            type: CityStateTypes.LIST_CITY_STATE_SUCCESS,
            payload,
        });
    };
};

export const listMissingCityStateOverrides = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, CityStateAction>) => {
        const data = await crewAPI.get<
            {
                bld_city: string;
                bld_state: string;
            }[]
        >({ path: `overrides/citystate/citystatenooverrides` });

        const payload: { records: MissingOCSI[]; totalMissingCount: number } = {
            records: data.map(row => ({ ocs_city: row.bld_city, ocs_state: row.bld_state })),
            totalMissingCount: data.length,
        };

        dispatch({
            type: CityStateTypes.MISSING_CITY_STATE_SUCCESS,
            payload,
        });
    };
};

export const createOrUpdateCityStateOverride = (CityStatesData: CityStateListItemI) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, CityStateAction>,
        getState: () => RootState
    ) => {
        dispatch({
            type: CityStateTypes.CREATE_CITY_STATE_LOADING,
        });
        const state = getState();

        // const isUpdate = CityStatesData.CityStates_id && !!state.CityStates.CityStateDetails[CityStatesData.CityStates_id]?.CityStates;

        try {
            const ocs_id = await updateCityState({ CityStatesData, state, dispatch });

            if (ocs_id) {
                await dispatch(listCityStateOverrides());
            }

            dispatch(appRedirect(`/admin/overrides/city-state/${ocs_id || CityStatesData.ocs_id}`));
        } catch (error) {
            console.log('error in the CityStates create or update promise all', error);
            dispatch({
                type: CityStateTypes.CREATE_CITY_STATE_FAIL,
                payload: {
                    errorMessage: 'Failed to save this Override',
                },
            });
        }
    };
};

const updateCityState = async ({
    CityStatesData,
    state,

    dispatch,
}: {
    CityStatesData: CityStateListItemI;
    state: RootState;
    dispatch: ThunkDispatch<RootState, undefined, CityStateAction>;
}) => {
    const isUpdate =
        CityStatesData.ocs_id &&
        !!state.cityState.cityStateDetails[CityStatesData.ocs_id]?.cityState;

    const cleanData: { [key: string]: string | number } = {};
    Object.entries(CityStatesData).forEach(([key, value]) => {
        if (key.startsWith('ocs')) {
            cleanData[key] = value;
        }
    });

    delete cleanData.ocs_updated_date;
    delete cleanData.ocs_updated_by;
    delete cleanData.ocs_created_date;
    delete cleanData.ocs_created_by;

    const needsUpdates = needsUpdate(
        cleanData,
        state.cityState.cityStateDetails[CityStatesData.ocs_id]?.cityState
    );
    if (!needsUpdates) {
        console.log('no update needed for CityStates', cleanData);
        return;
    }

    const options: CrewRequestConfig = {
        data: cleanData,
        method: isUpdate ? 'PUT' : 'POST',
        path: `overrides/citystate`,
    };

    const data = await crewAPI.request(options);

    dispatch({
        type: CityStateTypes.CREATE_CITY_STATE_SUCCESS,
    });
    if (!isUpdate) {
        return data.new_id;
    }
    return cleanData.omp_id as string;
};
