import { UsersActions, UsersState, UsersActionTypes } from './users.types';

const defaultState: UsersState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    users: [],

    updatingRoles: {
        loading: false,
        error: '',
    },
};

export const usersReducer = (
    state: UsersState = defaultState,
    action: UsersActions
): UsersState => {
    switch (action.type) {
        case UsersActionTypes.GET_USERS_LOADING:
            return { ...state, loading: true, loaded: false };
        case UsersActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                loaded: true,
                users: action.payload.data,
            };
        case UsersActionTypes.GET_USERS_FAIL:
            return { ...defaultState, error: true, message: action.payload.errorMessage };

        case UsersActionTypes.GET_USER_LOADING:
            return { ...state, loading: true, loaded: false };
        case UsersActionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                loaded: true,
                activeUser: action.payload.data,
            };
        case UsersActionTypes.GET_USER_FAIL:
            return { ...defaultState, error: true, message: action.payload.errorMessage };

        case UsersActionTypes.UPDATING_ROLE_LOADING:
            return { ...state, updatingRoles: { loading: true, error: '' } };
        case UsersActionTypes.UPDATING_ROLE_SUCCESS:
            return { ...state, updatingRoles: { loading: false, error: '' } };
        case UsersActionTypes.UPDATING_ROLE_FAIL:
            return {
                ...state,
                updatingRoles: { loading: false, error: action.payload.errorMessage },
            };

        default:
            return state;
    }
};
