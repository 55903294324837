import { RolesActions, RolesState, RolesActionTypes } from './roles.types';

const defaultState: RolesState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    roles: [],
};

export const rolesReducer = (
    state: RolesState = defaultState,
    action: RolesActions
): RolesState => {
    switch (action.type) {
        case RolesActionTypes.GET_ROLES_LOADING:
            return { ...state, loading: true, loaded: false };
        case RolesActionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                loaded: true,
                roles: action.payload.data,
            };
        case RolesActionTypes.GET_ROLES_FAIL:
            return { ...defaultState, error: true, message: action.payload.errorMessage };

        case RolesActionTypes.GET_ROLE_PERMISSIONS_LOADING:
            return { ...state, loading: true, loaded: false };
        case RolesActionTypes.GET_ROLE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                loaded: true,
                rolePermissions: action.payload.data,
            };
        case RolesActionTypes.GET_ROLE_PERMISSIONS_FAIL:
            return { ...defaultState, error: true, message: action.payload.errorMessage };

        default:
            return state;
    }
};
