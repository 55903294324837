import { Auth } from 'aws-amplify';
import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';

import { appRedirect } from 'store/app/app.actions';
import { EnhancedTableFilterI, Field } from 'components/organisms/EnhancedTableFilter';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import utils, { needsUpdate } from 'utils';
import { TableFilterSchema } from 'components/organisms/EnhancedTableFilter/schemas';

import { crewAPI, CrewRequestConfig } from 'utils/API';
import tipTranslations from './tooltipTranslations';
import { EquipmentDetailsI } from './equipment.state';
import EquipmentsTypes, {
    EquipmentI,
    EquipmentCreateI,
    EquipmentListItemI,
} from './equipment.types';
import OptionsTypes from '../options/options.types';
import { OptionsAction } from '../options/options.actions';

interface EquipmentsError {
    message: string;
}

interface ListEquipSuccessPayload {
    records: EquipmentListItemI[];
    totalRecordCount?: number;
    totalReplacementCost?: string;
    filters?: TableFilterSchema[];
}

export type EquipmentsAction =
    | { type: typeof EquipmentsTypes.GET_EQUIPMENT_FAIL; payload: EquipmentsError }
    | { type: typeof EquipmentsTypes.GET_EQUIPMENT_LOADING }
    | { type: typeof EquipmentsTypes.GET_EQUIPMENT_SUCCESS; payload: { equipments: EquipmentI[] } }
    | {
          type: typeof EquipmentsTypes.GET_EQUIPMENT_DETAILS_FAIL;
          payload: {
              [key: string]: {
                  equipment: EquipmentListItemI;
                  errorMessage: string;
              };
          };
      }
    | {
          type: typeof EquipmentsTypes.GET_EQUIPMENT_DETAILS_SUCCESS;
          payload: { [key: string]: EquipmentDetailsI };
      }
    | { type: typeof EquipmentsTypes.LIST_EQUIPMENT_LOADING }
    | {
          type: typeof EquipmentsTypes.LIST_EQUIPMENT_FAIL;
          payload: {
              errorMessage: string;
          };
      }
    | {
          type: typeof EquipmentsTypes.LIST_EQUIPMENT_SUCCESS;
          payload: ListEquipSuccessPayload;
      }
    | { type: typeof EquipmentsTypes.CREATE_EQUIPMENTS_LOADING }
    | { type: typeof EquipmentsTypes.CREATE_EQUIPMENTS_SUCCESS }
    | { type: typeof EquipmentsTypes.CREATE_EQUIPMENTS_FAIL; payload: { errorMessage: string } }
    | { type: typeof EquipmentsTypes.CLEAR_EQUIPMENT_SAVED }
    | { type: typeof EquipmentsTypes.UPLOAD_ATTACHMENT_LOADING; payload: { type: string } }
    | { type: typeof EquipmentsTypes.UPLOAD_ATTACHMENT_SUCCESS; payload: { type: string } }
    | {
          type: typeof EquipmentsTypes.UPLOAD_ATTACHMENT_FAILURE;
          payload: { type: string; errorMessage: string };
      }
    | { type: EquipmentsTypes.UPDATE_TAGS; payload: { tags: string[]; equipmentID: string } }
    | { type: EquipmentsTypes.UPDATE_OVERRIDE_ERROR; payload: { errorMessage: string } }
    | { type: typeof EquipmentsTypes.CLEAR_OVERRIDE_ERROR };

const toolTipTranslations = tipTranslations();

export const getEquipmentDetails = (equipmentID: string, force?: boolean) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>,
        getState: () => RootState
    ) => {
        const rootState = getState();
        if (rootState.equipment.equipmentDetails[equipmentID] && !force) {
            return;
        }
        try {
            const promArray = [
                crewAPI.get({ path: `equipment/${equipmentID}` }),
                crewAPI.get({ path: `equipment/${equipmentID}/tags` }),
                crewAPI.get({ path: `mepcosting/equ-mep-map/${equipmentID}` }),
            ];
            const resArray = await Promise.all(promArray);

            const res = resArray[0] as EquipmentDetailsI;

            console.log(' in res of getEquipmentDetails', res);
            if (!res || !res.equipment) {
                throw Error('invalid response from get equipment details');
            }

            // eslint-disable-next-line prefer-destructuring
            res.equipment.equ_tags = resArray[1];
            res.equipment.equ_mep_id = resArray[2].emm_mep_id;

            const equipmentDetails: EquipmentDetailsI = res;
            equipmentDetails.equipment.total_replacement_cost = Number(
                equipmentDetails.equipment.total_replacement_cost
            );

            dispatch({
                type: EquipmentsTypes.GET_EQUIPMENT_DETAILS_SUCCESS,
                payload: {
                    [equipmentID]: res,
                },
            });
        } catch (error) {
            console.log('error api', error);
            dispatch({
                type: EquipmentsTypes.GET_EQUIPMENT_DETAILS_FAIL,
                payload: {
                    [equipmentID]: {
                        equipment: {} as EquipmentListItemI,
                        errorMessage: `Error getting the details for a equipment`,
                    },
                },
            });
        }
    };
};

interface ListEquipmentProps {
    sort: {
        field: string;
        ascdesc: 'asc' | 'desc';
    };
    filters: EnhancedTableFilterI[];
    limit: number;
    skip: number;
}
export const listEquipment = ({ sort, filters, limit, skip }: ListEquipmentProps) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>) => {
        dispatch({
            type: EquipmentsTypes.LIST_EQUIPMENT_LOADING,
        });
        try {
            const filter = enhancedTableFiltersToApiFilters(filters);

            const getRequestConfig: CrewRequestConfig = {
                headers: {
                    sort: JSON.stringify(sort),
                    filter,
                    limit,
                    skip,
                },
                path: `equipment`,
            };

            interface EquipmentListResponse {
                records: EquipmentListItemI[];
                totalRecords?: number;
                totalReplacementCost?: number;
                filters?: { [key: string]: string[] };
            }

            const data = await crewAPI.get<EquipmentListResponse>(getRequestConfig);

            const payload: ListEquipSuccessPayload = {
                records: data?.records,
                totalRecordCount: data?.totalRecords,
                totalReplacementCost: data?.totalReplacementCost
                    ? utils.numberWithCommas(data.totalReplacementCost, 2)
                    : undefined,
            };
            if (data.filters) {
                const options: Field[] = Object.entries(
                    data.filters as { [key: string]: (string | null)[] }
                ).map(([key, values]) => {
                    return {
                        COLUMN_NAME: key,
                        COLUMN_LABEL: toolTipTranslations[key],
                        COLUMN_TYPE: 'option',
                        IS_NULLABLE: values.includes(null),
                        OPTIONS: values.filter(value => typeof value === 'string') as string[],
                    };
                });

                // manual adding of the search by ID
                options.push({
                    COLUMN_NAME: 'equ_id',
                    COLUMN_LABEL: toolTipTranslations.equ_id,
                    COLUMN_TYPE: 'char(36)',
                    IS_NULLABLE: false,
                });

                payload.filters = options;
            }

            dispatch({
                type: EquipmentsTypes.LIST_EQUIPMENT_SUCCESS,
                payload,
            });
        } catch (error) {
            console.log('error list equipment', error);
            dispatch({
                type: EquipmentsTypes.LIST_EQUIPMENT_FAIL,
                payload: {
                    errorMessage: 'Unable to get the Equipment list',
                },
            });
        }
    };
};

export const enhancedTableFiltersToApiFilters = (filters: EnhancedTableFilterI[]) => {
    // TODO add logic for the comparator operations.
    const apiFilters: { [key: string]: (string | number)[] } = {};
    filters.map(({ field, operation, value }) => {
        switch (operation.value) {
            case 'eq':
                apiFilters[field.COLUMN_NAME] = value;
        }
        return null;
    });
    console.log('apiFilters', apiFilters);

    return JSON.stringify(apiFilters);
};

export const createOrUpdateEquipment = (equipmentData: EquipmentCreateI) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>,
        getState: () => RootState
    ) => {
        dispatch({
            type: EquipmentsTypes.CREATE_EQUIPMENTS_LOADING,
        });

        const state = getState();
        let isCreate = false;

        try {
            const user = await Auth.currentSession();
            if (!equipmentData.equ_id) {
                // put in for create equipment
                isCreate = true;
                const newID = await createEquipment({ equipmentData, state, user, dispatch });
                // eslint-disable-next-line no-param-reassign
                equipmentData.equ_id = newID;
            } else {
                await updateEquipment({ equipmentData, state, user, dispatch, isCreate });
            }

            await utils.promiseTimer(4000);

            await dispatch(getEquipmentDetails(equipmentData.equ_id, true));
            dispatch(appRedirect(`/assets/equipment/${equipmentData.equ_id}`));

            dispatch({
                type: EquipmentsTypes.CREATE_EQUIPMENTS_SUCCESS,
            });
        } catch (error: any) {
            console.log('error in the equipment create or update promise all', error);

            const errorMessage = `Unable to ${isCreate ? 'create' : 'update'} this equipment: ${
                error.message
            }`;

            dispatch({
                type: EquipmentsTypes.CREATE_EQUIPMENTS_FAIL,
                payload: { errorMessage },
            });
        }
    };
};

export const duplicateEquipment = (equipmentData: EquipmentCreateI) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>,
        getState: () => RootState
    ) => {
        dispatch({
            type: EquipmentsTypes.CREATE_EQUIPMENTS_LOADING,
        });

        const state = getState();

        try {
            const user = await Auth.currentSession();

            const equipmentID = await createEquipment({ equipmentData, state, user, dispatch });
            // eslint-disable-next-line no-param-reassign
            equipmentData.equ_id = equipmentID;

            await Promise.all([
                createOrUpdateMepEquipmentMap({
                    equipmentID,
                    newMEPID: equipmentData.mep_id || '',
                    state,
                }),
                updateEquipmentTags({
                    equipmentID,
                    newTags: equipmentData.equ_tags,
                    state,

                    dispatch,
                }),
            ]);
            await utils.promiseTimer(4000);

            await dispatch(getEquipmentDetails(equipmentData.equ_id, true));
            dispatch(appRedirect(`/assets/equipment/${equipmentData.equ_id}`));

            dispatch({
                type: EquipmentsTypes.CREATE_EQUIPMENTS_SUCCESS,
            });
        } catch (error: any) {
            console.log('error in the duplicate equipment', error);

            const errorMessage = `Unable to duplicate this equipment: ${error.message}`;

            dispatch({
                type: EquipmentsTypes.CREATE_EQUIPMENTS_FAIL,
                payload: { errorMessage },
            });
        }
    };
};

const createEquipment = async ({
    equipmentData,
}: {
    equipmentData: EquipmentCreateI;
    state: RootState;
    user: CognitoUserSession;
    dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>;
}) => {
    const cleanData: { [key: string]: string | number } = {};
    Object.entries(equipmentData).forEach(([key, value]) => {
        if (key.startsWith('equ')) {
            cleanData[key] = value;
        }
    });
    delete cleanData.equ_id;
    delete cleanData.equ_updated_date;
    delete cleanData.equ_updated_by;
    delete cleanData.equ_mep_id;
    delete cleanData.equ_created_by;
    delete cleanData.equ_created_date;
    delete cleanData.equ_tags;

    const data = await crewAPI.post({ data: cleanData, path: `equipment` });

    return data.new_id;
};

export const clearEquipmentSaved = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>) => {
        dispatch({
            type: EquipmentsTypes.CLEAR_EQUIPMENT_SAVED,
        });
    };
};

const updateEquipment = async ({
    equipmentData,
    state,
    user,
    dispatch,
    isCreate,
}: {
    equipmentData: EquipmentCreateI;
    state: RootState;
    user: CognitoUserSession;
    dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>;
    isCreate: boolean;
}) => {
    if (isCreate) {
        return;
    }
    const isUpdate =
        equipmentData.equ_id && !!state.equipment.equipmentDetails[equipmentData.equ_id]?.equipment;

    const cleanData: { [key: string]: string | number } = {};
    Object.entries(equipmentData).forEach(([key, value]) => {
        if (key.startsWith('equ')) {
            cleanData[key] = value;
        }
    });

    delete cleanData.equ_updated_date;
    delete cleanData.equ_updated_by;
    delete cleanData.equ_mep_id;
    delete cleanData.equ_created_by;
    delete cleanData.equ_created_date;
    delete cleanData.equ_tags;

    if (isUpdate) {
        const needsUpdates = needsUpdate(
            cleanData,
            state.equipment.equipmentDetails[equipmentData.equ_id]?.equipment
        );
        if (!needsUpdates) {
            console.log('no update needed fro equipment');
            return;
        }
    }

    const options: CrewRequestConfig = {
        data: cleanData,
        headers: {
            Authorization: `Bearer ${user.getIdToken().getJwtToken()}`,
        },
        method: isUpdate ? 'PUT' : 'POST',
        path: `equipment`,
    };

    const data = await crewAPI.request(options);

    if (!isUpdate) {
        dispatch(appRedirect(`/assets/equipment/${data.new_id}`));
    }
};

export const saveMEPCosting = ({ equipmentID, mepID }: { equipmentID: string; mepID: string }) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>,
        getState: () => RootState
    ) => {
        const state = getState();

        try {
            await createOrUpdateMepEquipmentMap({
                equipmentID,
                newMEPID: mepID,
                state,
            });
            await utils.promiseTimer(2000);
            dispatch(getEquipmentDetails(equipmentID, true));
        } catch (error) {
            console.log('unable to save MEP Costing');
        }
    };
};

const createOrUpdateMepEquipmentMap = async ({
    equipmentID,
    newMEPID,
    state,
}: {
    equipmentID: string;
    newMEPID: string;
    state: RootState;
}) => {
    // check if the MEP ID has changed. If not then no need to update it.
    const isUpdate = equipmentID && !!state?.equipment?.equipmentDetails?.[equipmentID]?.equipment;
    if (
        isUpdate &&
        // eslint-disable-next-line eqeqeq
        newMEPID == state?.equipment?.equipmentDetails?.[equipmentID]?.equipment?.mep_id
    ) {
        console.log('mep ID not changed');

        return;
    }

    if (!newMEPID) {
        if (!state?.equipment?.equipmentDetails?.[equipmentID]?.equipment?.mep_id) {
            console.log('No MEP selected so not deploying');
            return;
        }

        return crewAPI.delete({
            path: `mepcosting/equ-mep-map/${equipmentID}`,
        });
    }

    return crewAPI.post({
        path: `mepcosting/equ-mep-map`,
        data: { emm_mep_id: newMEPID, emm_equ_id: equipmentID },
    });
};

export const saveEquipmentTags = ({
    tags,
    equipmentID,
}: {
    tags: string[];
    equipmentID: string;
}) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction | OptionsAction>,
        getState: () => RootState
    ) => {
        const state = getState();
        try {
            await updateEquipmentTags({
                equipmentID,
                newTags: tags || [],
                state,
            });
            dispatch({
                type: EquipmentsTypes.UPDATE_TAGS,
                payload: { equipmentID, tags: tags || [] },
            });

            // Ensure any new tags appear in future options lists
            if (tags.length) {
                dispatch({
                    type: OptionsTypes.ADD_TAG_OPTIONS,
                    payload: { tags },
                });
            }
        } catch (error) {
            console.log('unable to save tags');
        }
    };
};

const updateEquipmentTags = async ({
    equipmentID,
    newTags = [],
    state,
}: {
    equipmentID: string;
    newTags?: string[];
    state: RootState;
    dispatch?: ThunkDispatch<RootState, undefined, EquipmentsAction>;
}) => {
    const oldTags = state?.equipment?.equipmentDetails?.[equipmentID]?.equipment?.equ_tags || [];

    if (oldTags === newTags) {
        console.log('tags not changed');
        return;
    }

    const tags = newTags.map(tag => tag.trim());

    const path = `/equipment/${equipmentID}/tags`;

    await crewAPI.post({ path, data: tags });
};

export const updateEquipmentOverride = (overrides: {
    oes_dollars: number;
    oes_reason: string;
    oes_equ_id: string;
}) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>) => {
        try {
            const path = `/overrides/equipment`;

            if (overrides.oes_dollars === 0) {
                return crewAPI.delete({ path: `${overrides.oes_equ_id}` });
            }
            await crewAPI.post({ path, data: overrides });
        } catch (error: any) {
            dispatch({
                type: EquipmentsTypes.UPDATE_OVERRIDE_ERROR,
                payload: { errorMessage: error.message || 'Error saving the equipment override' },
            });
        }
    };
};

export const clearOverrideError = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentsAction>) => {
        dispatch({ type: EquipmentsTypes.CLEAR_OVERRIDE_ERROR });
    };
};
