import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Paper, Button, Box } from '@material-ui/core';

import SortTable, {
    filterRows,
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import {
    EnhancedTableFilterI,
    EnhancedTableFilterProps,
} from 'components/organisms/EnhancedTableFilter';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';

import { Link } from 'react-router-dom';
import { BuildingI } from 'store/buildings/buildings.types';
import { useLocalStorage } from 'hooks/storage';
import { appRedirect } from 'store/app/app.actions';

// import DescriptionIcon from '@material-ui/icons/Description';
// import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { AppFeatures } from 'store/app/app.state';

// import WindPowerIcon from '@mui/icons-material/WindPower';
import ToysIcon from '@material-ui/icons/Toys';
import { BuildingExcelExport } from '../../molecules/BuildingExcelExport';

import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

const NewBuildingList: React.FC = () => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const buildingListArray = hooks.buildings.useBuildingList();
    const { loaded: buildingsLoaded } = hooks.buildings.useBuildingState();
    const hasFeature = hooks.user.useHasFeature();
    const buildingSchema = hooks.buildings.useBuildingSchema();

    const initialSort = 'bld_id';
    const [page, setPage] = React.useState(0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>(initialSort);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [filters, setFilters] = useLocalStorage<EnhancedTableFilterI[]>('buildingFilters', []);
    const [, setEquipmentFilters] = useLocalStorage<EnhancedTableFilterI[]>('equipmentFilters', []);

    const [loadingList, setLoadingList] = useState(true);

    const canViewBuildings = hasFeature(
        AppFeatures.BUILDINGS.ID,
        AppFeatures.BUILDINGS.subfeatures.view
    );

    const canEditBuildings = hasFeature(
        AppFeatures.BUILDINGS.ID,
        AppFeatures.BUILDINGS.subfeatures.update
    );

    useEffect(() => {
        if (buildingsLoaded) {
            setLoadingList(false);
        }
    }, [buildingListArray, buildingsLoaded]);

    interface Data extends BuildingI {
        view: string;
        viewEquipment: string;
    }

    const filterParams: EnhancedTableFilterProps = {
        schema: buildingSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setPage(0);
            setFilters(filters);
        },
    };

    const rawRowData: Data[] = buildingListArray.map(building => {
        return {
            ...building,
            view: 'show',
            viewEquipment: 'show',
        };
    });

    const unsortedRowData = filterRows(rawRowData, filterParams);

    const sortedRowData = stableSort<Data>(unsortedRowData, getComparator(orderDirection, orderBy));
    const rowData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // TODO Add more columns to the table
    const headCells: HeadCell<Data>[] = [
        { id: 'bld_name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'bld_city', numeric: false, disablePadding: false, label: 'City' },
        { id: 'bld_state', numeric: false, disablePadding: false, label: 'State' },
        { id: 'sec_name', numeric: true, disablePadding: false, label: 'Sub-Region' },
        ...(canViewBuildings
            ? ([{ id: 'view', numeric: false, disablePadding: false, label: 'View' }] as const)
            : []),
        { id: 'viewEquipment', numeric: false, disablePadding: false, label: 'FCA' },
    ];

    const rowJSX = (row: Data, index: number) => {
        const labelId = `building-table-row-${index}`;

        const equipmentFilter: EnhancedTableFilterI = {
            field: {
                COLUMN_NAME: 'bld_id',
                COLUMN_LABEL: 'building ID',
                COLUMN_TYPE: 'option',
                IS_NULLABLE: false,
                OPTIONS: [row.bld_id],
            },
            operation: {
                label: 'Equals One Of',
                value: 'eq',
                inputs: { number: 1, format: 'options' },
            },
            value: [row.bld_id],
        };

        return (
            <TableRow key={labelId}>
                <TableCell align="center">{row.bld_name}</TableCell>
                <TableCell align="center">{row.bld_city}</TableCell>
                <TableCell align="center">{row.bld_state}</TableCell>
                <TableCell align="center">{row.sec_name}</TableCell>

                {canViewBuildings && (
                    <TableCell align="center">
                        <Button
                            aria-label="View details"
                            className={globalClasses.tableActionButton}
                            component={Link}
                            to={`/assets/buildings/${row.bld_id}`}
                        >
                            <VisibilityIcon />
                        </Button>
                    </TableCell>
                )}
                <TableCell align="center">
                    <Button
                        aria-label="View equipment"
                        className={globalClasses.tableActionButton}
                        onClick={() => {
                            setEquipmentFilters([equipmentFilter]);
                            dispatch(appRedirect(`/assets/equipment`));
                        }}
                    >
                        <ToysIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    return (
        <Box>
            <Paper>
                <SortTable<Data>
                    filterParams={filterParams}
                    rows={rowData}
                    headCells={headCells}
                    rowJSX={rowJSX}
                    totalRowCount={unsortedRowData.length}
                    dense
                    loadingList={loadingList}
                    page={page}
                    setPage={setPage}
                    orderDirection={orderDirection}
                    orderBy={orderBy}
                    onOrderByChange={onOrderByChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={setRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100].sort((a: number, b: number) => a - b)}
                />
            </Paper>

            <Box display="flex" alignItems="center">
                {canEditBuildings && (
                    <Button
                        className={globalClasses.button}
                        component={Link}
                        to="/assets/buildings/create"
                        variant="contained"
                        color="primary"
                    >
                        Create new building
                    </Button>
                )}

                {!!sortedRowData?.length && <BuildingExcelExport buildings={sortedRowData} />}
            </Box>
        </Box>
    );
};

export default NewBuildingList;
