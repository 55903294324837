import React, { FC, useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert/Alert';

interface Props {
    hasError: boolean;
    message?: string;
    showDuration?: number | null;
}

const ErrorMessage: FC<Props> = ({
    hasError,
    message = 'An unknown error occurred',
    showDuration = 5000,
}) => {
    const [isErrorVisible, setIsErrorVisible] = useState(hasError);

    useEffect(() => {
        setIsErrorVisible(hasError);
    }, [hasError, setIsErrorVisible]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isErrorVisible}
            autoHideDuration={showDuration}
            onClose={() => setIsErrorVisible(false)}
        >
            <MuiAlert elevation={6} variant="filled" severity="error">
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default ErrorMessage;
