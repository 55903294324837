import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAppRedirect } from 'store/app/app.actions';

interface Props {
    to: string;
}

const MyRedirect: React.FC<Props> = ({ to }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearAppRedirect());
    }, [dispatch]);

    const history = useHistory();
    history.push(to);

    return <div />;
};

export default MyRedirect;
