import { InspectionsAction } from './inspections.actions';
import InspectionsState from './inspections.state';
import InspectionsTypes from './inspections.types';

const defaultState: InspectionsState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    currentInspection: undefined,
    inspectionDetails: {},
};

const inspectionsReducer = (
    state: InspectionsState = defaultState,
    action: InspectionsAction
): InspectionsState => {
    switch (action.type) {
        case InspectionsTypes.GET_INSPECTION_LOADING:
            return { ...state, loading: true, loaded: false };
        case InspectionsTypes.GET_INSPECTION_SUCCESS:
            return {
                ...state,
                inspectionDetails: {
                    ...state.inspectionDetails,
                    [action.payload.inspection.ins_id]: action.payload,
                },
                error: false,
                loading: false,
                loaded: true,
                currentInspection: undefined,
            };
        case InspectionsTypes.GET_INSPECTION_FAIL:
            return {
                ...state,
                inspectionDetails: {
                    ...state.inspectionDetails,
                    [action.payload.inspectionID]: { errorMessage: action.payload.message },
                },
                currentInspection: undefined,
                error: true,
            };

        case InspectionsTypes.CREATE_INSPECTION_SUCCESS:
            return {
                ...state,
                error: false,
                message: '',
                loading: false,
                loaded: true,
                currentInspection: action.payload.inspectionID,
            };
        case InspectionsTypes.CREATE_INSPECTION_FAIL:
            return {
                ...state,
                currentInspection: undefined,
                error: true,
                message: action.payload.errorMessage,
                loading: false,
            };

        default:
            return state;
    }
};

export default inspectionsReducer;
