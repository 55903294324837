import { useSelector } from 'react-redux';
import InspectionsState from 'store/inspections/inspections.state';
import { RootState } from '../store/state';

interface InspectionLoadingState {
    loading: boolean;
    error: boolean;
    message: string;
    loaded: boolean;
    currentInspection: InspectionsState['currentInspection'];
}

export const useInspectionLoadingState = () => {
    const loadingState = useSelector<RootState, InspectionLoadingState>(state => {
        return {
            loading: state.inspections.loading,
            error: state.inspections.error,
            message: state.inspections.message,
            loaded: state.inspections.loaded,
            currentInspection: state.inspections.currentInspection,
        };
    });
    return loadingState;
};

export const useInspectionDetails = () => {
    const details = useSelector<RootState, RootState['inspections']['inspectionDetails']>(
        state => state.inspections.inspectionDetails
    );

    return details;
};
