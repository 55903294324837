import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useApp = () => {
    const details = useSelector<RootState, RootState['app']>(state => state.app);

    return details;
};

export const useRouting = () => {
    const details = useSelector<RootState, RootState['app']['redirect']>(
        state => state.app.redirect
    );

    return details;
};
