import { UserAction } from './user.actions';
import UserState from './user.state';
import { UserTypes } from './user.types';

const defaultState: UserState = {};

const userReducer = (state: UserState = defaultState, action: UserAction): UserState => {
    switch (action.type) {
        case UserTypes.LOGIN:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default userReducer;
