import { DashboardActions } from './dashboard.actions';
import DashboardState from './dashboard.state';
import DashboardTypes from './dashboard.types';

const defaultState: DashboardState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    dashboard: undefined,
};

const dashboardReducer = (
    state: DashboardState = defaultState,
    action: DashboardActions
): DashboardState => {
    switch (action.type) {
        case DashboardTypes.GET_DASHBOARD_LOADING:
            return { ...state, loading: true, loaded: false };
        case DashboardTypes.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboard: action.payload.data,
                error: false,
                loading: false,
                loaded: true,
                lastTier: action.payload.tier,
                lastTierID: action.payload.tierID,
            };
        case DashboardTypes.GET_DASHBOARD_FAIL:
            return { ...defaultState, message: action.payload.errorMessage, error: true };

        default:
            return state;
    }
};

export default dashboardReducer;
