import { BarChartRowProps } from 'components/atoms/BarChartRow';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import hooks from 'hooks';
import { useLocalStorage } from 'hooks/storage';
import React from 'react';
import { useDispatch } from 'react-redux';
import { appRedirect } from 'store/app/app.actions';
import { AppFeatures } from 'store/app/app.state';
import { Condition, ConditionI, Hero, Sublevel } from 'store/dashboard/dashboard.types';
import tipTranslations from 'store/equipment/tooltipTranslations';
import BarChart from '../BarChart';

const toolTipTranslations = tipTranslations();

interface Props {
    data: Hero | Sublevel;
}

const DashboardConditionChart: React.FC<Props> = ({ data }) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filters, setEquipmentFilters] = useLocalStorage<EnhancedTableFilterI[]>(
        'equipmentFilters',
        []
    );
    const hasFeature = hooks.user.useHasFeature();
    const hideNoData = !hasFeature(
        AppFeatures.DASHBOARD.ID,
        AppFeatures.DASHBOARD.subfeatures.viewNoData
    );

    const navigateTo = ({
        context,
        filter,
    }: {
        context: string;
        filter?: { [key: string]: string[] }[];
    }) => {
        // eslint-disable-next-line default-case
        switch (context) {
            case 'equipment':
                if (filter) {
                    const equipmentFilter: EnhancedTableFilterI[] = filter.map(
                        rawFilterToEnhancedTableFilter
                    );
                    console.log('equipmentFilter', equipmentFilter);
                    setEquipmentFilters(equipmentFilter);
                }

                dispatch(appRedirect('/assets/equipment'));
                break;
            case 'buildings':
                dispatch(appRedirect('/assets/buildings'));
        }
    };

    const maxPercentage = Math.max(...Object.values(data.conditions).map(d => d.percent));
    const globalStyles = getComputedStyle(document.documentElement);

    const barChartData: BarChartRowProps[] = Object.entries(data.conditions)
        .filter(condition => {
            if (hideNoData && condition[0] === 'nodata') {
                return null;
            }
            return true;
        })
        .map(([key, row]: [string, ConditionI]) => {
            return {
                title: row.label,
                percentage: (row.percent * 100) / maxPercentage,
                count: String(row.count),
                color: globalStyles.getPropertyValue(`--color-${key}`),
                onClick: () => {
                    console.log('baarChart clicked ', row.label);
                    navigateTo(
                        getConditionFilter(
                            { tier: data.tier, tierId: data.tierId },
                            getCondition(row.label)
                        )
                    );
                },
            };
        });

    const getCondition = (title: string) => {
        switch (title) {
            case 'Excellent':
                return Condition.EXCELLENT;
            case 'Good':
                return Condition.GOOD;
            case 'Fair':
                return Condition.FAIR;
            case 'Poor':
                return Condition.POOR;
            case 'No-Data':
                return Condition.NODATA;
            default:
                return Condition.NODATA;
        }
    };

    return <BarChart rows={barChartData} title="Condition Assessment" />;
};

export default DashboardConditionChart;

const rawFilterToEnhancedTableFilter = (rawFilter: { [key: string]: string[] }) => {
    let enhancedTableFilter: EnhancedTableFilterI = {} as EnhancedTableFilterI;
    Object.entries(rawFilter).forEach(([key, values]) => {
        enhancedTableFilter = {
            field: {
                COLUMN_NAME: key,
                COLUMN_LABEL: toolTipTranslations[key],
                COLUMN_TYPE: 'option',
                IS_NULLABLE: false,
                OPTIONS: values,
            },
            operation: {
                label: 'Equals One Of',
                value: 'eq',
                inputs: { number: 1, format: 'options' },
            },
            value: values,
        };
    });
    return enhancedTableFilter;
};

const getConditionFilter = (props: { tier: string; tierId: string }, bar: Condition) => {
    const tierFilter = getTierFilter(props);
    const conditionFilter = { ins_condition: [bar] };
    const filter = tierFilter ? [tierFilter, conditionFilter] : [conditionFilter];
    const results = { context: `equipment`, filter };

    return results;
};

const getTierFilter = (props: {
    tier: string;
    tierId: string;
}): { [key: string]: string[] } | undefined => {
    switch (props.tier.toLowerCase()) {
        case 'region':
            return { reg_id: [`${props.tierId}`] };
        case 'district':
            return { dis_id: [`${props.tierId}`] };
        case 'sector':
            return { sec_id: [`${props.tierId}`] };
        default:
    }
};
