import { AppFeatures } from 'store/app/app.state';
import {
    MappedRolePermissions,
    MappedSubfeatures,
    RolePermissions,
    Subfeatures,
} from 'store/roles/roles.types';

export const mapPermissions = (rolePermissions: RolePermissions): MappedRolePermissions => {
    return Object.values(AppFeatures).reduce((acc, cur) => {
        const subfeatures = mapSubfeatures(
            AppFeatures[cur.ID]?.subfeatures,
            rolePermissions[cur.ID]?.subfeatures
        );

        const canHaveSubfeatures = Object.keys(AppFeatures[cur.ID]?.subfeatures).length > 0;

        return {
            ...acc,
            [cur.ID]: {
                ...cur,
                canHaveSubfeatures,
                subfeatures,
            },
        };
    }, {} as MappedRolePermissions);
};

// map over all subfeatures and add field isChecked
// return true if included in rolePermissions
const mapSubfeatures = (
    allSubfeatures?: Subfeatures,
    subfeatures?: Subfeatures
): MappedSubfeatures =>
    Object.keys(allSubfeatures ?? {})?.map(sf => ({
        name: sf,
        isChecked: Object.keys(subfeatures ?? {}).includes(sf),
    })) ?? [];
