import { Box, Paper, CircularProgress } from '@material-ui/core';
import { useRolePermissions, useRolesData } from 'hooks/roles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getRolePermissions, getRoles } from 'store/roles/roles.actions';
import { Permissions } from '../../components/molecules/Permissions/Permissions';

type Props = RouteComponentProps<{ roleID: string }>;

export const UsersRolesDetails: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const { roleID } = match.params;

    const roles = useRolesData();

    const permissions = useRolePermissions();

    const role = roles.find(r => r.rol_id === roleID);

    useEffect(() => {
        dispatch(getRolePermissions(roleID));

        dispatch(getRoles());
    }, [dispatch, roleID]);

    return (
        <Box paddingX="10px">
            <h1>Edit Role</h1>

            {!permissions ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box display="flex">
                    <Paper style={{ marginRight: '10px', width: '100%', padding: '10px' }}>
                        <h2>Role: {role?.rol_description}</h2>

                        <Permissions permissions={permissions} />
                    </Paper>
                </Box>
            )}
        </Box>
    );
};
