import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/state';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import AuthTypes, { UserI } from './auth.types';

export type AuthAction = { type: AuthTypes.SUCCESS; payload: UserI };

export const getMyDetails = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AuthAction>) => {
        try {
            const getRequestConfig: CrewRequestConfig = {
                path: `users/me`,
            };

            const user = await crewAPI.get(getRequestConfig);

            user.features = user.permissions;
            delete user.permissions;

            dispatch({
                type: AuthTypes.SUCCESS,
                payload: user,
            });
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };
};
