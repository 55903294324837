import { RolePermissions } from 'store/roles/roles.types';

export enum UsersActionTypes {
    GET_USERS_FAIL = 'GET_USERS_FAIL',
    GET_USERS_LOADING = 'GET_USERS_LOADING',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',

    GET_USER_FAIL = 'GET_USER_FAIL',
    GET_USER_LOADING = 'GET_USER_LOADING',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',

    UPDATING_ROLE_FAIL = 'UPDATING_ROLE_FAIL',
    UPDATING_ROLE_LOADING = 'UPDATING_ROLE_LOADING',
    UPDATING_ROLE_SUCCESS = 'UPDATING_ROLE_SUCCESS',

    UPDATING_USER_ACCESS_TIER_SUCCESS = 'UPDATING_USER_ACCESS_TIER_SUCCESS',
    UPDATING_USER_ACCESS_TIER_FAIL = 'UPDATING_USER_ACCESS_TIER_FAIL',
}

export type UsersActions =
    | { type: typeof UsersActionTypes.GET_USERS_FAIL; payload: { errorMessage: string } }
    | { type: typeof UsersActionTypes.GET_USERS_LOADING }
    | { type: typeof UsersActionTypes.GET_USERS_SUCCESS; payload: { data: any } }
    //
    | { type: typeof UsersActionTypes.GET_USER_FAIL; payload: { errorMessage: string } }
    | { type: typeof UsersActionTypes.GET_USER_LOADING }
    | { type: typeof UsersActionTypes.GET_USER_SUCCESS; payload: { data: any } }
    //
    | { type: typeof UsersActionTypes.UPDATING_ROLE_FAIL; payload: { errorMessage: string } }
    | { type: typeof UsersActionTypes.UPDATING_ROLE_LOADING }
    | { type: typeof UsersActionTypes.UPDATING_ROLE_SUCCESS }
    | { type: typeof UsersActionTypes.UPDATING_USER_ACCESS_TIER_SUCCESS }
    | {
          type: typeof UsersActionTypes.UPDATING_USER_ACCESS_TIER_FAIL;
          payload: { errorMessage: string };
      };

export interface UsersState {
    error: boolean;
    message: string;
    loading: boolean;
    loaded: boolean;

    users: User[];
    activeUser?: UserDetail;

    updatingRoles: {
        loading: boolean;
        error: string;
    };
}

export interface User {
    usr_id: string;
    usr_email: string;
    usr_aws_id: string;
    usr_tier_type: string;
    usr_tier_id: string;
    usr_fname: string;
    usr_lname: string;
    usr_title: string;
    usr_status?: string;
    usr_created_by?: string;
    usr_created_date: Date;
    usr_updated_date?: Date;
    usr_updated_by?: string;
    usr_is_system_admin: number;
    usr_is_user_admin: number;
}

export interface UserDetail {
    usr_id: string;
    usr_email: string;
    usr_aws_id: string;
    usr_tier_type: string;
    usr_tier_id: string;
    usr_fname: string;
    usr_lname: string;
    usr_title: string;
    usr_status: string;
    usr_created_date: Date;
    usr_is_system_admin: number;
    usr_is_user_admin: number;
    roles: Role[];
    permissions: RolePermissions;
}

export interface Role {
    rol_id: string;
    rol_description: string;
    rol_long_description: string;
}
