import { useSelector } from 'react-redux';
import { RootState } from 'store/state';

export const useUsersLoading = () =>
    useSelector<RootState, RootState['users']['loading']>(state => state.users.loading);
export const useUsersData = () =>
    useSelector<RootState, RootState['users']['users']>(state => state.users.users);
export const useUserData = () =>
    useSelector<RootState, RootState['users']['activeUser']>(state => state.users.activeUser);
export const useUpdatingRoles = () =>
    useSelector<RootState, RootState['users']['updatingRoles']>(state => state.users.updatingRoles);
