import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IconButton } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { Condition, Hero, Levels } from 'store/dashboard/dashboard.types';
import './DashboardHero.scss';
import { Link } from 'react-router-dom';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import tipTranslations from 'store/equipment/tooltipTranslations';
import utils from 'utils';
import DashboardDetails from '../DashboardDetails';
import DashboardConditionChart from '../DashboardConditionChart';

const useStyles = makeStyles({
    upButton: {
        visibility: (props: Hero) => (props.linkParent ? 'visible' : 'hidden'),
    },
});

export const getAvatar = <T extends { tierId: string; tier: string }>(props: T) => {
    if (props.tier === 'corporate') {
        return 'C';
    }
    return (
        <svg height="40" width="40">
            <rect height="40" width="40" />
            <text
                fontSize={props.tierId.length > 3 ? '13px' : '18px'}
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="white"
            >
                {props.tierId}
            </text>
        </svg>
    );
};

interface LevelCount {
    sectors: number;
    districts: number;
    regions: number;
}

export const getLocations = <T extends LevelCount>(props: T, tier: Levels) => {
    let results = '';
    // console.log(`Tier: ${tier} value: ${props[tier]}  props: ${JSON.stringify(props)}`)
    if (props[tier]) {
        results += `${tier}: ${props[tier]}`;
    }
    return results;
};

export const getConditionFilter = (props: { tier: string; tierId: string }, bar: Condition) => {
    const tierFilter = getTierFilter(props);
    const conditionFilter = { ins_condition: [bar] };
    const filter = tierFilter ? [tierFilter, conditionFilter] : [conditionFilter];
    const results = { context: `equipment`, filter };

    return results;
};

export const getStructureFilter = (props: { tier: string; tierId: string }, structure: string) => {
    const tierFilter = getTierFilter(props);
    console.log('tierFilter', tierFilter);
    const results = tierFilter
        ? { context: structure, filter: [tierFilter] }
        : { context: structure };

    return results;
};

export const getTierFilter = (props: {
    tier: string;
    tierId: string;
}): { [key: string]: string[] } | undefined => {
    switch (props.tier.toLowerCase()) {
        case 'region':
            return { reg_id: [`${props.tierId}`] };
        case 'district':
            return { dis_id: [`${props.tierId}`] };
        case 'sector':
            return { sec_id: [`${props.tierId}`] };
        default:
    }
};
const toolTipTranslations = tipTranslations();
export const rawFilterToEnhancedTableFilter = (rawFilter: { [key: string]: string[] }) => {
    let enhancedTableFilter: EnhancedTableFilterI = {} as EnhancedTableFilterI;
    Object.entries(rawFilter).forEach(([key, values]) => {
        enhancedTableFilter = {
            field: {
                COLUMN_NAME: key,
                COLUMN_LABEL: toolTipTranslations[key],
                COLUMN_TYPE: 'option',
                IS_NULLABLE: false,
                OPTIONS: values,
            },
            operation: {
                label: 'Equals One Of',
                value: 'eq',
                inputs: { number: 1, format: 'options' },
            },
            value: values,
        };
    });
    return enhancedTableFilter;
};

const DashboardHero = ({ data }: { data: Hero }) => {
    const classes = useStyles(data);

    return (
        <Card className="DashboardHero" key={data.tierId}>
            <CardHeader
                title={calculateCardTitle({ data })}
                action={
                    <div>
                        {!!data.linkParent && (
                            <Link to={data.linkParent}>
                                <IconButton aria-label="up" className={classes.upButton}>
                                    <ArrowUpwardIcon />
                                </IconButton>
                            </Link>
                        )}
                        <Link to="/dashboards">
                            <IconButton aria-label="home" className={classes.upButton}>
                                <HomeIcon />
                            </IconButton>
                        </Link>
                    </div>
                }
            />
            <CardContent className="cardContent">
                <DashboardDetails
                    buildingCount={data.buildingCount}
                    equipmentCount={data.equipmentCount}
                    regionCount={data.regions}
                    districtCount={data.districts}
                    sectorCount={data.sectors}
                    tier={data.tier}
                    tierID={data.tierId}
                />
                <DashboardConditionChart data={data} />
            </CardContent>
        </Card>
    );
};

export default DashboardHero;

const calculateCardTitle = ({ data }: { data: Hero }) => {
    const tierName = utils.tierToName(data.tier);
    if (data.tier === 'corporate') return tierName;
    return `${tierName} - ${data.tierId}`;
};
