enum OptionsTypes {
    GET_OPTIONS_FAIL = 'GET_OPTIONS_FAIL',
    GET_OPTIONS_LOADING = 'GET_OPTIONS_LOADING',
    GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS',
    ADD_TAG_OPTIONS = 'ADD_TAG_OPTIONS',
    GET_SUBSYSTEM_SUCCESS = 'GET_SUBSYSTEM_SUCCESS',
}

export default OptionsTypes;

export type OptionsMepSystem = string[];

export type RegionOptions = {
    id: string;
    districts: {
        id: string;
        sectors: {
            id: string;
        }[];
    }[];
}[];

export interface MEPItem {
    mep_id: string;
    mep_system: string;
    mep_description: string;
    mep_cost_per_unit: string;
    mep_unit_of_measure: string;
    mep_capacity: string;
    mep_capacity_measure: string;
    mep_category: string;
    mep_type: string;
}
