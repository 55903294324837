import { BuildingsAction } from './buildings.actions';
import BuildingsState from './buildings.state';
import BuildingsTypes from './buildings.types';

export const defaultState: BuildingsState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,
    isSaving: false,

    buildings: [],
    buildingDetails: {},
    offlineSync: {
        errors: {
            fileErrors: [],
            buildingErrors: [],
            equipmentErrors: [],
        },
        loading: false,
        success: false,
    },
};

const upsertBuildingToList = (existingBuildings, newBuilding) => {
    const updatedBuildingsArray = [...existingBuildings];

    const existingBuildingIndex = updatedBuildingsArray.findIndex(
        current => current.bld_id === newBuilding.bld_id
    );

    if (existingBuildingIndex === -1) {
        updatedBuildingsArray.unshift(newBuilding);
        return updatedBuildingsArray;
    }

    updatedBuildingsArray[existingBuildingIndex] = newBuilding;
    return updatedBuildingsArray;
};

const buildingsReducer = (
    state: BuildingsState = defaultState,
    action: BuildingsAction
): BuildingsState => {
    switch (action.type) {
        case BuildingsTypes.GET_BUILDINGS_LOADING:
            return { ...state, loading: true, loaded: false, error: false, message: '' };
        case BuildingsTypes.GET_BUILDINGS_SUCCESS:
            return { ...state, ...action.payload, loading: false, loaded: true };
        case BuildingsTypes.GET_BUILDINGS_FAIL:
            return { ...defaultState, ...action.payload, error: true };
        case BuildingsTypes.GET_BUILDING_DETAILS_SUCCESS:
            return { ...state, buildingDetails: { ...state.buildingDetails, ...action.payload } };
        case BuildingsTypes.GET_BUILDING_DETAILS_FAIL:
            return { ...state, buildingDetails: { ...state.buildingDetails, ...action.payload } };
        case BuildingsTypes.SAVE_BUILDING_LOADING:
            return { ...state, isSaving: true, error: false, message: '' };
        case BuildingsTypes.SAVE_BUILDING_SUCCESS:
            return {
                ...state,
                buildings: upsertBuildingToList(state.buildings, action.payload.building),
                buildingDetails: action.payload.isCreate
                    ? state.buildingDetails
                    : {
                          ...state.buildingDetails,
                          [action.payload.building.bld_id]: {
                              ...state.buildingDetails[action.payload.building.bld_id],
                              building: action.payload.building,
                          },
                      },
                isSaving: false,
            };
        case BuildingsTypes.SAVE_BUILDING_FAIL:
            return {
                ...state,
                isSaving: false,
                error: true,
                message: `Error saving building: ${action.payload.errorMessage}`,
            };

        case BuildingsTypes.UPLOAD_OFFLINE_BUILDING:
            return {
                ...state,
                offlineSync: {
                    ...state.offlineSync,
                    loading: true,
                },
            };
        case BuildingsTypes.UPLOAD_OFFLINE_BUILDING_SUCCESS:
            return {
                ...state,
                offlineSync: {
                    loading: false,
                    success: true,
                    errors: {
                        fileErrors: [],
                        buildingErrors: [],
                        equipmentErrors: [],
                    },
                },
            };
        case BuildingsTypes.UPLOAD_OFFLINE_BUILDING_ERROR:
            return {
                ...state,
                offlineSync: {
                    loading: false,
                    success: false,
                    errors: action.payload,
                },
            };
        case BuildingsTypes.UPLOAD_OFFLINE_BUILDING_CLEAR:
            return {
                ...state,
                offlineSync: {
                    loading: false,
                    success: false,
                    errors: {
                        fileErrors: [],
                        buildingErrors: [],
                        equipmentErrors: [],
                    },
                    buildingId: undefined,
                },
            };
        default:
            return state;
    }
};

export default buildingsReducer;
