import * as inspections from './useInspectionHooks';
import * as buildings from './useBuildingHooks';
import * as equipment from './useEquipmentHooks';
import * as equipmentTemplate from './useEquipmentTemplateHooks';
import * as adminSettings from './useAdminSettingsHooks';
import * as options from './useOptionsHooks';
import * as app from './appHooks';
import * as mep from './mepHooks';
import * as mepOverrides from './mepOverridesHooks';
import * as dashboard from './dashboardHooks';
import * as user from './userHooks';
import * as cityStateOverrides from './cityStateOverrideHooks';
import * as style from './useGlobalStyles';

const hooks = {
    inspections,
    buildings,
    equipment,
    equipmentTemplate,
    adminSettings,
    options,
    app,
    mep,
    mepOverrides,
    dashboard,
    user,
    cityStateOverrides,
    style,
};

export default hooks;
