import { MEPOverridesAction } from './mepOverrides.actions';
import MEPOverridesState from './mepOverrides.state';
import MEPOverrideTypes from './mepOverrides.types';

const defaultState: MEPOverridesState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    mepOverridesDetails: {},
    mepOverridesList: [],
    mepOverridesListCount: 0,

    mepSystemOverrides: {},
};

const mepOverridesReducer = (
    state: MEPOverridesState = defaultState,
    action: MEPOverridesAction
): MEPOverridesState => {
    switch (action.type) {
        case MEPOverrideTypes.GET_MEP_OVERRIDES_LOADING:
            return { ...state, loading: true, loaded: false };
        case MEPOverrideTypes.GET_MEP_OVERRIDES_SUCCESS:
            return { ...state, ...action.payload, error: false, loading: false, loaded: true };
        case MEPOverrideTypes.GET_MEP_OVERRIDES_FAIL:
            return { ...defaultState, ...action.payload, error: true };
        case MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_SUCCESS:
            return {
                ...state,
                mepOverridesDetails: { ...state.mepOverridesDetails, ...action.payload },
            };
        case MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_FAIL:
            return {
                ...state,
                mepOverridesDetails: { ...state.mepOverridesDetails, ...action.payload },
            };

        case MEPOverrideTypes.LIST_MEP_OVERRIDES_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const mepOverridesDetails = { ...state.mepOverridesDetails };
            action.payload.records.forEach(record => {
                mepOverridesDetails[record.omp_id] = { mepOverride: record };
            });

            return {
                ...state,
                mepOverridesDetails,
                mepOverridesList: [...action.payload.records],
                loaded: true,
                mepOverridesListCount:
                    typeof action.payload?.totalRecordCount === 'number'
                        ? action.payload.totalRecordCount
                        : state.mepOverridesListCount,
            };

        case MEPOverrideTypes.GET_MEP_SYSTEM_OVERRIDES_SUCCESS:
            return {
                ...state,
                mepSystemOverrides: {
                    ...state.mepSystemOverrides,
                    [action.payload.mepSystem]: action.payload.records,
                },
            };
        default:
            return state;
    }
};

export default mepOverridesReducer;
