import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Paper, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
// import { listMEP } from 'store/mep/mep.actions';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';
import queryString from 'query-string';
import { RouteComponentProps, Link } from 'react-router-dom';
import './MEPOverrideList.scss';
import { useLocalStorage } from 'hooks/storage';
import { MEPOverrideListItemI } from 'store/mepOverrides/mepOverrides.types';
import { listMEPOverrides } from 'store/mepOverrides/mepOverrides.actions';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

interface Props {
    location: RouteComponentProps['location'];
}

const MEPOverrideList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const overrideListArray = hooks.mepOverrides.useOverrideList();
    const overridesLoaded = hooks.mepOverrides.useOverrideListLoaded();
    const overridesLength = hooks.mepOverrides.useOverrideListLength();
    // const mepSchema = hooks.mep.useMEPSchema();

    const { pagenum } = queryString.parse(location.search) as {
        [key: string]: string | string[];
    };

    const initialSort = 'omp_description';
    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>(initialSort);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filters] = useLocalStorage<EnhancedTableFilterI[]>('mepOverrideFilters', []);

    const [loadingList, setLoadingList] = useState(true);

    useEffect(
        () => {
            setLoadingList(true);
            dispatch(
                listMEPOverrides({
                    sort: {
                        field: orderBy,
                        ascdesc: orderDirection,
                    },
                    limit: rowsPerPage,
                    skip: rowsPerPage * page,
                    filters,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            /* orderBy, orderDirection, rowsPerPage, page, filters */
        ]
    );

    useEffect(() => {
        console.log('mep list loaded');
        if (overridesLoaded) {
            setLoadingList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overrideListArray]);

    interface Data extends MEPOverrideListItemI {
        view: string;
    }

    const unsortedRowData: Data[] = overrideListArray.map(omp => {
        return {
            ...omp,
            view: 'show',
        };
    });

    const sortedRowData = stableSort<Data>(unsortedRowData, getComparator(orderDirection, orderBy));
    const rowData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // TODO Add more columns to the table
    const headCells: HeadCell<Data>[] = [
        { id: 'omp_id', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'omp_description', numeric: false, disablePadding: false, label: 'Description' },

        { id: 'omp_mep_system', numeric: true, disablePadding: false, label: 'MEP System' },
        { id: 'omp_multiplier', numeric: false, disablePadding: false, label: 'Multiplier' },
        { id: 'omp_fixed_amount', numeric: false, disablePadding: false, label: 'Fixed Amount' },

        { id: 'view', numeric: false, disablePadding: false, label: 'View' },
    ];

    const rowJSX = (row: Data) => {
        return (
            <TableRow key={row.omp_id}>
                <TableCell>{row.omp_id}</TableCell>
                <TableCell align="right">{row.omp_description}</TableCell>
                <TableCell align="right">{row.omp_mep_system}</TableCell>
                <TableCell align="right">{`${Number(row.omp_multiplier) * 100}%`}</TableCell>

                <TableCell align="right">{`$${row.omp_fixed_amount}`}</TableCell>

                <TableCell align="center">
                    <Button
                        aria-label="View details"
                        className={globalClasses.tableActionButton}
                        component={Link}
                        to={`/admin/overrides/mep/${row.omp_id}`}
                    >
                        <DescriptionIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    /*
    const filterParams: EnhancedTableFilterProps = {
        schema: mepSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setPage(0);
            setFilters(filters);
        },
    };
    */

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    return (
        <div className="newMEPList">
            <Paper>
                <SortTable<Data>
                    // tableTitle="MEP List"
                    // filterParams={filterParams}
                    rows={rowData}
                    headCells={headCells}
                    rowJSX={rowJSX}
                    totalRowCount={overridesLength}
                    dense
                    loadingList={loadingList}
                    page={page}
                    setPage={setPage}
                    orderDirection={orderDirection}
                    orderBy={orderBy}
                    onOrderByChange={onOrderByChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={setRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20].sort((a: number, b: number) => a - b)}
                />
                <Link to="/admin/overrides/mep/create">
                    <Button variant="contained" color="primary">
                        Create New MEP Override
                    </Button>
                </Link>
            </Paper>
        </div>
    );
};

export default MEPOverrideList;
