import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Avatar } from '@material-ui/core';
import { Sublevel } from 'store/dashboard/dashboard.types';
import { Link } from 'react-router-dom';
import './DashboardCard.scss';
import utils from 'utils';
import { getAvatar } from '../DashboardHero';
import DashboardConditionChart from '../DashboardConditionChart';
import DashboardDetails from '../DashboardDetails';

const DashboardCard = ({ data }: { data: Sublevel }) => {
    return (
        <Card className=" DashboardCard">
            <Link to={data.link}>
                <CardHeader
                    avatar={<Avatar>{getAvatar(data)} </Avatar>}
                    title={calculateCardTitle({ data })}
                />
            </Link>
            <CardContent>
                <DashboardDetails
                    buildingCount={data.buildingCount}
                    equipmentCount={data.equipmentCount}
                    regionCount={data.regions}
                    districtCount={data.districts}
                    sectorCount={data.sectors}
                    tier={data.tier}
                    tierID={data.tierId}
                    link={data.link}
                />
                <DashboardConditionChart data={data} />
            </CardContent>
        </Card>
    );
};

export default DashboardCard;

const calculateCardTitle = ({ data }: { data: Sublevel }) => {
    const tierName = utils.tierToName(data.tier);
    return `${tierName} - ${data.tierId}`;
};
