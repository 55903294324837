import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@material-ui/core';
import MEPOverrideSelection from 'components/organisms/MEPOverrideSelection';
import hooks from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from 'store/actions';
import { appRedirect } from 'store/app/app.actions';
import { createOrUpdateMEP } from 'store/mep/mep.actions';
import { MEPListItemI } from 'store/mep/mep.types';

import './MepFullDetails.scss';

interface Props {
    details: MEPListItemI;
    editMode: boolean;
    create?: boolean;
}

const MepFullDetails: React.FC<Props> = ({ details: initialDetails, editMode, create }) => {
    const dispatch = useDispatch();
    const classes = hooks.style.useGlobalStyles();
    const [details, setDetails] = useState(initialDetails as MEPListItemI);
    const MEPSystems = hooks.mep.useMepSystems();
    const MEPSubsystemMap = hooks.mep.useMepSubSystemMap();

    const overrideOptions = hooks.mepOverrides.useMepSystemOverrides(details.mep_system);

    const [showNew, setShowNew] = useState({
        system: false,
        type: false,
    });

    const detailsChanges = (field: string, value: any) => {
        setDetails({ ...details, [field]: value });
    };
    const detailsMultipleChanges = (newData: { [field: string]: any }) => {
        setDetails({ ...details, ...newData });
    };

    useEffect(() => {
        setDetails(initialDetails);
        dispatch(actions.mepOverrides.getOverridesForMEPSystem(initialDetails.mep_system));
    }, [initialDetails, dispatch]);

    const submit = () => {
        console.log('details on submit', details);
        dispatch(createOrUpdateMEP(details));
    };

    const cancel = () => {
        setDetails(initialDetails);
        if (create) {
            dispatch(appRedirect(`/admin/mepcosting`));
        } else {
            dispatch(appRedirect(`/admin/mepcosting/${details.mep_id}`));
        }
    };

    return (
        <div className="mepCostingDetails">
            <Paper>
                <TextField
                    id="mep_job_id"
                    required
                    label="Job ID"
                    value={details.mep_job_id}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_job_id', e.target.value)}
                />
                {/* <TextField
                    id="mep_category"
                    required
                    label="Catgegory ID"
                    value={details.mep_category}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_category', e.target.value)}
                />
                */}

                <FormControl className={classes.formControl}>
                    <InputLabel id="mep_type-label">MEP Category</InputLabel>
                    <Select
                        labelId="mep_type-label"
                        id="mep_type"
                        value={details.mep_type}
                        readOnly={!editMode}
                        onChange={e => {
                            const mepCategory = e.target.value as string;
                            if (mepCategory === '+ New') {
                                setShowNew({
                                    type: true,
                                    system: true,
                                });
                                detailsMultipleChanges({
                                    mep_type: '+ New',
                                    mep_system: '+ New',
                                });
                                return;
                            }
                            setShowNew({
                                ...showNew,
                                type: false,
                            });

                            dispatch(actions.options.getMepPicklistSubSystems(mepCategory));

                            detailsMultipleChanges({
                                mep_type: mepCategory,
                                mep_system: '',
                            });
                        }}
                    >
                        {[...MEPSystems, '+ New'].map((category, idx) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={`mepcategory${idx}`} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {showNew.type ? (
                    <TextField
                        id="new_mep_type"
                        label="New Category Name"
                        value={details.mep_type}
                        InputProps={{
                            readOnly: !editMode,
                        }}
                        onChange={e => detailsChanges('mep_type', e.target.value)}
                    />
                ) : null}

                <FormControl className={classes.formControl}>
                    <InputLabel id="mep_system-label">MEP System</InputLabel>
                    <Select
                        labelId="mep_system-label"
                        id="mep_system"
                        readOnly={!editMode || showNew.system}
                        value={details.mep_system}
                        onChange={e => {
                            if (e.target.value === '+ New') {
                                setShowNew({
                                    ...showNew,
                                    system: true,
                                });
                            } else {
                                setShowNew({
                                    ...showNew,
                                    system: false,
                                });
                            }

                            detailsMultipleChanges({
                                mep_system: e.target.value,
                            });
                        }}
                    >
                        {[
                            details.mep_system,
                            ...(details.mep_type &&
                            MEPSubsystemMap[details.mep_type as keyof typeof MEPSubsystemMap]
                                ? MEPSubsystemMap[details.mep_type as keyof typeof MEPSubsystemMap]
                                : []),
                            '+ New',
                        ].map((system, idx) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={`mepsystem${idx}`} value={system}>
                                    {system}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {showNew.system ? (
                    <TextField
                        id="new_mep_system"
                        label="New System Name"
                        value={details.mep_system}
                        InputProps={{
                            readOnly: !editMode,
                        }}
                        onChange={e => detailsChanges('mep_system', e.target.value)}
                    />
                ) : null}

                <TextField
                    id="mep_description"
                    label="Description"
                    multiline
                    fullWidth
                    rows={4}
                    value={details.mep_description}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_description', e.target.value)}
                />
                <TextField
                    id="mep_capacity"
                    label="Capacity"
                    value={details.mep_capacity}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_capacity', e.target.value)}
                />
                <TextField
                    id="mep_capacity_measure"
                    required
                    label="Capacity Measure Units"
                    value={details.mep_capacity_measure}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_capacity_measure', e.target.value)}
                />
                <TextField
                    id="mep_units"
                    required
                    label="Units"
                    value={details.mep_units}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_units', e.target.value)}
                />
                <TextField
                    id="mep_unit_of_measure"
                    required
                    label="Units of Measure"
                    value={details.mep_unit_of_measure}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_unit_of_measure', e.target.value)}
                />
                <TextField
                    id="mep_cost_per_unit"
                    required
                    label="Cost Per Unit"
                    value={details.mep_cost_per_unit}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('mep_cost_per_unit', e.target.value)}
                />
                <TextField
                    id="mep_lifecycle"
                    required
                    label="Lifecycle"
                    value={details.mep_lifecycle}
                    InputProps={{
                        readOnly: !editMode,
                        type: 'number',
                    }}
                    onChange={e => detailsChanges('mep_lifecycle', e.target.value)}
                />

                {editMode ? (
                    <div className="bottomDetailButtons">
                        <Button variant="contained" color="primary" onClick={submit}>
                            Save
                        </Button>

                        <Button variant="contained" color="secondary" onClick={cancel}>
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="bottomDetailButtons">
                        <Link to={`/admin/mepcosting/${details.mep_id}/edit`}>
                            <Button variant="contained" color="primary">
                                Edit
                            </Button>
                        </Link>
                    </div>
                )}
            </Paper>
            {editMode ? null : (
                <MEPOverrideSelection
                    mepID={details.mep_id}
                    overridesPerSystem={overrideOptions || []}
                    selectedOverrides={
                        details.override_details ? JSON.parse(details.override_details) : []
                    }
                />
            )}
        </div>
    );
};

export default MepFullDetails;
