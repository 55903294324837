import React, { useState, useEffect } from 'react';
import { MEPListItemI } from 'store/mep/mep.types';
import { TableRow, TableCell, Paper, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import {
    EnhancedTableFilterI,
    EnhancedTableFilterProps,
} from 'components/organisms/EnhancedTableFilter';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';
import queryString from 'query-string';
import { RouteComponentProps, Link } from 'react-router-dom';
import './MEPList.scss';
import { useLocalStorage } from 'hooks/storage';
import { listMEP } from 'store/mep/mep.actions';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

interface Props {
    location: RouteComponentProps['location'];
}

const MEPList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const mepListArray = hooks.mep.useMEPList();
    const mepLoaded = hooks.mep.useMEPListLoaded();
    const mepLength = hooks.mep.useMEPListLength();
    // const mepSchema = hooks.mep.useMEPSchema();

    const { pagenum } = queryString.parse(location.search) as {
        [key: string]: string | string[];
    };

    const initialSort = 'mep_description';
    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>(initialSort);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filters, setFilters] = useLocalStorage<EnhancedTableFilterI[]>('mepFilters', []);
    const mepSystems = hooks.mep.useMepSystems();

    const [loadingList, setLoadingList] = useState(true);

    useEffect(() => {
        setLoadingList(true);
        dispatch(
            listMEP({
                sort: {
                    field: orderBy,
                    ascdesc: orderDirection,
                },
                limit: rowsPerPage,
                skip: rowsPerPage * page,
                filters,
            })
        );
    }, [orderBy, orderDirection, rowsPerPage, page, filters, dispatch]);

    useEffect(() => {
        console.log('mep list loaded');
        if (mepLoaded) {
            setLoadingList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mepListArray]);

    interface Data extends MEPListItemI {
        view: string;
    }

    const unsortedRowData: Data[] = mepListArray.map(mep => {
        return {
            ...mep,
            view: 'show',
        };
    });

    const rowData = stableSort<Data>(unsortedRowData, getComparator(orderDirection, orderBy));

    // TODO Add more columns to the table
    const headCells: HeadCell<Data>[] = [
        { id: 'mep_id', numeric: false, disablePadding: false, label: 'MEP ID' },
        { id: 'mep_description', numeric: false, disablePadding: false, label: 'Description' },

        { id: 'mep_system', numeric: true, disablePadding: false, label: 'MEP System' },
        { id: 'mep_type', numeric: false, disablePadding: false, label: 'Category' },
        { id: 'mep_capacity', numeric: false, disablePadding: false, label: 'Capacity' },
        {
            id: 'mep_cost_per_unit',
            numeric: false,
            disablePadding: false,
            label: 'Replacement Cost',
        },

        { id: 'view', numeric: false, disablePadding: false, label: 'View' },
    ];

    const rowJSX = (row: Data) => {
        return (
            <TableRow key={row.mep_id}>
                <TableCell align="right">{row.mep_id}</TableCell>
                <TableCell align="right">{row.mep_description}</TableCell>
                <TableCell align="right">{row.mep_system}</TableCell>
                <TableCell align="right">{row.mep_type}</TableCell>

                <TableCell align="right">{`${row.mep_capacity} ${row.mep_capacity_measure}`}</TableCell>
                <TableCell align="right">${Number(row.mep_cost_per_unit).toFixed(2)}</TableCell>

                <TableCell align="center">
                    <Button
                        aria-label="View details"
                        className={globalClasses.tableActionButton}
                        component={Link}
                        to={`/admin/mepcosting/${row.mep_id}`}
                    >
                        <DescriptionIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    const mepSchema: EnhancedTableFilterProps['schema'] = [
        {
            COLUMN_NAME: 'mep_system',
            COLUMN_TYPE: 'option',
            COLUMN_LABEL: 'MEP System',
            IS_NULLABLE: false,
            OPTIONS: mepSystems,
        },
        {
            COLUMN_NAME: 'mep_job_id',
            COLUMN_TYPE: 'int()',
            IS_NULLABLE: false,
            COLUMN_LABEL: 'Job ID',
            EXTRA: '',
        },
        {
            COLUMN_NAME: 'mep_id',
            COLUMN_TYPE: 'int()',
            IS_NULLABLE: false,
            COLUMN_LABEL: 'MEP ID',
            EXTRA: '',
        },
        {
            COLUMN_NAME: 'mep_type',
            COLUMN_TYPE: 'option',
            IS_NULLABLE: false,
            COLUMN_LABEL: 'Category',
            OPTIONS: [], // TODO
        },
    ];

    const filterParams: EnhancedTableFilterProps = {
        schema: mepSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setPage(0);
            setFilters(filters);
        },
    };

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    return (
        <div className="newMEPList">
            <Paper>
                <SortTable<Data>
                    // tableTitle="MEP List"
                    filterParams={filterParams}
                    rows={rowData}
                    headCells={headCells}
                    rowJSX={rowJSX}
                    totalRowCount={mepLength}
                    dense
                    loadingList={loadingList}
                    page={page}
                    setPage={setPage}
                    orderDirection={orderDirection}
                    orderBy={orderBy}
                    onOrderByChange={onOrderByChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={setRowsPerPage}
                    rowsPerPageOptions={[5, 10, 20].sort((a: number, b: number) => a - b)}
                />
            </Paper>
        </div>
    );
};

export default MEPList;
