import { Paper } from '@material-ui/core';
import React from 'react';
import { EnhancedTableFilterProps } from '../EnhancedTableFilter';
import { OrderDirection } from '../SortTable';
import { EnhancedTableToolbar } from '../SortTable/EnhancedTableToolbar';
import './wallOfCardsContainer.scss';

interface WallOfCardsProps<Data = any> {
    filterParams?: EnhancedTableFilterProps;
    filterable?: boolean;
    rows: Data[];
    rowToCardJSX: (row: Data, index: number) => JSX.Element;

    totalRowCount: number;

    loadingList?: boolean;

    orderDirection: OrderDirection;

    orderBy: keyof Data;
    setOrdering: (orderBy: keyof Data, direction: OrderDirection) => void;
}

function WallOfCards<Data>({
    filterParams,
    filterable,
    rows,
    totalRowCount,
    loadingList,
    orderBy,
    setOrdering,
    orderDirection,
    rowToCardJSX,
}: WallOfCardsProps<Data>) {
    console.log(filterParams, totalRowCount, loadingList, orderBy, setOrdering, orderDirection);
    return (
        <div className="wallOfCardsContainer">
            {filterable && (
                <Paper style={{ width: '100%', padding: '0 20px 5px 20px' }}>
                    <EnhancedTableToolbar filterParams={filterParams} />
                </Paper>
            )}

            {rows.map(rowToCardJSX)}
        </div>
    );
}

export default WallOfCards;
