/* eslint-disable no-shadow */
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

interface Props {
    value: string;
    name: string;
    setValue: (value: string) => void;
    label?: string;
    options: { value: string; label: string; disabled?: boolean }[];
}

const RadioButtonsGroup: React.FC<Props> = ({ value, setValue, label, options, name }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl component="fieldset">
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup
                className="radioGroup"
                aria-label={name}
                name={name}
                value={value}
                onChange={handleChange}
            >
                {options.map(({ value, label, disabled }) => (
                    <FormControlLabel
                        key={label}
                        value={value}
                        disabled={disabled}
                        control={<Radio />}
                        label={label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonsGroup;
