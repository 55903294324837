import { AuthAction } from './auth.actions';
import AuthState from './auth.state';
import AuthTypes from './auth.types';

const defaultState: AuthState = {
    successful: false,

    features: {},
};

const authReducer = (state: AuthState = defaultState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthTypes.SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default authReducer;
