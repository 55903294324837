import { Button, Paper } from '@material-ui/core';
import TickboxWrapper, { TickboxOptions } from 'components/molecules/TickboxWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { MEPOverrideListItemI } from 'store/mepOverrides/mepOverrides.types';
import './MEPOverrideSelection.scss';

interface Props {
    overridesPerSystem: MEPOverrideListItemI[];
    selectedOverrides: MEPOverrideListItemI[];
    mepID: string;
}

const MEPOverrideSelection: React.FC<Props> = ({
    mepID,
    overridesPerSystem,
    selectedOverrides,
}) => {
    const [editable, setEditable] = useState(false);
    const [options, setOptions] = useState<Record<string, TickboxOptions>>({});
    const dispatch = useDispatch();

    const initialiseOptions = useCallback(() => {
        const initialOptions: Record<string, TickboxOptions> = {};

        [...overridesPerSystem, ...selectedOverrides].forEach(override => {
            initialOptions[override.omp_id] = {
                label: `${override.omp_description} = ${
                    Number(override.omp_multiplier) * 100
                }% and $${override.omp_fixed_amount}`,
                id: override.omp_id,
                selected: false,
            };
        });
        selectedOverrides.forEach(override => {
            initialOptions[override.omp_id].selected = true;
        });
        setOptions(initialOptions);
    }, [overridesPerSystem, selectedOverrides]);

    useEffect(() => {
        initialiseOptions();
    }, [overridesPerSystem, selectedOverrides, initialiseOptions]);

    const toggleOption = (id: string) => {
        const newOptions = {
            ...options,
            [id]: { ...options[id], selected: !options[id].selected },
        };
        setOptions(newOptions);
    };

    const saveOverrides = () => {
        const selectedIDs: string[] = [];
        Object.values(options).forEach(option => {
            if (option.selected) selectedIDs.push(option.id);
        });
        dispatch(
            actions.mepOverrides.saveMEPOverrides({
                mepID,
                overrideIDArray: selectedIDs,
                existingOverrideArray: selectedOverrides.map(override => override.omp_id),
            })
        );
    };

    const EditSaveButton = () => {
        if (editable) {
            return (
                <div className="overrideButtonContainer">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setEditable(false);
                            saveOverrides();
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setEditable(false);
                            initialiseOptions();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            );
        }
        return (
            <div className="overrideButtonContainer">
                <Button variant="contained" color="primary" onClick={() => setEditable(true)}>
                    Edit
                </Button>
            </div>
        );
    };

    return (
        <Paper className="MEPOverrideSelection">
            <h2>Overrides</h2>

            <TickboxWrapper options={options} onChange={toggleOption} editable={editable} />
            <EditSaveButton />
        </Paper>
    );
};

export default MEPOverrideSelection;
