import { store } from 'App';
import { API, Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig, Method } from 'axios';
import axiosRetry from 'axios-retry';
import urljoin from 'url-join';

export interface CrewRequestConfig {
    headers?: Record<string, any>;
    path: string;
    method?: Method;
    data?: any;
}

axiosRetry(Axios, {
    retries: 3,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
    retryDelay: axiosRetry.exponentialDelay,
});

export const crewAPI = {
    get: async <T = any>({ headers, path }: { headers?: Record<string, any>; path: string }) => {
        return crewAPI.request<T>({ headers, path, method: 'GET' });
    },
    post: async <T = any>({
        headers,
        path,
        data,
    }: {
        headers?: Record<string, any>;
        path: string;
        data: any;
    }) => {
        return crewAPI.request<T>({ headers, path, method: 'POST', data });
    },
    delete: async <T = any>({
        headers,
        path,
        data,
    }: {
        headers?: Record<string, any>;
        path: string;
        data?: any;
    }) => {
        return crewAPI.request<T>({ headers, path, method: 'DELETE', data });
    },

    request: async <T = any>({ headers, path, method = 'GET', data }: CrewRequestConfig) => {
        const user = await Auth.currentSession();
        const baseUrl = await API.endpoint('slsAPI');

        const state = store.getState();
        const { isMaintenanceModeEnabled } = state.adminSettings;

        const requestConfig: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${user.getIdToken().getJwtToken()}`,
                ...(isMaintenanceModeEnabled && { 'x-maintenance-bld-equ': 'true' }),
                ...headers,
            },
            method,
            url: urljoin(baseUrl, encodeURI(path)),
            data,
        };

        const axiosRes = await Axios.request<T>(requestConfig);
        const res = axiosRes.data;
        return res;
    },

    downloadBlob: async ({ headers, path, method = 'GET', data }: CrewRequestConfig) => {
        const user = await Auth.currentSession();
        const baseUrl = await API.endpoint('slsAPI');

        const state = store.getState();
        const { isMaintenanceModeEnabled } = state.adminSettings;

        const requestConfig: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${user.getIdToken().getJwtToken()}`,
                ...(isMaintenanceModeEnabled && { 'x-maintenance-bld-equ': 'true' }),
                ...headers,
            },
            method,
            url: urljoin(baseUrl, encodeURI(path)),
            data,
            responseType: 'blob',
        };

        const axiosRes = await Axios.request<Blob>(requestConfig);
        const res = axiosRes.data;
        return res;
    },
};
