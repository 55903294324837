import React, { FC } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

type Props = {
    isLoading?: boolean;
    errorMessage?: string;
    onClick: () => void;
};

export const ExcelExportButton: FC<Props> = ({ onClick, errorMessage, isLoading = false }) => {
    const globalClasses = useGlobalStyles();

    return (
        <Box display="flex" alignItems="center">
            <Button
                className={globalClasses.button}
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={onClick}
            >
                Download to excel
            </Button>

            {isLoading && <CircularProgress size={24} />}

            {!!errorMessage && (
                <Box marginBottom={1} marginLeft={1}>
                    <Typography variant="body1">{errorMessage}</Typography>
                </Box>
            )}
        </Box>
    );
};
