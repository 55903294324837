import { ThunkDispatch } from 'redux-thunk';
import AdminSettingsActionTypes from './adminSettings.types';
import { RootState } from '../state';
import { crewAPI } from '../../utils/API';

export type AdminSettingsAction =
    | {
          type: typeof AdminSettingsActionTypes.SET_TEMPLATE_EDIT_MODE_ENABLED;
          payload: { isEnabled: boolean };
      }
    | {
          type: typeof AdminSettingsActionTypes.SET_MAINTENANCE_MODE_ENABLED;
          payload: { isEnabled: boolean };
      };

export const setIsMaintenanceModeEnabled = (isEnabled: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AdminSettingsAction>) => {
        if (!isEnabled) {
            try {
                await crewAPI.post({
                    path: 'dashboards/cache/clear',
                    data: {},
                });
            } catch (error) {
                console.error(error);
            }
        }
        dispatch({
            type: AdminSettingsActionTypes.SET_MAINTENANCE_MODE_ENABLED,
            payload: { isEnabled },
        });
    };
};
