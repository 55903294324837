import React from 'react';
import EquipmentFullDetails from 'components/template/EquipmentFullDetails';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';

type Props = RouteComponentProps<{ category: string; system: string }>;

const EquipmentTemplateView: React.FC<Props> = ({ match }) => {
    const { category, system } = match.params;
    const launchInfo = {
        equ_system_category: category,
        equ_system_name: system,
    };

    return (
        <Box>
            <Box ml={1}>
                <h1>Equipment Template</h1>
            </Box>

            <EquipmentFullDetails
                details={launchInfo as unknown as EquipmentListItemI}
                isTemplateView
            />
        </Box>
    );
};

export default withRouter(EquipmentTemplateView);
