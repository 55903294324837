import { Button } from '@material-ui/core';
import MEPList from 'components/template/MEPList';
import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

// import NewEquipmentList from 'components/template/NewEquipmentList';

type Props = RouteComponentProps;

const MEPListView: React.FC<Props> = ({ location }) => {
    return (
        <div className="mepList">
            <h1>MEP List</h1>

            <MEPList location={location} />
            <Link to="/admin/mepcosting/create">
                <Button variant="contained" color="primary">
                    Create New MEP
                </Button>
            </Link>
        </div>
    );
};

export default MEPListView;
