import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Card,
    CardContent,
    CardActions,
    Typography,
    CardHeader,
    CardMedia,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';

import {
    filterRows,
    getComparator,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import {
    EnhancedTableFilterI,
    EnhancedTableFilterProps,
} from 'components/organisms/EnhancedTableFilter';
import hooks from 'hooks';

import { Link } from 'react-router-dom';
import { BuildingI } from 'store/buildings/buildings.types';
import { useLocalStorage } from 'hooks/storage';

// import DescriptionIcon from '@material-ui/icons/Description';
// import SettingsIcon from '@material-ui/icons/Settings';

import { AppFeatures } from 'store/app/app.state';

// import WindPowerIcon from '@mui/icons-material/WindPower';
// import ToysIcon from '@material-ui/icons/Toys';
import WallOfCards from 'components/organisms/WallOfCards';
import { makeStyles } from '@material-ui/styles';
import { BuildingExcelExport } from '../../molecules/BuildingExcelExport';

import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

const useStyles = makeStyles({
    root: {
        margin: 5,
        width: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    title: {
        fontSize: 14,
    },
    pos: {},
});

const BuildingCardList = () => {
    const classes = useStyles();

    const globalClasses = useGlobalStyles();

    const buildingListArray = hooks.buildings.useBuildingList();
    const { loaded: buildingsLoaded } = hooks.buildings.useBuildingState();
    const hasFeature = hooks.user.useHasFeature();
    const buildingSchema = hooks.buildings.useBuildingSchema();

    const initialSort = 'bld_id';

    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>(initialSort);

    const [filters, setFilters] = useLocalStorage<EnhancedTableFilterI[]>('buildingFilters', []);

    const [loadingList, setLoadingList] = useState(true);

    const setOrdering = (orderBy: keyof Data, direction: OrderDirection) => {
        setOrderBy(orderBy);
        setOrderDirection(direction);
    };

    /*
    const canViewBuildings = hasFeature(
        AppFeatures.BUILDINGS.ID,
        AppFeatures.BUILDINGS.subfeatures.view
    );
*/

    const canEditBuildings = hasFeature(
        AppFeatures.BUILDINGS.ID,
        AppFeatures.BUILDINGS.subfeatures.update
    );

    useEffect(() => {
        if (buildingsLoaded) {
            setLoadingList(false);
        }
    }, [buildingListArray, buildingsLoaded]);

    interface Data extends BuildingI {
        view: string;
        viewEquipment: string;
    }

    const filterParams: EnhancedTableFilterProps = {
        schema: buildingSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setFilters(filters);
        },
    };

    const rawRowData: Data[] = buildingListArray.map(building => {
        return {
            ...building,
            view: 'show',
            viewEquipment: 'show',
        };
    });

    const unsortedRowData = filterRows(rawRowData, filterParams);

    const sortedRowData = stableSort<Data>(unsortedRowData, getComparator(orderDirection, orderBy));

    const rowToCard = (row: Data) => {
        console.log();
        return (
            <Card className={classes.root}>
                <CardHeader title={row.bld_name} subheader={row.bld_city} />
                <CardMedia
                    style={{ maxHeight: 400, maxWidth: 400 }}
                    className={classes.media}
                    image={row.bld_image_url || '/buildingPhotoPlaceholder.png'}
                    title="Building Photo"
                />
                <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                        {row.bld_address_line1}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {row.bld_city}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {row.bld_county}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {row.bld_state}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {row.bld_zip}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Sub-Region: {row.sec_name}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Portfolio Directors: {row.portfolio_managers}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Regional Facilities Managers: {row.region_managers}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Sr. Facilities Managers: {row.subregion_managers}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        aria-label="View details"
                        className={globalClasses.tableActionButton}
                        component={Link}
                        to={`/assets/buildings/${row.bld_id}`}
                    >
                        View Details
                    </Button>
                    <a
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${row.bld_address_line1} ${row.bld_city} ${row.bld_zip}`}
                        rel="noreferrer"
                    >
                        <RoomIcon />
                    </a>
                </CardActions>
            </Card>
        );
    };

    return (
        <Box>
            <WallOfCards
                filterable
                filterParams={filterParams}
                rows={sortedRowData}
                totalRowCount={unsortedRowData.length}
                orderBy={orderBy}
                loadingList={loadingList}
                rowToCardJSX={rowToCard}
                orderDirection={orderDirection}
                setOrdering={setOrdering}
            />

            <Box display="flex" alignItems="center">
                {canEditBuildings && (
                    <Button
                        className={globalClasses.button}
                        component={Link}
                        to="/assets/buildings/create"
                        variant="contained"
                        color="primary"
                    >
                        Create new building
                    </Button>
                )}

                {!!sortedRowData?.length && <BuildingExcelExport buildings={sortedRowData} />}
            </Box>
        </Box>
    );
};

export default BuildingCardList;
