import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useMepData = () => {
    const mepData = useSelector<RootState, RootState['options']['mepPicklist']>(
        state => state.options.mepPicklist
    );
    return mepData;
};

export const useMepSystems = (): string[] => {
    const mepSystem = useSelector<RootState, RootState['options']['mepSystems']>(
        state => state.options.mepSystems
    );
    return mepSystem;
};

export const useOverrideList = () => {
    const list = useSelector<RootState, RootState['cityState']['cityStateList']>(
        state => state.cityState.cityStateList
    );
    return list;
};
export const useOverrideListLoaded = () => {
    const loaded = useSelector<RootState, RootState['cityState']['loaded']>(
        state => state.cityState.loaded
    );
    return loaded;
};
export const useOverrideListLength = () => {
    const length = useSelector<RootState, RootState['cityState']['cityStateListCount']>(
        state => state.cityState.cityStateListCount
    );
    return length;
};

/*
export const useMEPSchema = () => {
    const schema = useSelector<RootState, RootState['options'][''] >(
        state => state.mep.
    )
    return schema
}
*/

export const useOverrideDetailsObj = () => {
    const overrideDetails = useSelector<RootState, RootState['cityState']['cityStateDetails']>(
        state => state.cityState.cityStateDetails
    );
    return overrideDetails;
};

export const useMissingOverrideList = () => {
    const list = useSelector<RootState, RootState['cityState']['missingCityStateList']>(
        state => state.cityState.missingCityStateList
    );
    return list;
};

export const useMissingOverrideListLength = () => {
    const length = useSelector<RootState, RootState['cityState']['totalMissingCount']>(
        state => state.cityState.totalMissingCount
    );
    return length;
};
