// eslint-disable-next-line import/no-cycle
import { PermissionFeature } from 'store/auth/auth.state';

export type AppFeaturesI = typeof AppFeatures;

export const AppFeatures = {
    ADMINISTRATION: { ID: 'ADMINISTRATION', subfeatures: {} },
    ALL: { ID: 'ALL', subfeatures: {} },
    BUILDINGS: {
        ID: 'BUILDINGS',
        subfeatures: { list: 'list', view: 'view', update: 'update', create: 'create' },
    },
    CAPEX: {
        ID: 'CAPEX', // Capital Expenditure
        subfeatures: {
            addReplacementBacklog: 'addReplacementBacklog',
            finaliseReplacementBacklog: 'finaliseReplacementBacklog',

            startCapexProject: 'startCapexProject',
            updateCapexProjectDetails: 'updateCapexProjectDetails',
            updateCapexProjectItems: 'updateCapexProjectItems',

            setProjectPriority: 'setProjectPriority',
            approveProject: 'approveProject',
        },
    },
    CITY_STATE_OVERRIDES: {
        ID: 'CITY_STATE_OVERRIDES',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },
    CONDITION_ASSESSMENTS: {
        ID: 'CONDITION_ASSESSMENTS',
        subfeatures: { list: 'list', view: 'view', update: 'update', create: 'create' },
    },
    DASHBOARD: { ID: 'DASHBOARD', subfeatures: { view: 'view', viewNoData: 'viewNoData' } },
    'DASHBOARD-CACHE': { ID: 'DASHBOARD-CACHE', subfeatures: { update: 'update' } },

    EQUIPMENT: {
        ID: 'EQUIPMENT',
        subfeatures: {
            list: 'list',
            view: 'view',
            update: 'update',
            create: 'create',

            viewImage: 'viewImage',
            addImage: 'addImage',

            assignMEP: 'assignMEP',
            viewMEP: 'viewMEP',
            updateMEP: 'updateMEP',
            removeMEP: 'removeMEP',

            manageTags: 'manageTags',
        },
    },
    MEP: {
        ID: 'MEP',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },
    PERMISSIONS: {
        ID: 'PERMISSIONS',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },

    REPORTING: {
        ID: 'REPORTING',
        subfeatures: {
            bulkInportExport: 'bulkInportExport',
        },
    },
    ROLES: {
        ID: 'ROLES',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },
    // TEAM: { ID: 'TEAM', subfeatures: {} },
    USERS: {
        ID: 'USERS',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },

    USER_ROLES: {
        ID: 'USER_ROLES',
        subfeatures: {
            create: 'create',
            list: 'list',
            update: 'update',
            view: 'view',
        },
    },
};

export default interface AppState {
    error: boolean;
    message: string;
    loading: boolean;

    name: string;
    logoURL: string;

    features: PermissionFeature[];

    redirect: string;
}
