import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import { useLocalStorage } from 'hooks/storage';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRedirect } from 'store/app/app.actions';
import tipTranslations from 'store/equipment/tooltipTranslations';

import './DashboardDetails.scss';

interface Props {
    buildingCount: number;
    equipmentCount: number;
    regionCount: number;
    districtCount: number;
    sectorCount: number;

    tier: string;
    tierID: string;

    link?: string;
}

const DashboardDetails: React.FC<Props> = props => {
    const { tier, tierID, buildingCount, equipmentCount, link } = props;
    const dispatch = useDispatch();

    const [, setEquipmentFilters] = useLocalStorage<EnhancedTableFilterI[]>('equipmentFilters', []);
    const [, setBuildingFilters] = useLocalStorage<EnhancedTableFilterI[]>('buildingFilters', []);

    const navigateTo = ({
        context,
        filter,
    }: {
        context: string;
        filter?: { [key: string]: string[] }[];
    }) => {
        switch (context) {
            case 'equipment':
                if (filter) {
                    const equipmentFilter: EnhancedTableFilterI[] = filter.map(
                        rawFilterToEnhancedTableFilter
                    );
                    console.log('equipmentFilter', equipmentFilter);
                    setEquipmentFilters(equipmentFilter);
                }

                dispatch(appRedirect('/assets/equipment'));
                break;
            case 'buildings':
                if (filter) {
                    const buildingFilter: EnhancedTableFilterI[] = filter.map(
                        rawFilterToEnhancedTableFilter
                    );
                    setBuildingFilters(buildingFilter);
                }

                dispatch(appRedirect('/assets/buildings'));
        }
    };

    return (
        <div className="dashboardDetails">
            <div className="top">
                <div
                    className="left"
                    onClick={() =>
                        navigateTo(getStructureFilter({ tier, tierId: tierID }, 'buildings'))
                    }
                >
                    <img src="/building-2.png" alt="buildings" />
                    <h3>{buildingCount}</h3>
                </div>
                <div
                    className="right"
                    onClick={() =>
                        navigateTo(getStructureFilter({ tier, tierId: tierID }, 'equipment'))
                    }
                >
                    <img src="/FanImage.png" alt="equipment" />
                    <h3>{equipmentCount}</h3>
                </div>
            </div>
            <div className="bottom">
                {link ? (
                    <Linkify link={link}>
                        <DetailChildren {...props} />
                    </Linkify>
                ) : (
                    <DetailChildren {...props} />
                )}
            </div>
        </div>
    );
};

export default DashboardDetails;

interface DetailChildrenProps {
    regionCount: number;
    districtCount: number;
    sectorCount: number;
    tier: string;
}

const DetailChildren: React.FC<DetailChildrenProps> = ({
    tier,
    regionCount,
    districtCount,
    sectorCount,
}) => {
    switch (tier) {
        case 'corporate':
            return (
                <div className="box">
                    <span>Portfolios</span>
                    <span>{regionCount}</span>
                </div>
            );
        case 'region':
            return (
                <div className="box">
                    <span>Regions</span>
                    <span>{districtCount}</span>
                </div>
            );
        case 'district':
            return (
                <div className="box">
                    <span>Sub-Regions</span>
                    <span>{sectorCount}</span>
                </div>
            );
        case 'sector':
        default:
            return <div />;
    }
};

const Linkify = ({ children, link }: { children: ReactElement<any, any>; link: string }) => {
    return <Link to={link}>{children}</Link>;
};

export const getStructureFilter = (props: { tier: string; tierId: string }, structure: string) => {
    const tierFilter = getTierFilter(props);
    console.log('tierFilter', tierFilter);
    const results = tierFilter
        ? { context: structure, filter: [tierFilter] }
        : { context: structure };

    return results;
};

export const getTierFilter = (props: {
    tier: string;
    tierId: string;
}): { [key: string]: string[] } | undefined => {
    switch (props.tier.toLowerCase()) {
        case 'region':
            return { reg_id: [`${props.tierId}`] };
        case 'district':
            return { dis_id: [`${props.tierId}`] };
        case 'sector':
            return { sec_id: [`${props.tierId}`] };
        default:
    }
};

const toolTipTranslations = tipTranslations();

export const rawFilterToEnhancedTableFilter = (rawFilter: { [key: string]: string[] }) => {
    let enhancedTableFilter: EnhancedTableFilterI = {} as EnhancedTableFilterI;
    Object.entries(rawFilter).forEach(([key, values]) => {
        enhancedTableFilter = {
            field: {
                COLUMN_NAME: key,
                COLUMN_LABEL: toolTipTranslations[key],
                COLUMN_TYPE: 'option',
                IS_NULLABLE: false,
                OPTIONS: values,
            },
            operation: {
                label: 'Equals One Of',
                value: 'eq',
                inputs: { number: 1, format: 'options' },
            },
            value: values,
        };
    });
    return enhancedTableFilter;
};
