import { Tier } from 'utils';

enum DashboardTypes {
    GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL',
    GET_DASHBOARD_LOADING = 'GET_DASHBOARD_LOADING',
    GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS',
}

export default DashboardTypes;

export interface Conditions {
    excellent: ConditionI;
    good: ConditionI;
    fair: ConditionI;
    poor: ConditionI;
    nodata: ConditionI;
}

export interface ConditionI {
    label: string;
    count: number;
    percent: number;
}

export interface Hero {
    type: string;
    title: string;
    buildingCount: number;
    equipmentCount: number;
    regions: number;
    districts: number;
    sectors: number;
    tier: Tier;
    tierId: string;
    linkParent: string;
    conditions: Conditions;
}

export interface Sublevel {
    type: string;
    title: string;
    buildingCount: number;
    equipmentCount: number;
    regions: number;
    districts: number;
    sectors: number;
    tier: Tier;
    tierId: string;
    link: string;
    conditions: Conditions;
}

export interface DashboardData {
    hero: Hero;
    sublevels: Sublevel[];
}

export enum Condition {
    NODATA = 'nodata',
    POOR = 'poor',
    FAIR = 'fair',
    GOOD = 'good',
    EXCELLENT = 'excellent',
}

export type Levels = 'sectors' | 'districts' | 'regions';
