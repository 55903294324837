import OCSFullDetails from 'components/template/OCSFullDetails';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CityStateListItemI } from 'store/cityStateOverrides/cityStateOverrides.types';

type Props = RouteComponentProps;

const OCSCreate: React.FC<Props> = ({ location }) => {
    const queryStrings = new URLSearchParams(location.search);
    const city = queryStrings.get('city');
    const state = queryStrings.get('state');

    const details = {
        ocs_city: city,
        ocs_state: state,
        ocs_multiplier: '0',
    } as CityStateListItemI;

    return (
        <div className="OCSCreate">
            <h1>City State Override Create</h1>

            <OCSFullDetails editMode details={details} create />
        </div>
    );
};

export default OCSCreate;
