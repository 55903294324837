import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box, CircularProgress } from '@material-ui/core';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableFilterI, EnhancedTableFilterProps } from '../EnhancedTableFilter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            borderCollapse: 'collapse',
            borderStyle: 'hidden',
            borderBottom: 'solid 1px',
            borderColor: theme.palette.divider,
            '& td': {
                border: 'solid 1px',
                borderColor: theme.palette.divider,
            },
            '& th': {
                border: 'solid 1px transparent',
                borderBottomColor: theme.palette.divider,
            },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        bodyCell: {
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.5)',
        },
    })
);

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type OrderDirection = 'asc' | 'desc';

export function getComparator<T = { [key: string]: string }>(
    order: OrderDirection,
    orderBy: keyof T
): (a: T, b: T) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

interface EnhancedTableHeadProps<Data> {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    orderDirection: OrderDirection;
    orderBy: keyof Data;
    headCells: HeadCell<Data>[];
}

export interface HeadCell<Data> {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    disableSort?: boolean;
    className?: string;
}

function EnhancedTableHead<Data>(props: EnhancedTableHeadProps<Data>) {
    const {
        classes,
        orderDirection,
        orderBy,
        onRequestSort,

        headCells,
    } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        className={headCell.className}
                        key={headCell.id as string}
                        align="center" // {headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? orderDirection : false}
                    >
                        {headCell.disableSort ? (
                            headCell.label
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? orderDirection : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {orderDirection === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableProps<Data> {
    tableTitle?: string;
    filterParams?: EnhancedTableFilterProps;

    rows: Data[];
    headCells: HeadCell<Data>[];
    rowJSX: (row: Data, index: number) => JSX.Element;
    totalRowCount: number;
    dense?: boolean;
    loadingList?: boolean;
    error?: boolean;

    page: number;
    setPage: (pageNumber: number) => void;

    orderDirection: OrderDirection;

    orderBy: keyof Data;
    onOrderByChange: (orderBy: keyof Data, direction: OrderDirection) => void;

    rowsPerPage: number;
    onRowsPerPageChange: (rowsPerPage: number) => void;
    rowsPerPageOptions?: number[];
}

export default function EnhancedTable<Data>({
    tableTitle,
    filterParams,

    rows,
    headCells,
    rowJSX,
    totalRowCount,
    dense = false,
    loadingList = false,
    error = false,

    page,
    setPage,

    orderDirection,
    orderBy,
    onOrderByChange,

    rowsPerPage,
    onRowsPerPageChange,
    rowsPerPageOptions = [5, 10, 25],
}: EnhancedTableProps<Data>) {
    const classes = useStyles();

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        onOrderByChange(property, isAsc ? 'desc' : 'asc');
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onRowsPerPageChange(parseInt(event.target.value, 10));
        setPage(0);
    };

    const generateBody = () => {
        if (error) {
            return (
                <TableBody>
                    <TableRow style={{ height: dense ? 33 : 53 }}>
                        <TableCell colSpan={headCells.length}>
                            <span>
                                There has been an error fetching the records. Please refresh the
                                page
                            </span>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }
        if (loadingList) {
            return (
                <TableBody>
                    <TableRow style={{ height: dense ? 33 : 53 }}>
                        <TableCell colSpan={headCells.length}>
                            <Box display="flex" justifyContent="center" p={2}>
                                <CircularProgress />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }

        return <TableBody>{rows.map(rowJSX)}</TableBody>;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {tableTitle || filterParams ? (
                    <EnhancedTableToolbar tableTitle={tableTitle} filterParams={filterParams} />
                ) : null}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            orderDirection={orderDirection}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />

                        {generateBody()}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

const shouldRemove = (row: { [key: string]: any }, filters: EnhancedTableFilterI[]) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
        switch (filter.operation.value) {
            case 'eq':
                // eslint-disable-next-line eqeqeq
                if (row[filter.field.COLUMN_NAME] == filter.value[0]) {
                    break;
                }
                console.log('remove because not eq');
                return true;
            case 'not_eq':
                // eslint-disable-next-line eqeqeq
                if (row[filter.field.COLUMN_NAME] != filter.value[0]) {
                    break;
                }
                console.log('remove because is eq');
                return true;
            case 'exists':
                if (row[filter.field.COLUMN_NAME]) {
                    break;
                }
                console.log('remove because not exists');
                return true;
            case 'not_exists':
                if (!row[filter.field.COLUMN_NAME]) {
                    break;
                }
                console.log('remove because is exists ');
                return true;
            case 'ge':
                if (row[filter.field.COLUMN_NAME] >= filter.value[0]) {
                    break;
                }
                console.log('remove because not greater');
                return true;
            case 'le':
                if (row[filter.field.COLUMN_NAME] <= filter.value[0]) {
                    break;
                }
                console.log('remove because not less than');
                return true;
            case 'between':
                if (
                    row[filter.field.COLUMN_NAME] > filter.value[0] &&
                    row[filter.field.COLUMN_NAME] < filter.value[1]
                ) {
                    break;
                }
                console.log('remove because not between');
                return true;
            case 'not_between':
                if (
                    row[filter.field.COLUMN_NAME] > filter.value[0] &&
                    row[filter.field.COLUMN_NAME] < filter.value[1]
                ) {
                    console.log('remove because is between');
                    return true;
                }
                break;

            default:
                break;
        }
    }

    return false;
};

export const filterRows = <Data extends { [key: string]: any }>(
    rawRowData: Data[],
    filterParams: EnhancedTableFilterProps
) => {
    const filteredData = rawRowData.filter(row => {
        const keep = !shouldRemove(row, filterParams.filters);

        return keep;
    });
    return filteredData;
};
