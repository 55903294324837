import { Chip, TextField, Button, Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

interface Props {
    chips: string[];
    chipOptions: string[];
    inputLabel?: string;
    noItemsLabel?: string;
    isEditable: boolean;
    isEditing: boolean;
    onSave: (chips: string[]) => void;
    onStartEdit: () => void;
    onEndEdit: () => void;
}

const DEFAULT_NO_ITEMS_LABEL = 'No chips';
const DEFAULT_INPUT_LABEL = 'Add chip';
const NEW_CHIP_SUGGESTION_PREFIX = 'Create:';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        button: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        autoComplete: {
            width: 240,
            display: 'inline-block',
            marginBottom: theme.spacing(1),
        },
    })
);

const hasPendingChanges = (originalChips: string[], editedChips: string[]) => {
    if (originalChips.length !== editedChips.length) return true;

    return !originalChips.every((chip, index) => chip === editedChips[index]);
};

const ListOfChips: React.FC<Props> = ({
    chips,
    chipOptions,
    isEditable,
    isEditing,
    onSave,
    onStartEdit,
    onEndEdit,
    noItemsLabel = DEFAULT_NO_ITEMS_LABEL,
    inputLabel = DEFAULT_INPUT_LABEL,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState<string | null>(null);
    const [editedChips, setEditedChips] = useState(chips);
    const classes = useStyles();
    const canSave = hasPendingChanges(chips, editedChips);

    useEffect(() => {
        setEditedChips(chips);
    }, [chips, setEditedChips]);

    const clearInput = () => {
        setInputValue('');
        setSelectValue(null);
    };

    const handleDelete = (idx: number) => {
        const newChips = [...editedChips];
        newChips.splice(idx, 1);
        setEditedChips(newChips);
    };

    const addNewChip = (newChip: string) => {
        setEditedChips([...editedChips, newChip.trim()]);
        clearInput();
    };

    const save = () => {
        onEndEdit();
        onSave(editedChips);
        clearInput();
    };

    const cancel = () => {
        onEndEdit();
        setEditedChips(chips);
        clearInput();
    };

    return (
        <Box>
            <Box mt={1} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                {!isEditing && !editedChips.length && (
                    <Box mr={1.5} mb={1}>
                        <Typography variant="body1">{noItemsLabel}</Typography>
                    </Box>
                )}
                {editedChips.map((title, idx) => (
                    <Chip
                        className={classes.chip}
                        key={title}
                        label={title}
                        onDelete={isEditing ? () => handleDelete(idx) : undefined}
                    />
                ))}
                {isEditable && !isEditing && (
                    <Button
                        className={classes.button}
                        size="small"
                        onClick={() => onStartEdit()}
                        variant="contained"
                        color="primary"
                    >
                        Edit
                    </Button>
                )}
            </Box>

            {isEditing && (
                <>
                    <Autocomplete
                        className={classes.autoComplete}
                        inputValue={inputValue}
                        value={selectValue}
                        onInputChange={(event, value) => {
                            setInputValue(value);
                        }}
                        onChange={(event, value) => {
                            setSelectValue(value);
                            if (value) {
                                addNewChip(
                                    value.startsWith(NEW_CHIP_SUGGESTION_PREFIX)
                                        ? inputValue
                                        : value
                                );
                            }
                        }}
                        selectOnFocus
                        blurOnSelect
                        handleHomeEndKeys
                        filterOptions={(options, params) => {
                            if (!params.inputValue) return options;

                            const filtered = options.filter(option =>
                                option.includes(params.inputValue)
                            );

                            // Suggest the creation of a new chip
                            if (params.inputValue && !filtered.includes(params.inputValue)) {
                                filtered.push(
                                    `${NEW_CHIP_SUGGESTION_PREFIX} "${params.inputValue}"`
                                );
                            }

                            return filtered;
                        }}
                        options={chipOptions.filter(option => !editedChips.includes(option))}
                        renderInput={params => <TextField {...params} label={inputLabel} />}
                        freeSolo
                    />
                    <Box marginTop={2}>
                        <Button
                            disabled={!canSave}
                            className={classes.button}
                            onClick={save}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        <Button
                            className={classes.button}
                            onClick={cancel}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ListOfChips;
