const dev = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:e1df3494-ad10-458c-b9a9-db1c0fcd4f10',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_6gjaxSxyV',
    aws_user_pools_web_client_id: '7dgol385q5c2og4f40npvuc5bl',
    oauth: {},
    signInConfig: {
        isSignUpDisplayed: false,
    },
    API: {
        endpoints: [
            {
                name: 'slsAPI',
                endpoint: 'https://y6z4le3g2d.execute-api.us-east-1.amazonaws.com/dev',
                region: 'us-east-1',
            },
        ],
    },
    Storage: {
        AWSS3: {
            bucket: 'crew-s3-image-upload-bucket',
            region: 'us-east-1',
        },
    },
};

const prod = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:11766c21-7a09-4ca5-b306-a1a1eeb9ae12',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_7vgPoZaO1',
    aws_user_pools_web_client_id: '4d6ihh68im5j80mq1bs5vahi8v',
    signInConfig: {
        isSignUpDisplayed: false,
    },
    API: {
        endpoints: [
            {
                name: 'slsAPI',
                endpoint: 'https://tk7bgqf6zi.execute-api.us-east-1.amazonaws.com/prod',
                region: 'us-east-1',
            },
        ],
    },
    aws_user_files_s3_bucket: 'prod-crew-s3-image-upload-bucket191649-prod',
    aws_user_files_s3_bucket_region: 'us-east-1',
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
