import React from 'react';
import { Box, Button, CircularProgress, Popover, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import hooks from 'hooks';
import { AppFeatures } from '../../../store/app/app.state';

interface Props {
    onSave: () => void;
    onCancel: () => void;
    onEdit: () => void;
    onDuplicate: () => void;
    isEditValid: boolean;
    editMode: boolean;
    buildingId: string;
}

const EquipmentEditButtons: React.FC<Props> = ({
    onSave,
    onCancel,
    onEdit,
    onDuplicate,
    isEditValid,
    editMode,
    buildingId,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
    const saving = hooks.equipment.useEquipmentLoading();
    const hasFeature = hooks.user.useHasFeature();
    const globalClasses = hooks.style.useGlobalStyles();

    if (!hasFeature(AppFeatures.EQUIPMENT.ID, AppFeatures.EQUIPMENT.subfeatures.update)) {
        console.log("can't update or edit");
        return null;
    }

    if (editMode) {
        const isSaveDisabled = saving || !isEditValid;

        return (
            <Box mt={1}>
                {!isEditValid && (
                    <Box mb={1}>
                        <Typography variant="body1" color="textSecondary">
                            Can&apos;t save as missing required fields. See fields marked with *
                        </Typography>
                    </Box>
                )}

                <Button
                    className={globalClasses.button}
                    variant="contained"
                    color="primary"
                    disabled={isSaveDisabled}
                    onClick={onSave}
                >
                    Save
                </Button>

                <Button
                    className={globalClasses.button}
                    variant="contained"
                    color="secondary"
                    disabled={saving}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                {saving && <CircularProgress size={24} />}
            </Box>
        );
    }

    const confirmDuplicate = Boolean(anchorEl);
    const popupID = confirmDuplicate ? 'confirmDuplicate' : undefined;

    const canAddDuplicate = hasFeature(
        AppFeatures.EQUIPMENT.ID,
        AppFeatures.EQUIPMENT.subfeatures.create
    );

    return (
        <Box mt={1}>
            <Button
                className={globalClasses.button}
                onClick={onEdit}
                variant="contained"
                color="primary"
            >
                Edit
            </Button>
            {canAddDuplicate && (
                <>
                    <Button
                        component={Link}
                        to={`/assets/equipment/create?buildingID=${buildingId}`}
                        className={globalClasses.button}
                        variant="contained"
                        color="primary"
                    >
                        Create New Equipment
                    </Button>
                    <Button
                        className={globalClasses.button}
                        onClick={event => setAnchorEl(event.currentTarget)}
                        variant="contained"
                        color="primary"
                    >
                        Duplicate This Equipment
                    </Button>
                    <Popover
                        id={popupID}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        open={confirmDuplicate}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        {' '}
                        <Box width={270} p={2}>
                            <Box mb={2}>
                                <Typography variant="body2">
                                    Do you want to duplicate this item? All tags and MEP details
                                    will also be duplicated.
                                </Typography>
                            </Box>

                            <Button
                                className={globalClasses.button}
                                onClick={onDuplicate}
                                variant="contained"
                                color="primary"
                            >
                                Confirm
                            </Button>
                            <Button
                                className={globalClasses.button}
                                onClick={() => setAnchorEl(null)}
                                variant="contained"
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Popover>
                </>
            )}
        </Box>
    );
};

export default EquipmentEditButtons;
