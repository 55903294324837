import { Auth } from 'aws-amplify';
import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';
import { appRedirect } from 'store/app/app.actions';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { needsUpdate } from 'utils';
import { TableFilterSchema } from 'components/organisms/EnhancedTableFilter/schemas';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import MEPTypes, { MEPI, MEPListItemI } from './mep.types';
import { MEPDetails } from './mep.state';

interface MEPError {
    message: string;
}

interface ListEquipSuccessPayload {
    records: MEPListItemI[];
    totalRecordCount?: number;
    totalReplacementCost?: string;
    filters?: TableFilterSchema[];
}

export type MEPAction =
    | { type: typeof MEPTypes.GET_MEP_FAIL; payload: MEPError }
    | { type: typeof MEPTypes.GET_MEP_LOADING }
    | { type: typeof MEPTypes.GET_MEP_SUCCESS; payload: { meps: MEPI[] } }
    | {
          type: typeof MEPTypes.GET_MEP_DETAILS_FAIL;
          payload: {
              [key: string]: {
                  mep: MEPListItemI;
                  errorMessage: string;
              };
          };
      }
    | {
          type: typeof MEPTypes.GET_MEP_DETAILS_SUCCESS;
          payload: { [key: string]: MEPDetails };
      }
    | {
          type: typeof MEPTypes.LIST_MEP_FAIL;
          payload: {
              errorMessage: string;
          };
      }
    | {
          type: typeof MEPTypes.LIST_MEP_SUCCESS;
          payload: ListEquipSuccessPayload;
      }
    | { type: typeof MEPTypes.CREATE_MEP_LOADING }
    | { type: typeof MEPTypes.CREATE_MEP_SUCCESS }
    | { type: typeof MEPTypes.CREATE_MEP_FAIL; payload: { errorMessage: string } };

interface GetMEPI {
    records: MEPListItemI[];
    totalRecords: {
        totalRecordCount: number;
    };
    filters?: { [key: string]: (string | null)[] };
}

export const getMEPDetails = (mepID: string, force?: boolean) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MEPAction>,
        getState: () => RootState
    ) => {
        const rootState = getState();
        if (rootState.mep.mepDetails[mepID] && !force) {
            return;
        }
        try {
            const getRequestConfig: CrewRequestConfig = {
                headers: {
                    filter: JSON.stringify({
                        mep_id: mepID,
                    }),
                    limit: 1,
                },
                path: `mepcosting`,
            };

            const data = await crewAPI.get<GetMEPI>(getRequestConfig);

            dispatch({
                type: MEPTypes.GET_MEP_DETAILS_SUCCESS,
                payload: {
                    [mepID]: { mep: data.records[0] },
                },
            });
        } catch (error) {
            console.log('error api', error);
            dispatch({
                type: MEPTypes.GET_MEP_DETAILS_FAIL,
                payload: {
                    [mepID]: {
                        mep: {} as MEPListItemI,
                        errorMessage: `Error getting the details for a mep`,
                    },
                },
            });
        }
    };
};

interface ListMEPProps {
    sort: {
        field: string;
        ascdesc: 'asc' | 'desc';
    };
    filters: EnhancedTableFilterI[];
    limit: number;
    skip: number;
}
export const listMEP = ({ sort, filters, limit, skip }: ListMEPProps) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, MEPAction>) => {
        dispatch({
            type: MEPTypes.GET_MEP_LOADING,
        });
        const filter = enhancedTableFiltersToApiFilters(filters);

        console.log('HEADERS', { sort, filter, limit, skip });

        const getRequestConfig: CrewRequestConfig = {
            headers: {
                sort: JSON.stringify(sort),
                filter,
                limit,
                skip,
            },
            method: 'GET',
            path: `mepcosting`,
        };

        const data = await crewAPI.get<GetMEPI>(getRequestConfig);

        console.log('data in list equip', data);

        const payload: ListEquipSuccessPayload = {
            records: data?.records,
            totalRecordCount: data?.totalRecords?.totalRecordCount,
        };
        if (data.filters) {
            const options = Object.entries(data.filters).map(([key, values]) => {
                return {
                    COLUMN_NAME: key,
                    COLUMN_LABEL: key.slice(4), //  toolTipTranslations[key], // replace when we get
                    COLUMN_TYPE: 'option',
                    IS_NULLABLE: values.includes(null),
                    OPTIONS: values.filter(value => typeof value === 'string') as string[],
                };
            });

            payload.filters = options;
        }

        dispatch({
            type: MEPTypes.LIST_MEP_SUCCESS,
            payload,
        });
    };
};

const enhancedTableFiltersToApiFilters = (filters: EnhancedTableFilterI[]) => {
    // TODO add logic for the comparitor opperations.
    const apiFilters: { [key: string]: (string | number)[] } = {};
    filters.map(({ field, operation, value }) => {
        switch (operation.value) {
            case 'eq':
                apiFilters[field.COLUMN_NAME] = value;
        }
        return null;
    });

    return JSON.stringify(apiFilters);
};

export const createOrUpdateMEP = (mepData: MEPListItemI) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MEPAction>,
        getState: () => RootState
    ) => {
        dispatch({
            type: MEPTypes.CREATE_MEP_LOADING,
        });

        const state = getState();
        // const isUpdate = mepData.mep_id && !!state.mep.mepDetails[mepData.mep_id]?.mep;

        try {
            const user = await Auth.currentSession();
            await updateMEP({ mepData, state, user, dispatch });

            await dispatch(getMEPDetails(mepData.mep_id, true));

            dispatch(appRedirect(`/admin/mepcosting/${mepData.mep_id}`));
        } catch (error) {
            console.log('error in the mep create or update promise all', error);
        }
    };
};

const updateMEP = async ({
    mepData,
    state,
    dispatch,
}: {
    mepData: MEPListItemI;
    state: RootState;
    user: CognitoUserSession;
    dispatch: ThunkDispatch<RootState, undefined, MEPAction>;
}) => {
    const isUpdate = mepData.mep_id && !!state.mep.mepDetails[mepData.mep_id]?.mep;

    const cleanData: { [key: string]: string | number } = {};
    Object.entries(mepData).forEach(([key, value]) => {
        if (key.startsWith('mep')) {
            cleanData[key] = value;
        }
    });

    delete cleanData.mep_updated_date;
    delete cleanData.mep_updated_by;
    delete cleanData.mep_created_date;
    delete cleanData.mep_created_by;
    delete cleanData.mep_mep_id;

    const needsUpdates = needsUpdate(cleanData, state.mep.mepDetails[mepData.mep_id]?.mep);
    if (!needsUpdates) {
        console.log('no update needed for mep');
        return;
    }

    const options: CrewRequestConfig = {
        data: cleanData,

        method: isUpdate ? 'PUT' : 'POST',
        path: `mepcosting`,
    };

    const data = await crewAPI.request(options);

    dispatch({
        type: MEPTypes.CREATE_MEP_SUCCESS,
    });
    if (!isUpdate) {
        dispatch(appRedirect(`/admin/mepcosting/${data.new_id}`));
    }
};
