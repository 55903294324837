import React, { FC, useState } from 'react';
import * as xlsx from 'xlsx';
import { formatISO } from 'date-fns';
import { BuildingI } from '../../../store/buildings/buildings.types';
import { ExcelExportButton } from '../../organisms/ExcelExportButton';

type Props = {
    buildings: BuildingI[];
};

const toExcelRowData = (building: BuildingI) => ({
    Name: building.bld_name,
    City: building.bld_city,
    State: building.bld_state,
    'Facility Mgt': building.sec_name,
});

export const BuildingExcelExport: FC<Props> = ({ buildings }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const onExport = () => {
        try {
            setErrorMessage('');
            const exportData = buildings.map(toExcelRowData);
            const sheet = xlsx.utils.json_to_sheet(exportData);
            sheet['!cols'] = [{ wch: 80 }, { wch: 18 }, { wch: 10 }, { wch: 10 }];

            xlsx.writeFile(
                { Sheets: { Sheet1: sheet }, SheetNames: ['Sheet1'] },
                `crew_app_building_export_${formatISO(new Date())}.xlsx`,
                { cellStyles: true }
            );
        } catch (error: any) {
            setErrorMessage(error.message);
        }
    };

    return <ExcelExportButton onClick={onExport} errorMessage={errorMessage} />;
};
