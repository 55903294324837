import { ThunkDispatch } from 'redux-thunk';
import EquipmentTemplateActionTypes, {
    EquipmentTemplate,
    EquipmentTemplateListItemI,
    fromApiResponse,
    toApiRequestPayload,
} from './equipmentTemplate.types';
import { crewAPI } from '../../utils/API';
import { RootState } from '../state';

export type EquipmentTemplateAction =
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_LOADING;
      }
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_SUCCESS;
          payload: { template: EquipmentTemplate };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_FAIL;
          payload: { error: Error };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.SAVE_TEMPLATE_LOADING;
      }
    | {
          type: typeof EquipmentTemplateActionTypes.SAVE_TEMPLATE_SUCCESS;
          payload: { template: EquipmentTemplate };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.SAVE_TEMPLATE_FAIL;
          payload: { error: Error };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_LOADING;
      }
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_SUCCESS;
          payload: { templates: EquipmentTemplateListItemI[] };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_FAIL;
          payload: { error: Error };
      }
    | {
          type: typeof EquipmentTemplateActionTypes.RESET_TEMPLATE_LIST;
      };

export const getCurrentTemplate = (systemCategory: string, systemName: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentTemplateAction>) => {
        try {
            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_LOADING,
            });

            const result = await crewAPI.get<string[]>({
                path: `equipment/template/${systemCategory}/${systemName}`,
            });

            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_SUCCESS,
                payload: { template: fromApiResponse(result) },
            });
        } catch (error) {
            if (!(error instanceof Error)) {
                console.error(error);
                return;
            }

            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_FAIL,
                payload: { error },
            });
        }
    };
};

export const updateTemplate = (
    systemCategory: string,
    systemName: string,
    template: EquipmentTemplate
) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentTemplateAction>) => {
        try {
            dispatch({
                type: EquipmentTemplateActionTypes.SAVE_TEMPLATE_LOADING,
            });

            await crewAPI.post({
                data: toApiRequestPayload(template),
                path: `equipment/template/${systemCategory}/${systemName}`,
            });

            dispatch({
                type: EquipmentTemplateActionTypes.SAVE_TEMPLATE_SUCCESS,
                payload: { template },
            });
        } catch (error) {
            if (!(error instanceof Error)) {
                console.error(error);
                return;
            }

            dispatch({
                type: EquipmentTemplateActionTypes.SAVE_TEMPLATE_FAIL,
                payload: { error },
            });
        }
    };
};

export const getTemplateList = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, EquipmentTemplateAction>) => {
        try {
            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_LOADING,
            });

            const result = await crewAPI.get<EquipmentTemplateListItemI[]>({
                path: 'equipment/templates',
            });

            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_SUCCESS,
                payload: { templates: result },
            });
        } catch (error) {
            if (!(error instanceof Error)) {
                console.error(error);
                return;
            }

            dispatch({
                type: EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_FAIL,
                payload: { error },
            });
        }
    };
};
