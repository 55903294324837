import { Box, createStyles, makeStyles, Paper, Button, Theme, Typography } from '@material-ui/core';

import hooks from 'hooks';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from 'store/actions';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingsItemHeader: {
            marginTop: 0,
            marginBottom: theme.spacing(1),
        },
        settingsItemBody: {
            marginBottom: theme.spacing(0.5),
        },
    })
);

const OfflineUploadView: React.FC = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { loading, success, errors, buildingId } = hooks.buildings.useOfflineUploadState();

    const [fileToUpload, setFile] = React.useState<File>();
    const inputRef = useRef(null);

    const submit = () => {
        if (fileToUpload) {
            dispatch(actions.buildings.uploadOfflineBuilding({ offlineCsv: fileToUpload }));
            console.log(loading, success, errors);
        }
        setFile(undefined);
    };

    const clearUpload = () => {
        dispatch(actions.buildings.clearUpload());
    };

    const onFileDrop = event => {
        console.log('file dropped');
        event.preventDefault();
        if (event.dataTransfer.items) {
            const item = event.dataTransfer.items[0];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                console.log(file.name);
                setFile(file);
            }
        }
    };
    const dragOverHandler = ev => {
        // Prevent default behavior (Prevent file from being opened)
        console.log('file dragged over');
        ev.preventDefault();
    };

    const chooseFile = () => {
        if (fileToUpload) {
            setFile(undefined);
            return;
        }
        // @ts-expect-error input ref complains it might be null
        inputRef?.current?.click();
    };

    const UploadView = () => (
        <>
            <h2 className={classes.settingsItemHeader}>Upload your updated Excel file here</h2>
            <Typography className={classes.settingsItemBody} variant="body1">
                We will check that the file is valid and then upload it into the system. If there
                are any issues with the data then we will let you know.
            </Typography>
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={ev => setFile(ev.target.files?.[0])}
            />
            <Box
                display="flex"
                alignItems="center"
                style={{
                    background: '#eee',
                    height: '200px',
                    width: '600px',
                    border: '2px solid #888',
                    borderRadius: '20px',
                    margin: '10px',
                }}
                onDrop={onFileDrop}
                onDragOver={dragOverHandler}
                onClick={chooseFile}
            >
                <Typography variant="body1" style={{ margin: 'auto' }}>
                    {fileToUpload ? `${fileToUpload.name}` : 'Click or Drop File Here'}
                </Typography>
            </Box>

            <Box display="flex" alignItems="center">
                <input
                    type="file"
                    onChange={e => setFile(e.target.files?.[0])}
                    style={{ display: 'none' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!fileToUpload}
                    onClick={submit}
                >
                    Upload
                </Button>
            </Box>
        </>
    );
    const SuccessView = () => (
        <>
            <h2 className={classes.settingsItemHeader}>Success</h2>
            <Typography className={classes.settingsItemBody} variant="body1">
                Your file has been uploaded successfully and syced with the rest of the system. You
                can view the updated building or upload another file
            </Typography>
            <Box display="flex" alignItems="center">
                <Link to={`/assets/buildings/${buildingId}`}>
                    <Button variant="contained" color="primary">
                        View the Building
                    </Button>
                </Link>
                <Button variant="contained" color="primary" onClick={clearUpload}>
                    Upload Another File
                </Button>
            </Box>
        </>
    );
    const ErrorView = () => (
        <>
            <h2 className={classes.settingsItemHeader}>Error</h2>
            <Typography className={classes.settingsItemBody} variant="body1">
                There were some issues uploading your data.
            </Typography>
            {errors.fileErrors.length && (
                <>
                    <Typography className={classes.settingsItemBody} variant="body1">
                        File Errors:
                    </Typography>
                    {errors.fileErrors.map((error, idx) => (
                        <Typography
                            key={`error-${idx}`}
                            className={classes.settingsItemBody}
                            variant="body1"
                        >
                            {error.error}
                        </Typography>
                    ))}
                </>
            )}
            {!!errors.buildingErrors.length && (
                <>
                    <Typography className={classes.settingsItemBody} variant="body1">
                        Building Errors:
                    </Typography>
                    {errors.buildingErrors.map((error, idx) => (
                        <Typography
                            key={`error-${idx}`}
                            className={classes.settingsItemBody}
                            variant="body1"
                        >
                            {error.error}
                        </Typography>
                    ))}
                </>
            )}
            {!!errors.equipmentErrors.length && (
                <>
                    <Typography className={classes.settingsItemBody} variant="body1">
                        Equipment Errors:
                    </Typography>
                    {errors.equipmentErrors.map((error, idx) => (
                        <Typography
                            key={`error-${idx}`}
                            className={classes.settingsItemBody}
                            variant="body1"
                        >
                            {error.error}
                        </Typography>
                    ))}
                </>
            )}
            <Box display="flex" alignItems="center">
                <Button variant="contained" color="primary" onClick={clearUpload}>
                    Upload Another File
                </Button>
            </Box>
        </>
    );

    return (
        <Box mx={1}>
            <h1>Offline Mode Upload</h1>
            <Paper className={globalClasses.paper}>
                {success && <SuccessView />}
                {(errors.buildingErrors.length > 0 ||
                    errors.equipmentErrors.length > 0 ||
                    errors.fileErrors.length > 0) && <ErrorView />}
                {loading && <h2>Uploading...</h2>}
                {!success &&
                    !(
                        errors.buildingErrors.length > 0 ||
                        errors.equipmentErrors.length > 0 ||
                        errors.fileErrors.length > 0
                    ) &&
                    !loading && <UploadView />}
            </Paper>
        </Box>
    );
};

export default OfflineUploadView;
