import { useSelector } from 'react-redux';
import { RootState } from 'store/state';

export const useDashboardLoading = () => {
    const loading = useSelector<RootState, RootState['dashboard']['loading']>(
        state => state.dashboard.loading
    );
    return loading;
};
export const useDashboardData = () => {
    const dashboardData = useSelector<RootState, RootState['dashboard']['dashboard']>(
        state => state.dashboard.dashboard
    );
    return dashboardData;
};

export const useTiers = () => {
    const { lastTier, lastTierID } = useSelector<RootState, RootState['dashboard']>(
        state => state.dashboard
    );
    return { lastTier, lastTierID };
};
