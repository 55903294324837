import React, { useEffect, ReactChild } from 'react';
import EquipmentFullDetails from 'components/template/EquipmentFullDetails';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import { getEquipmentDetails } from 'store/equipment/equipment.actions';
import SimpleAccordion from 'components/atoms/SimpleAccordion';
import utils from 'utils';
import EquipmentDetailsTabs from './EquipmentDetailsTabs';

type Props = RouteComponentProps<{ equipmentID: string; edit?: string }>;

const EquipmentDetails: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();
    const { isTemplateEditEnabled } = hooks.adminSettings.useAdminSettingsState();
    const globalClasses = hooks.style.useGlobalStyles();

    const { equipmentID, edit } = match.params;
    const editMode = edit === 'edit';

    const equipmentDetailsObject = hooks.equipment.useEquipmentDetails(equipmentID);

    useEffect(() => {
        dispatch(getEquipmentDetails(equipmentID, true));
    }, [dispatch, equipmentID]);

    const equipmentDetails = equipmentDetailsObject?.equipment || {};

    const sections: {
        children: ReactChild;
        title: string;
        expanded?: boolean;
        ID?: string;
    }[] = equipmentDetails.bld_id
        ? [
              {
                  title: 'Building Details',
                  expanded: true,
                  children: (
                      <Box>
                          <TextField
                              className={globalClasses.textField}
                              id="bld_name"
                              label="Building Name"
                              defaultValue={equipmentDetails.bld_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="bld_id"
                              label="Building ID"
                              defaultValue={equipmentDetails.bld_id}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />

                          <TextField
                              className={globalClasses.textField}
                              id="bld_address_line1"
                              label="Address Line 1"
                              defaultValue={equipmentDetails.bld_address_line1}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="bld_city"
                              label="City"
                              defaultValue={equipmentDetails.bld_city}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="bld_state"
                              label="State"
                              defaultValue={equipmentDetails.bld_state}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />

                          <TextField
                              className={globalClasses.textField}
                              id="reg_name"
                              label={utils.tierToName('region')}
                              defaultValue={equipmentDetails.reg_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="dis_name"
                              label={utils.tierToName('district')}
                              defaultValue={equipmentDetails.dis_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="sec_name"
                              label={utils.tierToName('sector')}
                              defaultValue={equipmentDetails.sec_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              className={globalClasses.textField}
                              id="bld_leased_owned"
                              label="Ownership"
                              defaultValue={equipmentDetails.bld_leased_owned}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                      </Box>
                  ),
              },
          ]
        : [];

    return (
        <div className="equipmentDetails">
            <Box ml={1}>
                <h1>{editMode ? 'Edit ' : ''}Equipment Details</h1>
            </Box>
            <div className="equipmentDetailsColumn">
                {equipmentDetails && equipmentDetails.equ_id ? (
                    <EquipmentFullDetails details={equipmentDetails} />
                ) : (
                    <Box display="flex" justifyContent="center" my={2}>
                        <CircularProgress />
                    </Box>
                )}

                {!isTemplateEditEnabled && (
                    <>
                        <Box mx={1} mb={1}>
                            <SimpleAccordion sections={sections} />
                        </Box>

                        <Box mx={1} mb={1}>
                            <EquipmentDetailsTabs
                                equipmentID={equipmentID}
                                equipmentDetailsObject={equipmentDetailsObject}
                            />
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default EquipmentDetails;
