import { AppFeatures } from 'store/app/app.state';

export enum RolesActionTypes {
    GET_ROLES_FAIL = 'GET_ROLES_FAIL',
    GET_ROLES_LOADING = 'GET_ROLES_LOADING',
    GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS',

    GET_ROLE_PERMISSIONS_FAIL = 'GET_ROLE_PERMISSIONS_FAIL',
    GET_ROLE_PERMISSIONS_LOADING = 'GET_ROLE_PERMISSIONS_LOADING',
    GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS',
}

export type RolesActions =
    | { type: typeof RolesActionTypes.GET_ROLES_FAIL; payload: { errorMessage: string } }
    | { type: typeof RolesActionTypes.GET_ROLES_LOADING }
    | { type: typeof RolesActionTypes.GET_ROLES_SUCCESS; payload: { data: any } }
    //
    | { type: typeof RolesActionTypes.GET_ROLE_PERMISSIONS_FAIL; payload: { errorMessage: string } }
    | { type: typeof RolesActionTypes.GET_ROLE_PERMISSIONS_LOADING }
    | { type: typeof RolesActionTypes.GET_ROLE_PERMISSIONS_SUCCESS; payload: { data: any } };

export interface RolesState {
    error: boolean;
    message: string;
    loading: boolean;
    loaded: boolean;

    roles: Role[];
    rolePermissions?: RolePermissions;
}

export type RolePermissions = Partial<Record<keyof typeof AppFeatures, RolePermission>>;
export type RolePermission = { ID: string; subfeatures: Subfeatures };
export type Subfeatures = Record<string, string>;

export type MappedRolePermissions = Record<keyof typeof AppFeatures, MappedRolePermission>;
export type MappedRolePermission = {
    ID: string;
    subfeatures: MappedSubfeatures;
    canHaveSubfeatures: boolean;
};
export type MappedSubfeatures = { name: string; isChecked: boolean }[];

export interface Role {
    rol_id: string;
    rol_description: string;
    rol_long_description: string;
    rol_created_by?: string;
    rol_created_date?: Date;
    rol_updated_by?: string;
    rol_updated_date?: Date;
}
