import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    CircularProgress,
} from '@material-ui/core';
import React, { useState } from 'react';
import { MEPItem } from 'store/options/options.types';
import hooks from 'hooks';

import './MEPCostingCreate.scss';
import { Autocomplete } from '@material-ui/lab';
import { AppFeatures } from 'store/app/app.state';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

interface Props {
    details: any;
    onEndEdit: () => void;
}

const MEPCostingCreate: React.FC<Props> = ({ details: initialDetails, onEndEdit }) => {
    const mepData: { [key: string]: MEPItem } = hooks.mep.useMepData();
    const mepSystems: string[] = hooks.mep.useMepSystems();
    const classes = hooks.style.useGlobalStyles();
    const hasFeature = hooks.user.useHasFeature();
    const [tempID, setTempID] = useState('');
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [tempDetails, setTempDetails] = useState(initialDetails);

    const detailsMultipleChanges = (details: { [key: string]: any }) => {
        setTempDetails({ ...tempDetails, ...details });
    };

    const save = () => {
        dispatch(
            actions.equipment.saveMEPCosting({
                equipmentID: initialDetails.equ_id as string,
                mepID: tempDetails.mep_id,
            })
        );
        setLoading(true);
    };

    const mepIDList = Object.keys(mepData);

    return (
        <div className="mepCostingCreate">
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mep_system-label">MEP System</InputLabel>
                            <Select
                                labelId="mep_system-label"
                                id="mep_system"
                                value={tempDetails.mep_system || ''}
                                onChange={e => {
                                    const mepSystem = e.target.value as string;
                                    detailsMultipleChanges({
                                        mep_id: '',
                                        mep_system: mepSystem,
                                        mep_description: '',
                                        equ_mep_id: '',
                                    });
                                }}
                            >
                                {mepSystems.map((system, idx) => {
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <MenuItem key={`mepsystem${idx}`} value={system}>
                                            {system}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mep_id-label">MEP Item</InputLabel>
                            <Select
                                labelId="mep_id-label"
                                id="mep_id"
                                value={tempDetails.mep_id || ''}
                                renderValue={value => `${value}`}
                                onChange={e => {
                                    const mepID = e.target.value as string;
                                    const { mep_system, mep_description } = mepData[mepID];

                                    detailsMultipleChanges({
                                        mep_id: mepID,
                                        mep_system,
                                        mep_description,
                                        equ_mep_id: mepID,
                                    });
                                }}
                            >
                                {Object.entries(mepData)
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    .filter(([mepID, mepUnit]) => {
                                        if (
                                            tempDetails.mep_system &&
                                            mepUnit.mep_system !== tempDetails.mep_system
                                        ) {
                                            return null;
                                        }
                                        return true;
                                    })
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    .sort(([mepID, mepUnit], [mepIDB, mepUnitB]) => {
                                        if (!mepUnit.mep_capacity || !mepUnitB.mep_capacity)
                                            return 1;
                                        return (
                                            Number(mepUnit.mep_capacity) -
                                            Number(mepUnitB.mep_capacity)
                                        );
                                    })
                                    .map(([mepID, mepUnit]) => {
                                        return (
                                            <MenuItem key={`mepOption${mepID}`} value={mepID}>
                                                <div className="mepOption">
                                                    {`Capacity: ${mepUnit.mep_capacity} ${mepUnit.mep_capacity_measure}
Cost: $${mepUnit.mep_cost_per_unit} ${mepUnit.mep_unit_of_measure}
Type: ${mepUnit.mep_type}
${mepUnit.mep_description}
`}
                                                </div>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>

                        <TextField
                            id="mep_description"
                            label="Details"
                            multiline
                            disabled
                            rowsMax={4}
                            value={tempDetails.mep_description}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant={tempDetails.mep_description ? 'filled' : 'standard'}
                        />
                    </>
                    <div>
                        <h4>If you know the MEP ID you can type it directly here &#8595;</h4>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                value={tempID}
                                onChange={(e, newValue) => {
                                    console.log({ e, newValue });
                                    const mepID = newValue as string;
                                    setTempID(mepID);

                                    if (!mepIDList.includes(mepID)) {
                                        return;
                                    }

                                    const { mep_system, mep_description } = mepData[mepID];
                                    console.log(mepData[mepID]);

                                    detailsMultipleChanges({
                                        mep_id: mepID,
                                        mep_system,
                                        mep_description,
                                        equ_mep_id: mepID,
                                    });
                                }}
                                id="mep_id_autocomplete"
                                options={mepIDList}
                                style={{ width: 300 }}
                                renderInput={params => (
                                    <TextField {...params} label="MEP ID" variant="standard" />
                                )}
                            />
                        </FormControl>
                    </div>
                    {hasFeature(
                        AppFeatures.EQUIPMENT.ID,
                        AppFeatures.EQUIPMENT.subfeatures.removeMEP
                    ) ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                detailsMultipleChanges({
                                    mep_id: '',
                                    mep_system: '',
                                    mep_description: '',
                                    equ_mep_id: '',
                                });
                                setTempID('');
                            }}
                        >
                            Remove MEP
                        </Button>
                    ) : null}

                    <Button onClick={save} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button onClick={onEndEdit} variant="contained" color="secondary">
                        Cancel
                    </Button>
                </>
            )}
        </div>
    );
};

export default MEPCostingCreate;
