import { useSelector } from 'react-redux';
import { RootState } from 'store/state';

export const useRolesLoading = () =>
    useSelector<RootState, RootState['roles']['loading']>(state => state.roles.loading);

export const useRolesData = () =>
    useSelector<RootState, RootState['roles']['roles']>(state => state.roles.roles);

export const useRolePermissions = () =>
    useSelector<RootState, RootState['roles']['rolePermissions']>(
        state => state.roles.rolePermissions
    );
