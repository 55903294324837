import React from 'react';

import './omp.scss';
import { RouteComponentProps } from 'react-router-dom';
import MEPOverrideList from 'components/template/OMPList';

type Props = RouteComponentProps;

const OMPList: React.FC<Props> = ({ location }) => {
    return (
        <div className="mepOverrides">
            <h1>MEP Overrides</h1>
            <MEPOverrideList location={location} />
        </div>
    );
};

export default OMPList;
