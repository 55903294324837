enum InspectionsTypes {
    GET_INSPECTION_FAIL = 'GET_INSPECTION_FAIL',
    GET_INSPECTION_LOADING = 'GET_INSPECTION_LOADING',
    GET_INSPECTION_SUCCESS = 'GET_INSPECTION_SUCCESS',

    GET_INSPECTIONS_FAIL = 'GET_INSPECTIONS_FAIL',
    GET_INSPECTIONS_LOADING = 'GET_INSPECTIONS_LOADING',
    GET_INSPECTIONS_SUCCESS = 'GET_INSPECTIONS_SUCCESS',

    CREATE_INSPECTION_SUCCESS = 'CREATE_INSPECTION_SUCCESS',
    CREATE_INSPECTION_FAIL = 'CREATE_INSPECTION_FAIL',

    DELETE_INSPECTION_SUCCESS = 'DELETE_INSPECTION_SUCCESS',
}

export default InspectionsTypes;

export interface InspectionI {
    ins_id: string;
    ins_equ_id: string;
    ins_condition: string;
    ins_created_by?: string;
    ins_created_date?: Date;
    ins_updated_date?: Date;
    ins_updated_by?: string;
    ins_notes?: string;
}

export interface InspectionCreateI {
    ins_equ_id: string;
    ins_condition: string;
    ins_notes: string;
    ins_tags?: string[];
}
