import MepFullDetails from 'components/template/MepFullDetails';
import hooks from 'hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { getMEPDetails } from 'store/mep/mep.actions';

type Props = RouteComponentProps<{ mepID: string; edit?: string }>;

const MEPDetails: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const { mepID, edit } = match.params;
    const editMode = edit === 'edit';

    const mepDetailsObj = hooks.mep.useMEPDetailsObj();
    const details = mepDetailsObj[mepID]?.mep || {};

    useEffect(() => {
        dispatch(getMEPDetails(mepID, true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mepDetails">
            <h1>MEP Details</h1>
            {Object.keys(details).length > 3 ? (
                <MepFullDetails editMode={editMode} details={details} />
            ) : (
                <div>
                    <ClipLoader />
                </div>
            )}
        </div>
    );
};

export default MEPDetails;
