import React, { FC } from 'react';
import { RolePermissions } from 'store/roles/roles.types';

import { mapPermissions } from 'utils/roles';
import { Permission } from './Permission';

type Props = { permissions: RolePermissions };
export const Permissions: FC<Props> = ({ permissions }) => {
    const mappedPermissions = mapPermissions(permissions ?? {});

    return (
        <>
            {Object.values(mappedPermissions).map(p => (
                <Permission key={p.ID} permission={p} />
            ))}
        </>
    );
};
