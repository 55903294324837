enum CityStateTypes {
    GET_CITY_STATE_LOADING = 'GET_CITY_STATE_LOADING',

    LIST_CITY_STATE_SUCCESS = 'LIST_CITY_STATE_SUCCESS',
    LIST_CITY_STATE_FAIL = 'LIST_CITY_STATE_FAIL',
    MISSING_CITY_STATE_SUCCESS = 'MISSING_CITY_STATE_SUCCESS',

    CREATE_CITY_STATE_LOADING = 'CREATE_CITY_STATE_LOADING',
    CREATE_CITY_STATE_SUCCESS = 'CREATE_CITY_STATE_SUCCESS',
    CREATE_CITY_STATE_FAIL = 'CREATE_CITY_STATE_FAIL',
}

export default CityStateTypes;

export interface CityStateListItemI {
    ocs_id: string;
    ocs_city: string;
    ocs_state: string;
    ocs_multiplier: string;
    ocs_created_by: string;
    ocs_created_date: string;
    ocs_updated_by: string;
    ocs_updated_date: string;
    ocs_description?: string;
}

export type CityStateI = CityStateListItemI;

export type MEPCreateI = CityStateI;

export interface MissingOCSI {
    ocs_city: string;
    ocs_state: string;
}
