import { EquipmentI } from 'store/equipment/equipment.types';

enum BuildingsTypes {
    GET_BUILDINGS_FAIL = 'GET_BUILDINGS_FAIL',
    GET_BUILDINGS_LOADING = 'GET_BUILDINGS_LOADING',
    GET_BUILDINGS_SUCCESS = 'GET_BUILDINGS_SUCCESS',

    GET_BUILDING_DETAILS_SUCCESS = 'GET_BUILDING_DETAILS_SUCCESS',
    GET_BUILDING_DETAILS_FAIL = 'GET_BUILDING_DETAILS_FAIL',

    SAVE_BUILDING_LOADING = 'SAVE_BUILDING_LOADING',
    SAVE_BUILDING_SUCCESS = 'SAVE_BUILDING_SUCCESS',
    SAVE_BUILDING_FAIL = 'SAVE_BUILDING_FAIL',

    UPLOAD_OFFLINE_BUILDING = 'UPLOAD_OFFLINE_BUILDING',
    UPLOAD_OFFLINE_BUILDING_ERROR = 'UPLOAD_OFFLINE_BUILDING_ERROR',
    UPLOAD_OFFLINE_BUILDING_SUCCESS = 'UPLOAD_OFFLINE_BUILDING_SUCCESS',
    UPLOAD_OFFLINE_BUILDING_CLEAR = 'UPLOAD_OFFLINE_BUILDING_CLEAR',
}

export default BuildingsTypes;

export interface BuildingI {
    bld_sec_id: string;
    bld_id: string;
    bld_glc: string;
    bld_responder_code: string;
    bld_name: string;
    bld_address_line1: string;
    bld_address_line2?: string;
    bld_city: string;
    bld_county?: string;
    bld_state: string;
    bld_zip: string;
    bld_leased_owned: string;
    reg_id: string;
    dis_id: string;
    sec_id: string;
    dis_reg_id: string;
    sec_dis_id: string;
    reg_name: string;
    dis_name: string;
    sec_name: string;
    img_id?: string;
    img_description?: string;

    bld_image_url?: string;

    img_orig_aws_bucket?: string;
    img_thumbnail_aws_bucket?: string;
    img_small_aws_bucket?: string;
    img_lg_aws_bucketl?: string;
    img_entity_id?: string;
    img_entity?: string;
    img_default?: number;
    img_created_date?: string;

    portfolio_managers?: string;
    region_managers?: string;
    subregion_managers?: string;

    bld_yard_id?: string;
    bld_sf?: number;
    bld_stories_serviced?: number;
}

export interface BuildingCreateOrUpdateI {
    bld_id?: string;
    bld_sec_id: string;
    bld_name: string;
    bld_responder_code: string;
    bld_glc: string;
    bld_address_line1: string;
    bld_address_line2?: string;
    bld_city: string;
    bld_state: string;
    bld_zip: string;
    bld_leased_owned?: string;
}

export interface BuildingDetailsI {
    building: BuildingI;
    images: ImageI[];
    equipment: EquipmentI[];
}

export interface ImageI {
    img_id: string;
    img_description: string;
    img_orig_aws_bucket: string;
    img_thumbnail_aws_bucket: string;
    img_small_aws_bucket: string;
    img_lg_aws_bucketl: string;
    img_entity_id: string;
    img_entity: string;
    img_default: number;
    img_created_date: string;
}
