import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';

import { crewAPI } from 'utils/API';
import DashboardTypes, { DashboardData } from './dashboard.types';

export type DashboardActions =
    | { type: typeof DashboardTypes.GET_DASHBOARD_FAIL; payload: { errorMessage: string } }
    | { type: typeof DashboardTypes.GET_DASHBOARD_LOADING }
    | {
          type: typeof DashboardTypes.GET_DASHBOARD_SUCCESS;
          payload: { data: DashboardData; tier?: string; tierID?: string };
      };

export const getDashboard = ({ tier, tierID }: { tier?: string; tierID?: string }) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, DashboardActions>) => {
        dispatch({ type: DashboardTypes.GET_DASHBOARD_LOADING });

        try {
            const path = tier && tierID ? `dashboards/${tier}/${tierID}` : 'dashboards/home';

            const data = await crewAPI.get<DashboardData>({ path });

            dispatch({
                type: DashboardTypes.GET_DASHBOARD_SUCCESS,
                payload: { data, tier, tierID },
            });
        } catch (error) {
            console.log('get dashboard error api', error);
            dispatch({
                type: DashboardTypes.GET_DASHBOARD_FAIL,
                payload: {
                    errorMessage:
                        'Unable to get the data for the dashboard - please wait 30s and refresh the page',
                },
            });
        }
    };
};
