import { Box, CircularProgress } from '@material-ui/core';
import React, { FC, useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MappedRolePermission, MappedSubfeatures } from 'store/roles/roles.types';

const checkedStatus = (mappedSubfeatures: MappedSubfeatures) => {
    const allChecked =
        mappedSubfeatures.every(sf => sf.isChecked) && mappedSubfeatures.length !== 0;

    if (allChecked) return 'all';

    const noneChecked = mappedSubfeatures.every(sf => !sf.isChecked);

    return noneChecked ? 'none' : 'some';
};

type Props = { permission: MappedRolePermission };
export const Permission: FC<Props> = ({ permission }) => {
    const [state, setState] = useState({ showSubfeatures: false, loading: false, error: '' });

    const onToggle = async () => {
        /*         try {
            if (state.loading) return;

            setState({ ...state, loading: true });

            // console.log('toggling...');
            await new Promise(resolve => setTimeout(resolve, 1000));
            setState({ ...state, loading: false, error: '' });
        } catch (error) {
            console.log('error toggling subfeature: ', error);
            setState({ ...state, loading: false, error: error.message });
        } */
    };

    const Icon = () => {
        const status = checkedStatus(permission.subfeatures);

        if (state.loading) {
            return <LoadingIcon />;
        }
        if (status === 'all') {
            return <CheckBoxIcon fontSize="small" /* cursor="pointer" */ onClick={onToggle} />;
        }
        if (status === 'none') {
            return (
                <CheckBoxOutlineBlankIcon
                    fontSize="small"
                    /* cursor="pointer" */ onClick={onToggle}
                />
            );
        }

        return <IndeterminateCheckBoxIcon fontSize="small" /* cursor="pointer" */ />;
    };

    return (
        <>
            <Box display="flex" mb="10px" alignItems="center">
                <Box width="30px" display="flex" alignItems="center">
                    {permission.canHaveSubfeatures && (
                        <>
                            {state.showSubfeatures ? (
                                <ExpandMoreIcon
                                    onClick={() => setState({ ...state, showSubfeatures: false })}
                                    cursor="pointer"
                                />
                            ) : (
                                <ChevronRightIcon
                                    onClick={() => setState({ ...state, showSubfeatures: true })}
                                    cursor="pointer"
                                />
                            )}
                        </>
                    )}
                </Box>

                <Icon />

                <Box ml="5px">{permission.ID}</Box>
            </Box>
            {state.showSubfeatures &&
                permission.subfeatures.map(sf => <Subfeature key={sf.name} sf={sf} />)}
        </>
    );
};

const Subfeature: FC<{ sf: { name: string; isChecked: boolean } }> = ({ sf }) => {
    const [state] = useState({ loading: false, error: '' });

    const onToggle = async () => {
        /* try {
            if (state.loading) return;

            setState({ ...state, loading: true });

            console.log('toggling...');
            await new Promise(resolve => setTimeout(resolve, 1000));
            setState({ loading: false, error: '' });
        } catch (error) {
            console.log('error toggling subfeature: ', error);
            setState({ loading: false, error: error.message });
        } */
    };

    const Icon = () =>
        // eslint-disable-next-line no-nested-ternary
        state.loading ? (
            <LoadingIcon />
        ) : sf.isChecked ? (
            <CheckBoxIcon fontSize="small" /* cursor="pointer" */ onClick={onToggle} />
        ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" /* cursor="pointer" */ onClick={onToggle} />
        );

    return (
        <Box display="flex" alignItems="center" mb="8px" ml="55px">
            <Icon />

            <Box ml="5px">{sf.name}</Box>
        </Box>
    );
};

const LoadingIcon = () => (
    <Box display="flex" alignItems="center" justifyContent="center" mr="4px" pl="1px" pb="2px">
        <CircularProgress size="15px" />
    </Box>
);
