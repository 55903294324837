import React from 'react';
import EquipmentFullDetails from 'components/template/EquipmentFullDetails';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';

type Props = RouteComponentProps;

const EquipmentCreate: React.FC<Props> = ({ location }) => {
    const buildingID = new URLSearchParams(location.search).get('buildingID');
    const launchInfo = {
        equ_bld_id: buildingID,
    };

    return (
        <Box>
            <Box ml={1}>
                <h1>Create New Equipment</h1>
            </Box>

            <EquipmentFullDetails
                details={launchInfo as unknown as EquipmentListItemI}
                isCreateView
            />
        </Box>
    );
};

export default withRouter(EquipmentCreate);
