import { EquipmentsAction } from 'store/equipment/equipment.actions';
import EquipmentsTypes from 'store/equipment/equipment.types';
import { OptionsAction } from './options.actions';

import OptionsState from './options.state';
import OptionsTypes from './options.types';

export const defaultState: OptionsState = {
    error: false,
    message: '',
    loading: false,

    mepSystems: [],
    mepSubSystemsMap: {},
    mepPicklist: {},
    equipmentTableFilterOptions: [],
    buildingTableFilterOptions: [],
    tags: [],
    regionOptions: [],
    equipmentSystemCategories: {},
    equipmentStates: [
        {
            stateName: 'Active',
            stateValue: 1,
        },
        {
            stateName: 'Staging',
            stateValue: 2,
        },
        {
            stateName: 'Retired',
            stateValue: 3,
        },
    ],
};

const optionsReducer = (
    state: OptionsState = defaultState,
    action: OptionsAction | EquipmentsAction
): OptionsState => {
    switch (action.type) {
        case OptionsTypes.GET_OPTIONS_LOADING:
            return { ...state, loading: true, error: false, message: '' };
        case OptionsTypes.GET_OPTIONS_SUCCESS:
            return { ...state, ...action.payload, loading: false };
        case OptionsTypes.GET_OPTIONS_FAIL:
            return { ...defaultState, ...action.payload, loading: false, error: true };
        case EquipmentsTypes.LIST_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipmentTableFilterOptions: action.payload.filters
                    ? action.payload.filters
                    : state.equipmentTableFilterOptions,
            };
        case OptionsTypes.GET_SUBSYSTEM_SUCCESS:
            return {
                ...state,
                mepSubSystemsMap: {
                    ...state.mepSubSystemsMap,
                    [action.payload.system]: action.payload.subsystems,
                },
            };
        case OptionsTypes.ADD_TAG_OPTIONS:
            return {
                ...state,
                tags: [...new Set([...state.tags, ...action.payload.tags])],
            };

        default:
            return state;
    }
};

export default optionsReducer;
