import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import NewEquipmentList from 'components/template/NewEquipmentList';
import { Box } from '@material-ui/core';

type Props = RouteComponentProps;

const EquipmentList: React.FC<Props> = ({ location }) => {
    return (
        <Box mx={1} mb={2}>
            <h1>FCA List</h1>
            <NewEquipmentList location={location} />
        </Box>
    );
};

export default EquipmentList;
