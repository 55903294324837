import React, { useState, useEffect } from 'react';
import { EquipmentListItemI } from 'store/equipment/equipment.types';
import { TableRow, TableCell, Box, Button } from '@material-ui/core';
// import NoteAddIcon from '@material-ui/icons/NoteAdd';
// import DescriptionIcon from '@material-ui/icons/Description';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import {
    EnhancedTableFilterI,
    EnhancedTableFilterProps,
} from 'components/organisms/EnhancedTableFilter';
import { enhancedTableFiltersToApiFilters, listEquipment } from 'store/equipment/equipment.actions';
import { useDispatch } from 'react-redux';
import hooks from 'hooks';
import queryString from 'query-string';
import { RouteComponentProps, Link } from 'react-router-dom';
import './NewEquipmentList.scss';
import { useLocalStorage } from 'hooks/storage';
import { AppFeatures } from 'store/app/app.state';
import utils from 'utils';
import { EquipmentExcelExport } from 'components/molecules/EquipmentExcelExport';

import { Visibility, AddCircleOutline } from '@material-ui/icons';
import { useGlobalStyles } from '../../../hooks/useGlobalStyles';

interface Props {
    location: RouteComponentProps['location'];
}

interface Data extends EquipmentListItemI {
    view: string;
    add_inspection: string;
}

const NewEquipmentList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const equipmentListArray = hooks.equipment.useEquipmentList();
    const equipmentLoaded = hooks.equipment.useEquipmentListLoaded();
    const equipmentLength = hooks.equipment.useEquipmentListLength();
    const equipmentSchema = hooks.equipment.useEquipmentSchema();
    const equipmentError = hooks.equipment.useError();
    const totalEquipmentReplacementCost = hooks.equipment.useEquipmentReplacementCost();
    const { isMaintenanceModeEnabled } = hooks.adminSettings.useAdminSettingsState();

    const hasFeature = hooks.user.useHasFeature();

    const { pagenum } = queryString.parse(location.search) as {
        [key: string]: string | string[];
    };

    const initialSort = 'ins_rating';
    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>(initialSort);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [filters, setFilters] = useLocalStorage<EnhancedTableFilterI[]>('equipmentFilters', []);

    const [loadingList, setLoadingList] = useState(true);

    useEffect(() => {
        setLoadingList(true);
        console.log('filters', filters);
        dispatch(
            listEquipment({
                sort: {
                    field: orderBy,
                    ascdesc: orderDirection,
                },
                limit: rowsPerPage,
                skip: rowsPerPage * page,
                filters,
            })
        );
    }, [orderBy, orderDirection, rowsPerPage, page, filters, dispatch]);

    useEffect(() => {
        console.log('equipment list loaded');
        if (equipmentLoaded) {
            setLoadingList(false);
        }
    }, [equipmentListArray, equipmentLoaded]);

    const unsortedRowData: Data[] = equipmentListArray.map(equipment => {
        return {
            ...equipment,
            ins_condition: equipment.ins_condition,
            mep_system: equipment.mep_system,
            view: 'show',
            add_inspection: 'show',
        };
    });

    const rowData = stableSort<Data>(unsortedRowData, getComparator(orderDirection, orderBy));

    const showViewEquipmentDetails = hasFeature(
        AppFeatures.EQUIPMENT.ID,
        AppFeatures.EQUIPMENT.subfeatures.view
    );
    const showAddInspection = hasFeature(
        AppFeatures.CONDITION_ASSESSMENTS.ID,
        AppFeatures.CONDITION_ASSESSMENTS.subfeatures.view
    );

    // TODO Add more columns to the table
    const headCells: HeadCell<Data>[] = [
        {
            id: 'equ_business_id',
            numeric: false,
            disablePadding: false,
            label: 'ID',
        },
        // { id: 'bld_id', numeric: false, disablePadding: false, label: 'Building ID' },

        {
            id: 'equ_system_category',
            numeric: false,
            disablePadding: false,
            label: 'Category',
        },
        {
            id: 'equ_system_name',
            numeric: false,
            disablePadding: false,
            label: 'System',
        },

        { id: 'bld_glc', numeric: false, disablePadding: false, label: 'GLC' },
        {
            id: 'bld_address_line1',
            numeric: false,
            disablePadding: false,
            label: 'Address',
        },
        {
            id: 'ins_rating',
            numeric: false,
            disablePadding: false,
            label: 'Condition',
        },
        {
            id: 'total_replacement_cost',
            numeric: false,
            disablePadding: false,
            label: 'Replacement Cost',
        },

        { id: 'view', numeric: false, disablePadding: false, disableSort: true, label: 'View' },
        {
            id: 'add_inspection',
            numeric: false,
            disablePadding: false,
            disableSort: true,
            label: 'Add Inspection',
        },
    ];

    if (!showAddInspection) headCells.pop();
    if (!showViewEquipmentDetails) headCells.pop();

    const rowJSX = (row: Data) => {
        return (
            <React.Fragment key={`${row.bld_glc}-${row.equ_id}`}>
                <TableRow className={`equipment-condition-${row.ins_condition || 'nodata'}`}>
                    <TableCell align="center">{row.equ_business_id}</TableCell>

                    <TableCell align="center">{row.equ_system_category}</TableCell>
                    <TableCell align="center">{row.equ_system_name}</TableCell>

                    <TableCell align="center">{row.bld_glc}</TableCell>
                    <TableCell align="center">
                        {`${row.bld_name}, ${row.bld_city}, ${row.bld_state}`}
                    </TableCell>

                    <TableCell align="center">{row.ins_condition || 'No Data'}</TableCell>
                    <TableCell align="center">
                        ${utils.numberWithCommas(Number(row.total_replacement_cost), 2)}
                    </TableCell>

                    {showViewEquipmentDetails ? (
                        <TableCell align="center">
                            <Button
                                aria-label="View details"
                                className={globalClasses.tableActionButton}
                                component={Link}
                                to={`/assets/equipment/${row.equ_id}`}
                            >
                                <Visibility />
                            </Button>
                        </TableCell>
                    ) : null}
                    {showAddInspection ? (
                        <TableCell align="center">
                            <Button
                                aria-label="Add inspection"
                                className={globalClasses.tableActionButton}
                                component={Link}
                                to={`/assets/equipment/${row.equ_id}/create-inspection`}
                            >
                                <AddCircleOutline />
                            </Button>
                        </TableCell>
                    ) : null}
                </TableRow>

                {row.ept_tag ? (
                    <TableRow className={`equipment-condition-${row.ins_condition || 'nodata'}`}>
                        <TableCell colSpan={1} />
                        <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={headCells.length - 1}
                        >
                            Tags = {row.ept_tag}
                        </TableCell>
                    </TableRow>
                ) : null}
            </React.Fragment>
        );
    };

    // todo once equipment status filtering works
    if (isMaintenanceModeEnabled && equipmentSchema?.length) {
        if (equipmentSchema[equipmentSchema.length - 1].COLUMN_NAME !== 'equ_status') {
            equipmentSchema.push({
                COLUMN_NAME: 'equ_status',
                COLUMN_TYPE: 'map',
                COLUMN_LABEL: 'Status',
                IS_NULLABLE: false,
                MAP: {
                    Active: 1,
                    Staging: 2,
                    Retired: 3,
                },
            });
        }
    }

    const filterParams: EnhancedTableFilterProps = {
        schema: equipmentSchema,
        filters,
        onFiltersChange: (filters: EnhancedTableFilterI[]) => {
            console.log('setFilters called with', filters);
            setPage(0);
            setFilters(filters);
        },
    };

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    return (
        <div>
            <SortTable<Data>
                // tableTitle="Equipment List"
                filterParams={filterParams}
                rows={rowData}
                headCells={headCells}
                rowJSX={rowJSX}
                totalRowCount={equipmentLength}
                dense
                loadingList={loadingList}
                error={equipmentError.error}
                page={page}
                setPage={setPage}
                orderDirection={orderDirection}
                orderBy={orderBy}
                onOrderByChange={onOrderByChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={setRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100].sort((a: number, b: number) => a - b)}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <EquipmentExcelExport
                    equipmentLength={equipmentLength}
                    sort={JSON.stringify({
                        field: orderBy,
                        ascdesc: orderDirection,
                    })}
                    filter={enhancedTableFiltersToApiFilters(filters)}
                />
                <Box>Total Replacement Cost = ${totalEquipmentReplacementCost}</Box>
            </Box>
        </div>
    );
};

export default NewEquipmentList;
