import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import hooks from 'hooks';

interface Props {
    onSave: () => void;
    onReset: () => void;
    onPreview: () => void;
    onEdit: () => void;
    isPreviewEnabled: boolean;
    hasPendingChanges: boolean;
    isTemplateView?: boolean;
}

const TemplateEditButtons: React.FC<Props> = ({
    onSave,
    onReset,
    onPreview,
    onEdit,
    isPreviewEnabled,
    hasPendingChanges,
    isTemplateView,
}) => {
    const { isSaving } = hooks.equipmentTemplate.useCurrentEquipmentTemplateState();
    const globalClasses = hooks.style.useGlobalStyles();

    return (
        <Box mt={1}>
            <Box mb={1}>
                {!isTemplateView && (
                    <Typography variant="body1">
                        Template edit mode is enabled. To return to equipment edit mode, visit{' '}
                        <Link to="/admin/settings">Administration Settings</Link>.
                    </Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                {isPreviewEnabled ? (
                    <Button
                        className={globalClasses.button}
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        onClick={onEdit}
                    >
                        Edit
                    </Button>
                ) : (
                    <Button
                        className={globalClasses.button}
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                        onClick={onPreview}
                    >
                        Preview
                    </Button>
                )}

                {hasPendingChanges && (
                    <>
                        <Button
                            className={globalClasses.button}
                            variant="contained"
                            color="primary"
                            disabled={isSaving}
                            onClick={onSave}
                        >
                            Save
                        </Button>
                        <Button
                            className={globalClasses.button}
                            variant="contained"
                            color="secondary"
                            disabled={isSaving}
                            onClick={onReset}
                        >
                            Reset
                        </Button>
                    </>
                )}

                {isSaving && <CircularProgress size={24} />}
            </Box>
        </Box>
    );
};

export default TemplateEditButtons;
