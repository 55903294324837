import React, { FC, useEffect, useState } from 'react';
import ResponsiveGallery from 'react-responsive-gallery';

import { Storage } from 'aws-amplify';
import { EquipmentDetailsI } from 'store/equipment/equipment.state';

interface Props {
    images: EquipmentDetailsI['images'];
}

const ImageCarousel: FC<Props> = ({ images }) => {
    const [urls, setUrls] = useState<string[]>([]);

    useEffect(() => {
        const mappedImages = images?.map(img => img.img_srcKey) || [];

        Promise.all(
            mappedImages.map(filename => {
                const splits = filename.split('.');
                return Storage.get(filename, {
                    level: 'public',
                    contentType: `image/${splits[splits.length - 1]}`,
                }) as unknown as string;
            })
        ).then(urls => {
            console.log(urls);
            setUrls(urls);
        });
    }, [images]);

    return (
        <div className="storedImages">
            <ResponsiveGallery images={urls.map(url => ({ src: url }))} />
        </div>
    );
};

export default ImageCarousel;
