import React, { ReactChild } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { v4 as uuid } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        } as CSSProperties,
    })
);

interface Props {
    sections: {
        children: ReactChild;
        title: string;
        ID?: string;
        expanded?: boolean;
    }[];
}

export default function SimpleAccordion({ sections }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {sections.map(({ children, title, ID, expanded }, index) => {
                return (
                    <Accordion
                        key={`accordion-section-${ID ?? index}`}
                        id={ID}
                        defaultExpanded={expanded}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={ID || title + uuid()}
                        >
                            <Typography className={classes.heading}>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>{children}</AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
}
