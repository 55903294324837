import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import './TickboxWrapper.scss';

export interface TickboxOptions {
    label: string;
    id: string;
    selected: boolean;
}

interface Props {
    options: Record<string, TickboxOptions>;
    editable: boolean;
    onChange: (optionID) => void;
}

const TickboxWrapper: React.FC<Props> = ({ options, onChange, editable }) => {
    return (
        <div className="tickboxes">
            {Object.values(options).map(option => (
                <div key={option.id} className="tickboxOption">
                    <CheckBox
                        ticked={option.selected}
                        onToggle={() => {
                            if (editable) onChange(option.id);
                        }}
                    />
                    <span className="label">{option.label || option.id}</span>
                </div>
            ))}
        </div>
    );
};

export default TickboxWrapper;

const CheckBox = ({ ticked, onToggle }: { ticked: boolean; onToggle: () => void }) => {
    if (ticked) {
        return <CheckBoxIcon fontSize="small" /* cursor="pointer" */ onClick={onToggle} />;
    }
    return <CheckBoxOutlineBlankIcon fontSize="small" /* cursor="pointer" */ onClick={onToggle} />;
};
