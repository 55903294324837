/* eslint-disable no-shadow */
import React, { FC, useState } from 'react';

import hooks from 'hooks';
import utils from 'utils';
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { getEquipmentDetails } from 'store/equipment/equipment.actions';
import { getBuildingDetails } from 'store/buildings/buildings.actions';

interface Props {
    equipmentID?: string;
    buildingID?: string;
}

const ImageUploader: React.FC<Props> = ({ equipmentID, buildingID }) => {
    const dispatch = useDispatch();
    const appDetails = hooks.app.useApp();
    const [status, setStatus] = useState('waiting');

    const onChange = async (fileList: FileList | null) => {
        setStatus('uploading');
        if (!fileList) {
            setStatus('error');
            return;
        }

        const files = Array.from(fileList);

        if (equipmentID) {
            await addEquipmentImages({ files, equipmentID });
            return;
        }
        if (buildingID) {
            // dispatch(addBuildingImage({ file, buildingID }));
        }
    };

    const addEquipmentImages = async ({
        files,
        equipmentID,
    }: {
        files: File[];
        equipmentID: string;
    }) => {
        try {
            await utils.attachments.uploadImages({ appDetails, files, equipmentID });
            setStatus('success');
        } catch (error) {
            setStatus('error');
        }
    };

    const Content = () => {
        switch (status) {
            case 'waiting':
                return (
                    <div>
                        <ImageFileInput onChange={onChange} />
                    </div>
                );
            case 'uploading':
                return <ClipLoader />;
            case 'success':
                if (equipmentID) {
                    dispatch(getEquipmentDetails(equipmentID, true));
                    setStatus('waiting');
                }
                if (buildingID) {
                    dispatch(getBuildingDetails(buildingID, true));
                    setStatus('waiting');
                }
                return (
                    <div>
                        <div>File Uploaded</div>
                        <ImageFileInput onChange={onChange} />
                    </div>
                );
            case 'error':
                return (
                    <div>
                        <div>Failed to Upload Image, Please try again</div>
                        <ImageFileInput onChange={onChange} />
                    </div>
                );
            default:
                return (
                    <div>
                        <ImageFileInput onChange={onChange} />
                    </div>
                );
        }
    };

    return (
        <div>
            <h3>Upload New Image(s)</h3>
            <Content />
        </div>
    );
};

type ImageFileInputProps = { onChange: (fileList: FileList | null) => Promise<void> };
const ImageFileInput: FC<ImageFileInputProps> = ({ onChange }) => (
    <input
        type="file"
        accept="image/png, , image/jpeg, , image/jpg"
        onChange={e => onChange(e?.target?.files)}
        multiple
    />
);

export default ImageUploader;
