import { stringFloatToInt, ValueConverter } from '../../../utils/valueConverters';

export interface EquipmentFieldDefinition {
    key: string;
    label: string;
    type: 'text' | 'number';
    isOptional: boolean;
    isReadOnly: boolean;
    fullWidth?: boolean;
    minRows?: number;
    maxRows?: number;
    width?: number;
    valueConverter?: ValueConverter;
}

export const detailsEquipmentFields: EquipmentFieldDefinition[] = [
    { key: 'equ_alias', label: 'Alias', type: 'text', isOptional: true, isReadOnly: false },
    {
        key: 'equ_description',
        label: 'Description',
        maxRows: 2,
        type: 'text',
        isOptional: true,
        isReadOnly: false,
    },
    {
        key: 'equ_business_id',
        label: 'Equipment Business ID',
        type: 'text',
        isOptional: true,
        isReadOnly: false,
    },
    {
        key: 'equ_id',
        label: 'Raw Equipment ID',
        type: 'text',
        width: 330,
        isOptional: false,
        isReadOnly: true,
    },
    {
        key: 'equ_approx_yr_installed',
        label: 'Install Year',
        type: 'number',
        isOptional: true,
        isReadOnly: false,
    },
    {
        key: 'equ_mep_quantity',
        label: 'MEP Quantity',
        type: 'number',
        isOptional: true,
        isReadOnly: false,
        valueConverter: stringFloatToInt,
    },
];

export const locationEquipmentFields: EquipmentFieldDefinition[] = [
    { key: 'equ_bld_id', label: 'BuildingID', type: 'text', isOptional: false, isReadOnly: true },
    { key: 'equ_floor', label: 'Floor', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_room', label: 'Room', type: 'text', isOptional: true, isReadOnly: false },
];

export const technicalDetailsEquipmentFields: EquipmentFieldDefinition[] = [
    { key: 'equ_mfg', label: 'Manufacturer', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_model', label: 'Model', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_vendor', label: 'Vendor', type: 'text', isOptional: true, isReadOnly: false },
    {
        key: 'equ_serial_number',
        label: 'Serial Number',
        type: 'text',
        isOptional: true,
        isReadOnly: false,
    },
    { key: 'equ_specs', label: 'Specs', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_ton', label: 'Ton', type: 'number', isOptional: true, isReadOnly: false },
    { key: 'equ_btu', label: 'BTU', type: 'number', isOptional: true, isReadOnly: false },
    { key: 'equ_hp', label: 'HP', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_volt_amp', label: 'Volt Amps', type: 'text', isOptional: true, isReadOnly: false },
    { key: 'equ_gal', label: 'Gallons', type: 'number', isOptional: true, isReadOnly: false },
    { key: 'equ_pod', label: 'POD', type: 'text', isOptional: true, isReadOnly: false },
    {
        key: 'equ_dr_rate_percent',
        label: 'DR Rate Percent',
        type: 'text',
        isOptional: true,
        isReadOnly: false,
    },
];

export const notesField = {
    key: 'equ_notes',
    label: 'Notes',
    type: 'text',
    maxRows: 4,
    minRows: 2,
    isOptional: true,
    isReadOnly: false,
    fullWidth: true,
} as const;
