import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import EquipmentTemplatesList from 'components/template/EquipmentTemplatesList';
import { useDispatch } from 'react-redux';
import { getTemplateList } from '../../store/equipmentTemplate/equipmentTemplate.actions';
import EquipmentTemplateActionTypes from '../../store/equipmentTemplate/equipmentTemplate.types';

const EquipmentTemplatesListView: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTemplateList());

        return () => {
            dispatch({ type: EquipmentTemplateActionTypes.RESET_TEMPLATE_LIST });
        };
    }, [dispatch]);

    return (
        <Box mx={1} mb={2}>
            <Box>
                <h1>Equipment Templates</h1>
            </Box>

            <EquipmentTemplatesList />
        </Box>
    );
};

export default EquipmentTemplatesListView;
