import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import DashboardCard from 'components/molecules/DashboardCard';
import DashboardHero from 'components/molecules/DashboardHero';
import { useDispatch } from 'react-redux';
import { getDashboard } from 'store/dashboard/dashboard.actions';
import hooks from 'hooks';
import { ClipLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router-dom';
import './Dashboard.scss';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            paddingLeft: '10px',
        },
        hero: {
            width: '100%',
            marginTop: '2px',
            float: 'left',
        },
    })
);
type Props = RouteComponentProps<{ tier?: string; tierID?: string }>;

const Dashboard: React.FC<Props> = ({ match }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = hooks.dashboard.useDashboardLoading();
    const dashboardData = hooks.dashboard.useDashboardData();

    const { tier, tierID } = match.params;

    useEffect(() => {
        dispatch(getDashboard({ tier, tierID }));
    }, [dispatch, tier, tierID]);

    return (
        <div className="Dashboard">
            {loading || !dashboardData ? (
                <div className="full-width-clip-loader">
                    <ClipLoader
                        css={`
                            display: block;
                            margin: 0 auto;
                        `}
                        size={50}
                        color="#123abc"
                        loading
                    />
                </div>
            ) : (
                <>
                    <div className={classes.hero}>
                        {dashboardData.hero ? (
                            <DashboardHero data={dashboardData.hero} />
                        ) : (
                            <Paper className="dashboardLoadingError">
                                <h1>No Dashboard Available</h1>
                            </Paper>
                        )}
                    </div>
                    <div className="sublevel">
                        {dashboardData?.sublevels?.map(dashboard_data => (
                            <DashboardCard key={dashboard_data.tierId} data={dashboard_data} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;
