import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import hooks from 'hooks';
import { getBuildingDetails } from 'store/buildings/buildings.actions';
import { Paper, CircularProgress, Button, Box } from '@material-ui/core';
import BuildingFullDetails from 'components/molecules/BuildingFullDetails';
import { BuildingI } from 'store/buildings/buildings.types';
import { useLocalStorage } from 'hooks/storage';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import { appRedirect } from 'store/app/app.actions';
import { AppFeatures } from 'store/app/app.state';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

type Props = RouteComponentProps<{ buildingID: string; edit?: string }>;

const BuildingDetails: React.FC<Props> = ({ match }) => {
    const { buildingID, edit } = match.params;
    const editMode = edit === 'edit';
    const dispatch = useDispatch();
    const [, setEquipmentFilters] = useLocalStorage<EnhancedTableFilterI[]>('equipmentFilters', []);
    const hasFeature = hooks.user.useHasFeature();
    const { buildingDetails } = hooks.buildings.useBuildingState();
    const globalClasses = useGlobalStyles();

    useEffect(() => {
        dispatch(getBuildingDetails(buildingID));
    }, [buildingID, dispatch]);

    const equipmentFilter: EnhancedTableFilterI = {
        field: {
            COLUMN_NAME: 'bld_id',
            COLUMN_LABEL: 'building ID',
            COLUMN_TYPE: 'option',
            IS_NULLABLE: false,
            OPTIONS: [buildingID],
        },
        operation: {
            label: 'Equals One Of',
            value: 'eq',
            inputs: { number: 1, format: 'options' },
        },
        value: [buildingID],
    };

    return (
        <Box>
            <Box margin={1}>
                <h1>{editMode ? 'Edit ' : ''}Building Details</h1>
            </Box>

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} mx={0.5}>
                <Box mx={0.5} flexGrow={1}>
                    <Paper className={globalClasses.paper}>
                        {buildingID && buildingDetails[buildingID]?.building ? (
                            <BuildingFullDetails
                                details={
                                    (buildingID &&
                                        buildingDetails[buildingID].building) as BuildingI
                                }
                                editMode={editMode}
                                isCreate={false}
                            />
                        ) : (
                            <Box display="flex" justifyContent="center" my={2}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Paper>
                </Box>
                <Box mx={0.5} width={{ xs: 'auto', md: '35%' }} flexShrink={0}>
                    <Paper className={globalClasses.paper}>
                        <h2>Equipment</h2>
                        <Box>
                            <Button
                                className={globalClasses.button}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setEquipmentFilters([equipmentFilter]);
                                    dispatch(appRedirect(`/assets/equipment`));
                                }}
                            >
                                View
                            </Button>
                            {hasFeature(
                                AppFeatures.EQUIPMENT.ID,
                                AppFeatures.EQUIPMENT.subfeatures.create
                            ) && (
                                <Button
                                    component={Link}
                                    to={`/assets/equipment/create?buildingID=${buildingID}`}
                                    className={globalClasses.button}
                                    variant="contained"
                                    color="primary"
                                >
                                    Create New
                                </Button>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default BuildingDetails;
