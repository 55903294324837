import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useMepData = () => {
    const mepData = useSelector<RootState, RootState['options']['mepPicklist']>(
        state => state.options.mepPicklist
    );
    return mepData;
};

export const useMepSystems = (): string[] => {
    const mepSystem = useSelector<RootState, RootState['options']['mepSystems']>(
        state => state.options.mepSystems
    );
    return mepSystem.sort();
};

export const useMEPList = () => {
    const list = useSelector<RootState, RootState['mep']['mepList']>(state => state.mep.mepList);
    return list;
};

export const useMepSubSystemMap = (): Record<string, string[]> => {
    const mepSubSystemsMap = useSelector<RootState, RootState['options']['mepSubSystemsMap']>(
        state => state.options.mepSubSystemsMap
    );
    return mepSubSystemsMap;
};

export const useMEPListLoaded = () => {
    const loaded = useSelector<RootState, RootState['mep']['loaded']>(state => state.mep.loaded);
    return loaded;
};
export const useMEPListLength = () => {
    const length = useSelector<RootState, RootState['mep']['mepListCount']>(
        state => state.mep.mepListCount
    );
    return length;
};

/*
export const useMEPSchema = () => {
    const schema = useSelector<RootState, RootState['options'][''] >(
        state => state.mep.
    )
    return schema
}
*/

export const useMEPDetailsObj = () => {
    const mepDetails = useSelector<RootState, RootState['mep']['mepDetails']>(
        state => state.mep.mepDetails
    );
    return mepDetails;
};
