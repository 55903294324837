import OCSFullDetails from 'components/template/OCSFullDetails';
import hooks from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { listCityStateOverrides } from 'store/cityStateOverrides/cityStateOverrides.actions';

type Props = RouteComponentProps<{ ocsID: string; edit?: string }>;

const OCSDetails: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const { ocsID, edit } = match.params;
    const editMode = edit === 'edit';

    const ocsDetailsObj = hooks.cityStateOverrides.useOverrideDetailsObj();
    const details = ocsDetailsObj[ocsID]?.cityState || {};

    if (!details.ocs_id) {
        dispatch(listCityStateOverrides());
    }

    return (
        <div className="OCSDetails">
            <h1>City State Override Details</h1>
            {Object.keys(details).length > 3 ? (
                <OCSFullDetails editMode={editMode} details={details} />
            ) : (
                <div>
                    <ClipLoader />
                </div>
            )}
        </div>
    );
};

export default OCSDetails;
