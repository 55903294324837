import AdminSettingsState from './adminSettings.state';
import { AdminSettingsAction } from './adminSettings.actions';
import AdminSettingsActionTypes from './adminSettings.types';

export const defaultState: AdminSettingsState = {
    isMaintenanceModeEnabled: false,
    isTemplateEditEnabled: false,
};

const AdminSettingsReducer = (
    state: AdminSettingsState = defaultState,
    action: AdminSettingsAction
): AdminSettingsState => {
    switch (action.type) {
        case AdminSettingsActionTypes.SET_MAINTENANCE_MODE_ENABLED:
            return { ...state, isMaintenanceModeEnabled: action.payload.isEnabled };
        case AdminSettingsActionTypes.SET_TEMPLATE_EDIT_MODE_ENABLED:
            return { ...state, isTemplateEditEnabled: action.payload.isEnabled };
        default:
            return state;
    }
};

export default AdminSettingsReducer;
