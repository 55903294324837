import MepFullDetails from 'components/template/MepFullDetails';
import React from 'react';
import { MEPListItemI } from 'store/mep/mep.types';

const MEPCreate: React.FC = () => {
    return (
        <div className="mepDetails">
            <h1>MEP Create</h1>

            <MepFullDetails editMode details={{} as MEPListItemI} create />
        </div>
    );
};

export default MEPCreate;
