import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useCurrentEquipmentTemplateState = () =>
    useSelector<RootState, RootState['equipmentTemplate']['current']>(
        state => state.equipmentTemplate.current
    );

export const useEquipmentTemplateListState = () =>
    useSelector<RootState, RootState['equipmentTemplate']['templateList']>(
        state => state.equipmentTemplate.templateList
    );
