import React, { useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useInspectionDetails } from 'hooks/useInspectionHooks';
import { useDispatch } from 'react-redux';
import { getInspectionDetails } from 'store/inspections/inspections.actions';
import { Paper, Breadcrumbs, Typography } from '@material-ui/core';
import InspectionDetails from 'components/molecules/InspectionDetails';

type Props = RouteComponentProps<{ inspectionID: string; edit?: string }>;

const InspectionView: React.FC<Props> = ({ match }) => {
    console.log('in inspection details');

    const { inspectionID, edit } = match.params;
    const dispatch = useDispatch();

    const editMode = edit === 'edit';

    useEffect(() => {
        console.log(`in use effect in inspection Details with instapectionID ${inspectionID}`);
        dispatch(getInspectionDetails(inspectionID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inspectionsDetails = useInspectionDetails();

    const inspection = inspectionsDetails[inspectionID]?.inspection;
    const errorMessage = inspectionsDetails[inspectionID]?.errorMessage;

    return (
        <div className="inspectionDetails">
            <h1>Inspections</h1>

            {!inspection ? (
                <div>{errorMessage}</div>
            ) : (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={`/assets/equipment/${inspection.ins_equ_id}`}>Equipment</Link>

                        <Typography color="textPrimary">
                            {editMode ? 'Edit Inspection' : 'Inspection'}
                        </Typography>
                    </Breadcrumbs>
                    <Paper className="inspectionDetailSummary textInputGroup">
                        <InspectionDetails
                            equipmentID={inspection.ins_equ_id}
                            inspectionDetails={inspection}
                            editMode={editMode}
                        />
                    </Paper>
                </>
            )}
        </div>
    );
};

export default InspectionView;
