import React, { useEffect } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import './main.scss';
import hooks from 'hooks';
import { getAllOptions } from 'store/options/options.actions';
import { useDispatch } from 'react-redux';
import { getMyDetails } from 'store/auth/auth.actions';
import HeaderBar from 'components/molecules/HeaderBar';

import MyRedirect from 'components/atoms/MyRedirect';

import BuildingList from 'views/BuildingList';
import InspectionCreate from 'views/InspectionCreate';
import InspectionDetails from 'views/InspectionDetails';
import BuildingDetails from 'views/BuildingDetails';

import BuildingCreate from 'views/BuildingCreate';
import EquipmentDetails from 'views/EquipmentDetails';
import EquipmentCreate from 'views/EquipmentCreate';

import OMPList from 'views/Overrides/OMP/OMPList';
import EquipmentList from 'views/EquipmentList';
import MEPListView from 'views/MEP/MEPList';
import MEPDetails from 'views/MEP/MEPDetails';
import MEPCreate from 'views/MEP/MEPCreate';
import MEPOverrideDetails from 'views/Overrides/OMP/OMPDetails';
import Dashboard from 'views/Dashboard';
import MEPOverrideCreate from 'views/Overrides/OMP/OMPCreate';
import OCSList from 'views/Overrides/CityState/OCSList';
import OCSCreate from 'views/Overrides/CityState/OCSCreate';
import OCSDetails from 'views/Overrides/CityState/OCSDetails';
import { UsersList } from 'views/UsersList';
import { UsersDetails } from 'views/UsersDetails';
import { UsersRolesList } from 'views/UsersRolesList';
import { UsersRolesDetails } from 'views/UsersRolesDetails';
import AdminSettingsView from 'views/AdminSettings';
import OfflineUploadView from 'views/OfflineUpload';
import { AppFeatures } from 'store/app/app.state';
import ErrorMessage from '../components/atoms/ErrorMessage/ErrorMessage';
import EquipmentTemplatesListView from '../views/EquipmentTemplatesList';
import EquipmentTemplateView from '../views/EquipmentTemplate';

type Props = RouteComponentProps;

const Main: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const redirect = hooks.app.useRouting();
    const appData = hooks.app.useApp();
    const hasFeature = hooks.user.useHasFeature();

    useEffect(() => {
        dispatch(getMyDetails());
        dispatch(getAllOptions());
    }, [dispatch]);

    return (
        <div className="main">
            {redirect ? <MyRedirect to={redirect} /> : ''}

            <ErrorMessage
                hasError={appData.error}
                showDuration={null}
                message={appData.message || 'There has been an error loading data into the app'}
            />

            <HeaderBar />

            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/dashboards" component={Dashboard} />
                <Route exact path="/dashboards/:tier/:tierID" component={Dashboard} />

                <Route exact path="/assets/buildings" component={BuildingList} />

                <Route exact path="/assets/equipment" component={EquipmentList} />
                <Route exact path="/assets/equipment/create" component={EquipmentCreate} />
                <Route exact path="/assets/equipment/:equipmentID" component={EquipmentDetails} />
                <Route
                    exact
                    path="/assets/templates/equipment"
                    component={EquipmentTemplatesListView}
                />
                <Route
                    exact
                    path="/assets/templates/equipment/:category/:system"
                    component={EquipmentTemplateView}
                />

                <Route
                    exact
                    path="/assets/equipment/:equipmentID/create-inspection"
                    component={InspectionCreate}
                />
                <Route
                    exact
                    path="/assets/equipment/:equipmentID/:edit"
                    component={EquipmentDetails}
                />
                <Route
                    exact
                    path="/assets/inspections/:inspectionID"
                    component={InspectionDetails}
                />
                <Route
                    exact
                    path="/assets/inspections/:inspectionID/:edit"
                    component={InspectionDetails}
                />
                <Route exact path="/assets/buildings/create">
                    <BuildingCreate />
                </Route>
                <Route exact path="/assets/buildings/:buildingID/" component={BuildingDetails} />
                <Route
                    exact
                    path="/assets/buildings/:buildingID/:edit"
                    component={BuildingDetails}
                />

                <Route exact path="/users" component={UsersList} />
                <Route exact path="/users/roles" component={UsersRolesList} />
                <Route exact path="/users/roles/:roleID" component={UsersRolesDetails} />
                <Route exact path="/users/:userID" component={UsersDetails} />

                <Route exact path="/admin/mepcosting" component={MEPListView} />
                <Route exact path="/admin/mepcosting/create" component={MEPCreate} />
                <Route exact path="/admin/mepcosting/:mepID" component={MEPDetails} />
                <Route exact path="/admin/mepcosting/:mepID/:edit" component={MEPDetails} />

                <Route exact path="/admin/overrides/city-state" component={OCSList} />
                <Route exact path="/admin/overrides/city-state/create" component={OCSCreate} />
                <Route exact path="/admin/overrides/city-state/:ocsID" component={OCSDetails} />
                <Route
                    exact
                    path="/admin/overrides/city-state/:ocsID/:edit"
                    component={OCSDetails}
                />
                <Route exact path="/admin/settings" component={AdminSettingsView} />

                <Route exact path="/admin/overrides/mep" component={OMPList} />
                <Route exact path="/admin/overrides/mep/create" component={MEPOverrideCreate} />
                <Route exact path="/admin/overrides/mep/:ompID" component={MEPOverrideDetails} />
                <Route
                    exact
                    path="/admin/overrides/mep/:ompID/:edit"
                    component={MEPOverrideDetails}
                />
                {hasFeature(
                    AppFeatures.REPORTING.ID,
                    AppFeatures.REPORTING.subfeatures.bulkInportExport
                ) && (
                    <>
                        <Route exact path="/offline/import" component={OfflineUploadView} />
                    </>
                )}
            </Switch>
        </div>
    );
};

export default withRouter(Main);
