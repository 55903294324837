import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import { TableRow, TableCell, Box, Button } from '@material-ui/core';

import { useRolesData, useRolesLoading } from 'hooks/roles';
import { getRoles } from 'store/roles/roles.actions';
import DescriptionIcon from '@material-ui/icons/Description';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

type Props = RouteComponentProps;

type Data = {
    rol_id: string;
    rol_description: string;
    rol_long_description: string;
    view: string;
};

export const UsersRolesList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const { pagenum } = queryString.parse(location.search);

    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('rol_id');
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const isLoading = useRolesLoading();
    const roles = useRolesData();

    const mappedRoles = roles.map(r => ({
        rol_id: r.rol_id,
        rol_description: r.rol_description,
        rol_long_description: r.rol_long_description,
        view: 'show',
    }));

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    const headCells: HeadCell<Data>[] = [
        { id: 'rol_id', numeric: false, disablePadding: false, label: 'Role' },
        { id: 'rol_description', numeric: false, disablePadding: false, label: 'Description' },
        { id: 'view', numeric: false, disablePadding: false, label: 'View' },
    ];

    const sortedRowData = stableSort<Data>(mappedRoles, getComparator(orderDirection, orderBy));

    const rowData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const rowJSX = (r: Data) => (
        <TableRow key={r.rol_id}>
            <TableCell style={{ borderLeft: 'none' }}>{r.rol_description}</TableCell>
            <TableCell>{r.rol_long_description}</TableCell>
            <TableCell align="center">
                <Button
                    aria-label="View details"
                    className={globalClasses.tableActionButton}
                    component={Link}
                    to={`/users/roles/${r.rol_id}`}
                >
                    <DescriptionIcon />
                </Button>
            </TableCell>
        </TableRow>
    );

    return (
        <Box paddingX="10px">
            <h1>Manage Roles</h1>

            <SortTable<Data>
                rows={rowData}
                headCells={headCells}
                rowJSX={rowJSX}
                totalRowCount={roles.length}
                dense
                loadingList={isLoading}
                page={page}
                setPage={setPage}
                orderDirection={orderDirection}
                orderBy={orderBy}
                onOrderByChange={onOrderByChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={setRowsPerPage}
                rowsPerPageOptions={[20, 50, 100].sort((a: number, b: number) => a - b)}
            />
        </Box>
    );
};
