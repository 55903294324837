import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@material-ui/core';
import hooks from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { appRedirect } from 'store/app/app.actions';
import { createOrUpdateMEPOverride } from 'store/mepOverrides/mepOverrides.actions';
import { MEPOverrideListItemI } from 'store/mepOverrides/mepOverrides.types';

import './OMPFullDetails.scss';

interface Props {
    details: MEPOverrideListItemI;
    editMode: boolean;
    create?: boolean;
}

const OMPFullDetails: React.FC<Props> = ({ details: initialDetails, editMode, create }) => {
    const dispatch = useDispatch();
    const classes = hooks.style.useGlobalStyles();
    const [details, setDetails] = useState(initialDetails as MEPOverrideListItemI);
    const MEPSystems = hooks.mepOverrides.useMepSystems();

    const detailsChanges = (field: string, value: any) => {
        setDetails({ ...details, [field]: value });
    };
    const detailsMultipleChanges = (newData: { [field: string]: any }) => {
        setDetails({ ...details, ...newData });
    };

    useEffect(() => {
        setDetails(initialDetails);
    }, [initialDetails]);

    const submit = () => {
        console.log('details on submit', details);
        dispatch(createOrUpdateMEPOverride(details));
    };

    const cancel = () => {
        setDetails(initialDetails);
        if (create) {
            dispatch(appRedirect(`/admin/mepcosting`));
        } else {
            dispatch(appRedirect(`/admin/overrides/mep/${details.omp_id}`));
        }
    };

    console.log('MEPSystems', MEPSystems);

    return (
        <div className="mepCostingDetails">
            <Paper>
                {create ? null : (
                    <TextField
                        id="omp_id"
                        required
                        label="ID"
                        value={details.omp_id}
                        InputProps={{
                            readOnly: create,
                        }}
                        onChange={e => detailsChanges('omp_id', e.target.value)}
                    />
                )}
                <FormControl className={classes.formControl}>
                    <InputLabel id="omp_mep_system-label">MEP System</InputLabel>
                    <Select
                        labelId="omp_mep_system-label"
                        id="omp_mep_system"
                        value={details.omp_mep_system}
                        readOnly={!editMode}
                        onChange={e => {
                            const mepSystem = e.target.value as string;

                            detailsMultipleChanges({
                                omp_mep_system: mepSystem,
                            });
                        }}
                    >
                        {[...MEPSystems, details.omp_mep_system].map((system, idx) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={`mepsystem${idx}`} value={system}>
                                    {system}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <TextField
                    id="omp_fixed_amount"
                    required
                    label="Fixed Amount"
                    type="number"
                    value={details.omp_fixed_amount}
                    InputProps={{
                        readOnly: !editMode,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onChange={e => detailsChanges('omp_fixed_amount', e.target.value)}
                />
                <TextField
                    id="omp_multiplier"
                    required
                    label="Multiplier"
                    value={Number(details.omp_multiplier) * 100}
                    InputProps={{
                        readOnly: !editMode,
                        inputProps: { min: '0', step: '1' },
                        endAdornment: <InputAdornment position="end"> %</InputAdornment>,
                    }}
                    type="number"
                    onChange={e => detailsChanges('omp_multiplier', Number(e.target.value) / 100)}
                />
                <TextField
                    id="omp_description"
                    label="Description"
                    multiline
                    fullWidth
                    rows={4}
                    value={details.omp_description}
                    InputProps={{
                        readOnly: !editMode,
                    }}
                    onChange={e => detailsChanges('omp_description', e.target.value)}
                />

                {editMode ? (
                    <div className="bottomDetailButtons">
                        <Button variant="contained" color="primary" onClick={submit}>
                            Save
                        </Button>

                        <Button variant="contained" color="secondary" onClick={cancel}>
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="bottomDetailButtons">
                        <Link to={`/admin/overrides/mep/${details.omp_id}/edit`}>
                            <Button variant="contained" color="primary">
                                Edit
                            </Button>
                        </Link>
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default OMPFullDetails;
