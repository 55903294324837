import React from 'react';
import BuildingFullDetails from 'components/molecules/BuildingFullDetails';
import { BuildingI, BuildingCreateOrUpdateI } from 'store/buildings/buildings.types';
import { Box, Paper } from '@material-ui/core';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

const emptyBuilding: BuildingCreateOrUpdateI = {
    bld_sec_id: '',
    bld_name: '',
    bld_glc: '',
    bld_responder_code: '',
    bld_address_line1: '',
    bld_address_line2: '',
    bld_city: '',
    bld_state: '',
    bld_zip: '',
    bld_leased_owned: '',
};

const BuildingCreate: React.FC = () => {
    const globalClasses = useGlobalStyles();
    return (
        <Box margin={1}>
            <h1>Create New Building</h1>

            <Paper className={globalClasses.paper}>
                <BuildingFullDetails details={emptyBuilding as BuildingI} editMode isCreate />
            </Paper>
        </Box>
    );
};

export default BuildingCreate;
