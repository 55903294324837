import { Paper } from '@material-ui/core';
import ImageCarousel from 'components/atoms/ImageCarousel';
import ImageUploader from 'components/atoms/ImageUploader';
import InspectionList from 'components/molecules/InspectionList';
import TabContainer from 'components/molecules/TabContainer';
import hooks from 'hooks';
import React from 'react';
import { AppFeatures } from 'store/app/app.state';
import { EquipmentDetailsI } from 'store/equipment/equipment.state';

interface Props {
    equipmentDetailsObject: EquipmentDetailsI;
    equipmentID: string;
}

const EquipmentDetailsTabs: React.FC<Props> = ({ equipmentDetailsObject, equipmentID }) => {
    const hasFeature = hooks.user.useHasFeature();

    const tabTitles = ['Inspections', 'Images', 'Documents'];

    return (
        <Paper>
            <TabContainer tabTitles={tabTitles}>
                <div className="equipmentInspectionsTab">
                    <InspectionList
                        inspections={equipmentDetailsObject?.inspections || []}
                        equipmentID={equipmentID}
                    />
                </div>
                <div className="equipmentImagesTab">
                    <ImageCarousel images={equipmentDetailsObject?.images || []} />
                    {hasFeature(AppFeatures.EQUIPMENT.ID, 'addImage') ? (
                        <ImageUploader equipmentID={equipmentID} />
                    ) : null}
                </div>
                <div className="equipmentDocumentsTab">Documents</div>
            </TabContainer>
        </Paper>
    );
};

export default EquipmentDetailsTabs;
