import EquipmentsTypes from 'store/equipment/equipment.types';
import DashboardTypes from 'store/dashboard/dashboard.types';
import { DashboardActions } from 'store/dashboard/dashboard.actions';
import { EquipmentsAction } from 'store/equipment/equipment.actions';
import MEPOverrideTypes from 'store/mepOverrides/mepOverrides.types';
import { MEPOverridesAction } from 'store/mepOverrides/mepOverrides.actions';
import AppTypes from './app.types';
import AppState from './app.state';
import { AppAction } from './app.actions';

const defaultState: AppState = {
    error: false,
    message: '',
    loading: false,
    name: '',
    logoURL: `${process.env.PUBLIC_URL}/crew_building_white.png`,
    features: [],
    redirect: '',
};

const AppReducer = (
    state: AppState = defaultState,
    action: AppAction | EquipmentsAction | DashboardActions | MEPOverridesAction
): AppState => {
    switch (action.type) {
        case AppTypes.INIT_APP_LOADING:
            return { ...state, loading: true };
        case AppTypes.INIT_APP_SUCCESS:
            return { ...action.payload, forms: {}, error: false, loading: false };
        case AppTypes.INIT_APP_FAIL:
            return { ...defaultState, ...action.payload, error: true };
        case AppTypes.REDIRECT:
            return { ...state, redirect: action.payload };
        case AppTypes.CLEAR_REDIRECT:
            return { ...state, redirect: '' };
        case EquipmentsTypes.LIST_EQUIPMENT_FAIL:
        case DashboardTypes.GET_DASHBOARD_FAIL:
            return { ...state, error: true, message: action.payload.errorMessage };
        case MEPOverrideTypes.SET_MEP_OVERRIDES_MAP_ERROR:
            return { ...state, error: true, message: action.payload.errorMessage };
        default:
            return state;
    }
};

export default AppReducer;
