import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBuildingList } from 'store/buildings/buildings.actions';
import { RouteComponentProps } from 'react-router-dom';
import NewBuildingList from 'components/template/NewBuildingList';
import { Box, Button } from '@material-ui/core';
import BuildingCardList from 'components/template/BuildingCardList';
import AppsIcon from '@material-ui/icons/Apps';
import ViewListIcon from '@material-ui/icons/ViewList';

type Props = RouteComponentProps;

const BuildingList: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const [viewCards, setViewCards] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getBuildingList());
    }, [dispatch]);

    return (
        <Box mx={1} mb={2}>
            <h1>Building List</h1>
            <div>
                <Button
                    onClick={() => {
                        setViewCards(true);
                    }}
                    color="primary"
                    style={{ margin: '5px' }}
                    variant="contained"
                    startIcon={<AppsIcon style={{ fontSize: '40px' }} />}
                >
                    Card View
                </Button>

                <Button
                    onClick={() => {
                        setViewCards(false);
                    }}
                    color="secondary"
                    variant="contained"
                    startIcon={<ViewListIcon style={{ fontSize: '40px' }} />}
                >
                    Table View
                </Button>
            </div>
            {viewCards ? <BuildingCardList /> : <NewBuildingList />}
        </Box>
    );
};

export default BuildingList;
