enum EquipmentsTypes {
    GET_EQUIPMENT_FAIL = 'GET_EQUIPMENT_FAIL',
    GET_EQUIPMENT_LOADING = 'GET_EQUIPMENT_LOADING',
    GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS',

    GET_EQUIPMENT_DETAILS_SUCCESS = 'GET_EQUIPMENT_DETAILS_SUCCESS',
    GET_EQUIPMENT_DETAILS_FAIL = 'GET_EQUIPMENT_DETAILS_FAIL',

    LIST_EQUIPMENT_LOADING = 'LIST_EQUIPMENT_LOADING',
    LIST_EQUIPMENT_SUCCESS = 'LIST_EQUIPMENT_SUCCESS',
    LIST_EQUIPMENT_FAIL = 'LIST_EQUIPMENT_FAIL',

    CREATE_EQUIPMENTS_LOADING = 'CREATE_EQUIPMENTS_LOADING',
    CREATE_EQUIPMENTS_SUCCESS = 'CREATE_EQUIPMENTS_SUCCESS',
    CREATE_EQUIPMENTS_FAIL = 'CREATE_EQUIPMENTS_FAIL',

    CLEAR_EQUIPMENT_SAVED = 'CLEAR_EQUIPMENT_SAVED',

    UPLOAD_ATTACHMENT_LOADING = 'UPLOAD_ATTACHMENT_LOADING',
    UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS',
    UPLOAD_ATTACHMENT_FAILURE = 'UPLOAD_ATTACHMENT_FAILURE',

    UPDATE_TAGS = 'UPDATE_TAGS',
    UPDATE_MEP = 'UPDATE_MEP',

    UPDATE_OVERRIDE_ERROR = 'UPDATE_OVERRIDE_ERROR',
    CLEAR_OVERRIDE_ERROR = 'CLEAR_OVERRIDE_ERROR',
}

export default EquipmentsTypes;

export interface EquipmentListItemI {
    bld_sec_id: string;
    bld_id: string;
    bld_responder_code: string;
    bld_glc: number;
    bld_name?: any;
    bld_address_line1: string;
    bld_address_line2?: any;
    bld_city: string;
    bld_state: string;
    bld_zip: string;
    bld_date_added?: any;
    bld_sr_portfolio_manager?: any;
    bld_portfolio_manager?: any;
    bld_facility_manager?: any;
    bld_leased_owned: string;
    bld_lease_type?: any;
    bld_document_location_insurance?: any;
    blc_document__location_occupant_roster?: any;
    bld_document_location_lease_abstracts?: any;
    bld_document_location_emergency_contact_list?: any;
    bld_document_location_contract_copies?: any;
    bld_document_location_contractor_listing?: any;
    bld_sf?: any;
    bld_stories_serviced?: any;
    bld_year_build?: any;
    bld_last_renovation_month?: any;
    bld_last_renovation_year?: any;
    bld_active: number;
    bld_tags?: any;
    bld_created_by?: any;
    bld_created_date?: any;
    bld_updated_by?: any;
    bld_updated_date?: any;
    sec_dis_id: string;
    sec_id: string;
    sec_name: string;
    dis_reg_id: string;
    dis_id: string;
    dis_name: string;
    reg_id: string;
    reg_name: string;
    equ_business_id: string;
    equ_bld_id: string;
    equ_id: string;
    equ_alias: string;
    equ_description: string;
    equ_floor: number;
    equ_room: string;
    equ_mfg: string;
    equ_model: string;
    equ_vendor: string;
    equ_specs: string;
    equ_uses_refrigerant: string;
    equ_refrigerant_type: string;
    equ_dr_rate_percent: string;
    equ_ton: string;
    equ_mep_id: string;
    equ_mep_quantity: number;
    equ_system_name?: string;
    equ_system_category?: string;
    equ_active: number;
    equ_in_big?: any;
    equ_tags?: any;
    equ_created_by?: any;
    equ_created_date?: any;
    equ_updated_by?: any;
    equ_updated_date?: any;
    mep_id: string;
    mep_job_id: number;
    mep_category: number;
    mep_system: string;
    mep_description: string;
    mep_type: string;
    mep_capacity: number;
    mep_capacity_measure: string;
    mep_lifecycle: number;
    mep_unit_of_measure: string;
    mep_cost_per_unit: number;
    mep_units: number;
    mep_additional_info?: any;
    mep_created_date?: any;
    mep_created_by?: any;
    mep_updated_date?: any;
    mep_updated_by?: any;
    usr_id: string;
    usr_email: string;
    usr_aws_id: string;
    usr_tier_type: string;
    usr_tier_id: string;
    usr_fname: string;
    usr_lname: string;
    usr_title: string;
    usr_status: string;
    usr_phone?: any;
    usr_created_by?: any;
    usr_created_date: string;
    usr_updated_date?: any;
    usr_updated_by?: any;
    usr_is_system_admin: number;
    usr_is_user_admin: number;
    oem_mep_id: string;
    mep_overrides: string;
    omp_multiplier: number;
    omp_fixed_amount: number;
    ocs_id: string;
    ocs_city: string;
    ocs_state: string;
    ocs_description?: any;
    ocs_multiplier: number;
    ocs_created_by?: any;
    ocs_created_date?: any;
    ocs_updated_by?: any;
    ocs_updated_date?: any;
    ins_id?: any;
    ins_equ_id?: any;
    ins_condition?: any;
    ins_rating?: number;
    ins_notes?: any;
    ins_tags?: any;
    ins_created_by?: any;
    ins_created_date?: any;
    ins_updated_by?: any;
    ins_updated_date?: any;

    mep_multiplier_overrides_value: number;
    omp_fixed_amount__1: number;
    total_mep_overrides: number;
    overrrides_city_state_value: number;

    mep_replacement_unit_cost: number;
    mep_replacement_cost?: number;
    total_replacement_cost: number;
    ept_equ_id: string;
    ept_tag: string[];

    oes_dollars?: number;
    oes_reason?: string;
}

export interface EquipmentI {
    equ_bld_id: string;
    equ_id: string;
    equ_category: string;
    equ_name: string;
    equ_mep_id?: string;
    mep_id?: string;
    mep_system?: string;
    mep_description?: string;
    mep_lifecycle?: number;
    mep_lifecycle_unit?: string;
    mep_cost?: string;
    equ_other?: string;

    ins_id?: string;
    ins_equ_id?: string;
    ins_condition?: string;
    ins_notes?: string;
    ins_tags?: string;
    ins_created_by?: string;
    ins_created_date?: string;
    ins_updated_by?: string;
    ins_updated_date?: string;
}

export interface EquipmentCreateI {
    equ_bld_id: string;
    equ_id: string;
    equ_category: string;
    equ_name: string;
    mep_id?: string;
    equ_alias: string;
    equ_tags?: string[];
}

export interface ImageI {
    img_id: string;
    img_description: string;
    img_orig_aws_bucket: string;
    img_thumbnail_aws_bucket: string;
    img_small_aws_bucket: string;
    img_lg_aws_bucket: string;
    img_entity_id: string;
    img_entity_type: string;
    img_default: number;
    img_created_by: string;
    img_created_date: string;
    img_updated_by: string;
    img_updated_date: string;
    img_srcKey: string;
    img_contentType: string;
}
export interface DocumentI {
    url: string;
    type: string;
}
