import OMPFullDetails from 'components/template/OMPFullDetails';
import hooks from 'hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { getMEPOverrideDetails } from 'store/mepOverrides/mepOverrides.actions';

type Props = RouteComponentProps<{ ompID: string; edit?: string }>;

const MEPOverrideDetails: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();

    const { ompID, edit } = match.params;
    const editMode = edit === 'edit';

    const mepDetailsObj = hooks.mepOverrides.useOverrideDetailsObj();
    const details = mepDetailsObj[ompID]?.mepOverride || {};
    console.log('mep Details', details);

    useEffect(() => {
        dispatch(getMEPOverrideDetails(ompID, true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mepOverrideDetails">
            <h1>MEP Override Details</h1>
            {Object.keys(details).length > 3 ? (
                <OMPFullDetails editMode={editMode} details={details} />
            ) : (
                <div>
                    <ClipLoader />
                </div>
            )}
        </div>
    );
};

export default MEPOverrideDetails;
