import { CityStateAction } from './cityStateOverrides.actions';
import CityStateState from './cityStateOverrides.state';
import CityStateTypes from './cityStateOverrides.types';

const defaultState: CityStateState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    cityStateDetails: {},
    cityStateList: [],
    missingCityStateList: [],
    totalMissingCount: 0,
    cityStateListCount: 0,
};

const cityStatesReducer = (
    state: CityStateState = defaultState,
    action: CityStateAction
): CityStateState => {
    switch (action.type) {
        case CityStateTypes.GET_CITY_STATE_LOADING:
            return { ...state, loading: true, loaded: false };

        case CityStateTypes.LIST_CITY_STATE_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const cityStateDetails = { ...state.cityStateDetails };
            action.payload.records.forEach(record => {
                cityStateDetails[record.ocs_id] = { cityState: record };
            });

            return {
                ...state,
                cityStateDetails,
                cityStateList: [...action.payload.records],
                loaded: true,
                error: false,
                message: '',
                cityStateListCount:
                    typeof action.payload?.totalRecordCount === 'number'
                        ? action.payload.totalRecordCount
                        : state.cityStateListCount,
            };
        case CityStateTypes.MISSING_CITY_STATE_SUCCESS:
            return {
                ...state,
                missingCityStateList: action.payload.records,
                totalMissingCount: action.payload.totalMissingCount,
            };

        default:
            return state;
    }
};

export default cityStatesReducer;
