import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useMepData = () => {
    const mepData = useSelector<RootState, RootState['options']['mepPicklist']>(
        state => state.options.mepPicklist
    );
    return mepData;
};

export const useMepSystems = (): string[] => {
    const mepSystem = useSelector<RootState, RootState['options']['mepSystems']>(
        state => state.options.mepSystems
    );
    return mepSystem;
};

export const useOverrideList = () => {
    const list = useSelector<RootState, RootState['mepOverrides']['mepOverridesList']>(
        state => state.mepOverrides.mepOverridesList
    );
    return list;
};
export const useOverrideListLoaded = () => {
    const loaded = useSelector<RootState, RootState['mepOverrides']['loaded']>(
        state => state.mepOverrides.loaded
    );
    return loaded;
};
export const useOverrideListLength = () => {
    const length = useSelector<RootState, RootState['mepOverrides']['mepOverridesListCount']>(
        state => state.mepOverrides.mepOverridesListCount
    );
    return length;
};

/*
export const useMEPSchema = () => {
    const schema = useSelector<RootState, RootState['options'][''] >(
        state => state.mep.
    )
    return schema
}
*/

export const useOverrideDetailsObj = () => {
    const overrideDetails = useSelector<
        RootState,
        RootState['mepOverrides']['mepOverridesDetails']
    >(state => state.mepOverrides.mepOverridesDetails);
    return overrideDetails;
};

export const useMepSystemOverrides = (mepSystem: string) => {
    const overrides = useSelector<RootState, RootState['mepOverrides']['mepSystemOverrides']>(
        state => state.mepOverrides.mepSystemOverrides
    );
    if (!mepSystem) {
        return undefined;
    }
    return overrides[mepSystem];
};
