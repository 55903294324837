import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Paper,
    Switch,
    Theme,
    Typography,
} from '@material-ui/core';

import hooks from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { Link } from 'react-router-dom';
import AdminSettingsActionTypes from '../../store/adminSettings/adminSettings.types';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingsItemHeader: {
            marginTop: 0,
            marginBottom: theme.spacing(1),
        },
        settingsItemBody: {
            marginBottom: theme.spacing(0.5),
        },
    })
);

const AdminSettingsView: React.FC = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { isMaintenanceModeEnabled, isTemplateEditEnabled } =
        hooks.adminSettings.useAdminSettingsState();

    const toggleMaintenanceMode = event => {
        dispatch(actions.adminSettings.setIsMaintenanceModeEnabled(event.target.checked));
    };

    const setIsTemplateEditEnabled = event => {
        dispatch({
            type: AdminSettingsActionTypes.SET_TEMPLATE_EDIT_MODE_ENABLED,
            payload: { isEnabled: event.target.checked },
        });
    };

    return (
        <Box mx={1}>
            <h1>Administration Settings</h1>
            <Paper className={globalClasses.paper}>
                <h2 className={classes.settingsItemHeader}>Maintenance Mode</h2>
                <Typography className={classes.settingsItemBody} variant="body1">
                    In this mode, you&apos;ll be able to see equipment that is in staging or
                    retired. This equipment and information will only be viewable whilst in this
                    mode.
                </Typography>
                <Box display="flex" alignItems="center">
                    <Typography>Off</Typography>
                    <Switch
                        checked={isMaintenanceModeEnabled}
                        onChange={toggleMaintenanceMode}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>On</Typography>
                </Box>
            </Paper>
            <Paper className={globalClasses.paper}>
                <h2 className={classes.settingsItemHeader}>Template Edit Mode</h2>
                <Typography className={classes.settingsItemBody} variant="body1">
                    Enable this mode to define which fields are displayed on the details view for a
                    given Category / System. Disable this mode to switch back to creating and
                    editing equipment.
                </Typography>
                <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Off</Typography>
                    <Switch
                        checked={isTemplateEditEnabled}
                        onChange={setIsTemplateEditEnabled}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>On</Typography>
                </Box>
                <Button
                    className={globalClasses.button}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/assets/templates/equipment"
                >
                    Templates List
                </Button>
            </Paper>
        </Box>
    );
};

export default AdminSettingsView;
