import { ThunkDispatch } from 'redux-thunk';
import { TableFilterSchema } from 'components/organisms/EnhancedTableFilter/schemas';
import tipTranslations from 'store/equipment/tooltipTranslations';
import { RootState } from 'store/state';
import { crewAPI } from 'utils/API';
import OptionsTypes, { MEPItem, RegionOptions } from './options.types';

interface OptionsError {
    message: string;
}

interface GetOptionsPayloadI {
    mepSystems?: string[];
    mepPicklist?: { [key: string]: MEPItem };
    tags?: string[];
    equipmentTableFilterOptions?: TableFilterSchema[];
    buildingTableFilterOptions?: TableFilterSchema[];
    regionOptions?: RegionOptions;
    equipmentSystemCategories?: Record<string, string[]>;
}

export type OptionsAction =
    | { type: typeof OptionsTypes.GET_OPTIONS_FAIL; payload: OptionsError }
    | { type: typeof OptionsTypes.GET_OPTIONS_LOADING }
    | { type: typeof OptionsTypes.GET_OPTIONS_SUCCESS; payload: GetOptionsPayloadI }
    | {
          type: typeof OptionsTypes.GET_SUBSYSTEM_SUCCESS;
          payload: { system: string; subsystems: string[] };
      }
    | { type: typeof OptionsTypes.ADD_TAG_OPTIONS; payload: { tags: string[] } };

const toolTipTranslations = tipTranslations();

/* const createRegionOptions = (
    buildingFilterOptions: { COLUMN_NAME: string; OPTIONS: string[] }[]
) => {
    const allRegions =
        buildingFilterOptions.find(field => field.COLUMN_NAME === 'reg_id')?.OPTIONS || [];
    const allDistricts =
        buildingFilterOptions.find(field => field.COLUMN_NAME === 'dis_id')?.OPTIONS || [];
    const allSectors =
        buildingFilterOptions.find(field => field.COLUMN_NAME === 'sec_id')?.OPTIONS || [];

    const regionOptions = allRegions.map(region => ({
        id: region,
        districts: allDistricts
            .filter(option => option.substring(0, option.search(/\d/)) === region)
            .map(district => ({
                id: district,
                sectors: allSectors
                    .filter(option => option.split('-')[0] === district)
                    .map(sector => ({ id: sector })),
            })),
    }));
    return regionOptions;
}; */

const parseFilterOptions = (filterResponse: { [key: string]: (string | null)[] }) => {
    return Object.entries(filterResponse).map(([key, values]) => {
        return {
            COLUMN_NAME: key,
            COLUMN_LABEL: toolTipTranslations[key] || key.slice(4).split('_').join(' '),
            COLUMN_TYPE: 'option',
            IS_NULLABLE: values.includes(null),
            OPTIONS: values.filter(value => typeof value === 'string') as string[],
        };
    });
};

export const getAllOptions = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, OptionsAction>) => {
        dispatch({
            type: OptionsTypes.GET_OPTIONS_LOADING,
        });

        const clearDashboardPromise = crewAPI
            .post({
                path: 'dashboards/cache/clear',
                data: {},
            })
            .catch(error => {
                console.error('getAllOptions: error clearing dashboard cache.', error);
            });

        // Hit all the APIs to get the stuff.
        const payload: GetOptionsPayloadI = {
            equipmentSystemCategories: defaultEquipmentSystemCategories,
        };

        const picklistPromise = crewAPI
            .get<MEPItem[]>({ path: 'mepcosting/picklist' })
            .then(async res => {
                payload.mepPicklist = res.reduce(
                    (list, item) => ({ ...list, [item.mep_id]: item }),
                    {}
                );
                const systems = res
                    .map(item => item.mep_system)
                    .reduce((list, system) => {
                        return list.includes(system) ? list : [...list, system];
                    }, [] as string[]);

                payload.mepSystems = [...(payload.mepSystems || []), ...systems];
            })
            .catch(error => {
                console.error('getAllOptions:', error);
                throw new Error('Error fetching mep systems');
            });

        const equipmentListFilterPromise = crewAPI
            .get<{ [key: string]: (string | null)[] }>({ path: 'equipment/pagefiltersetup' })
            .then(response => {
                const options = parseFilterOptions(response);
                payload.equipmentTableFilterOptions = options;
                const tags = (response.ept_tag?.filter(tag => tag !== null) || []) as string[];
                payload.tags = tags;
            })
            .catch(error => {
                console.error('getAllOptions:', error);
                throw new Error('Error fetching equipment filters');
            });

        const buildingFilterPromise = crewAPI
            .get<{ [key: string]: (string | null)[] }>({ path: 'buildings/pagefiltersetup' })
            .then(async response => {
                const options = parseFilterOptions(response);
                payload.buildingTableFilterOptions = options;
                // payload.regionOptions = createRegionOptions(options);
            })
            .catch(error => {
                console.error('getAllOptions:', error);
                throw new Error('Error fetching building filters');
            });

        const regionDistrictSectorPromise = crewAPI
            .get<{
                regions: Record<
                    string,
                    {
                        id: string;
                        name: string;
                        districts: Record<
                            string,
                            {
                                id: string;
                                name: string;
                                sectors: Record<string, { id: string; name: string }>;
                            }
                        >;
                    }
                >;
            }>({ path: 'regions-districts-sectors' })
            .then(response => {
                payload.regionOptions = Object.values(response.regions).map(region => {
                    return {
                        ...region,
                        districts: Object.values(region.districts).map(district => {
                            return {
                                ...district,
                                sectors: Object.values(district.sectors).sort((a, b) => {
                                    return Number(a.id.split('-')[1]) - Number(b.id.split('-')[1]);
                                }),
                            };
                        }),
                    };
                });
            });

        const equipmentSystemCategoriesPromise = crewAPI
            .get<{ ecs_category: string; ecs_system: string }[]>({ path: 'ecs' })
            .then(res => {
                const mergedObj: Record<string, string[]> = {};
                res.forEach(({ ecs_category, ecs_system }) => {
                    mergedObj[ecs_category] = mergedObj[ecs_category] || [];
                    mergedObj[ecs_category].push(ecs_system);
                });
                Object.entries(mergedObj).forEach(([key, array]) => {
                    mergedObj[key] = array.sort();
                });
                payload.equipmentSystemCategories = mergedObj;
            })
            .catch(error => {
                console.error('getAllOptions:', error);
                throw new Error('Error fetching equipment system categories');
            });

        try {
            await Promise.all([
                clearDashboardPromise,
                picklistPromise,
                equipmentListFilterPromise,
                buildingFilterPromise,
                regionDistrictSectorPromise,
                equipmentSystemCategoriesPromise,
            ]);

            dispatch({
                type: OptionsTypes.GET_OPTIONS_SUCCESS,
                payload,
            });
        } catch (error: any) {
            dispatch({
                type: OptionsTypes.GET_OPTIONS_FAIL,
                payload: {
                    message: error.message,
                },
            });
        }
    };
};

export const getMepPicklistSubSystems = (system: string) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, OptionsAction>,
        getState: () => RootState
    ) => {
        const state = getState();
        if (state.options.mepSubSystemsMap[system]) {
            return;
        }

        const subsystemMap = await crewAPI.get<{ mep_type: string }[]>({
            path: `mepcosting/picklist/${system}`,
        });
        const allSubsystems = subsystemMap.map(item => item.mep_type);

        const subsystems = [...new Set(allSubsystems)];

        dispatch({
            type: OptionsTypes.GET_SUBSYSTEM_SUCCESS,
            payload: {
                system,
                subsystems,
            },
        });
    };
};

const defaultEquipmentSystemCategories = {
    ELECTRIC: [
        'AUTOMATIC TRANSFER SWITCH',
        'BATTERY SYSTEM',
        'ELECTRIC HEATER',
        'ELECTRICAL PANEL',
        'EXHAUST FAN',
        'GENERATOR',
        'Generator Fuel Tank',
        'TRANSFER SWITCH',
        'UNINTERUPTIBLE POWER SOURCE',
        'VFD',
        'WATER TANK',
    ],
    'ELEVATOR/ESCALATORS': ['ELEVATOR'],
    ENVELOPE: ['BOILER', 'FIRE ALARM', 'FIRE SUPPRESSION SYSTEM', 'ROOF', 'SPRINKLERS'],
    FLS: [],
    HVAC: [
        'AIR CONDITIONING UNIT',
        'AIR HANDLER UNIT',
        'BMS',
        'BOILER',
        'CHILLED WATER PUMP',
        'CHILLER',
        'CIRCULATION PUMP',
        'COMPUTER ROOM AIR CONDITIONER',
        'CONDENSER WATER PUMP',
        'CONDENSOR PUMP',
        'CONDENSOR UNIT',
        'COOLING TOWER',
        'ELECTRIC HEATER',
        'EXHAUST FAN',
        'FAN COIL UNIT',
        'FIRE ALARM',
        'FURNACES-HOT AIR-GAS',
        'Heat Exchanger',
        'HOT WATER HEATER',
        'PNEUMATIC',
        'RADIATOR',
        'ROOF TOP UNIT',
        'SPLIT SYSTEM',
        'SUMP PUMP',
        'VAV',
        'VFD',
        'WATER DETECTION',
        'Water Treatment',
        'WINDOWS',
    ],
    OTHER: [
        'ALARM PANEL',
        'BMS',
        'CIRCULATION PUMP',
        'ELECTRIC HEATER',
        'ELECTRICAL PANEL',
        'ELEVATOR',
        'EXHAUST FAN',
        'FIRE ALARM',
        'FIRE PANEL',
        'FIRE SUPPRESSION SYSTEM',
        'ICE MACHINES',
        'Kitchen Equipment',
        'PNEUMATIC',
        'ROOF',
        'SPRINKLERS',
        'UNITERUPTIBLE POWER SOURCE',
        'WATER DETECTION',
        'WATER TANK',
    ],
    PLUMBING: [
        'BACK FLOW PREVENTER',
        'BOILER',
        'BOOSTER PUMP',
        'CHILLED WATER PUMP',
        'CIRCULATION PUMP',
        'CONDENSOR PUMP',
        'CONDENSOR UNIT',
        'FIRE SUPPRESSION SYSTEM',
        'Fuel Pump',
        'HOT WATER HEATER',
        'ICE MACHINES',
        'Jockey Pump',
        'PUMP CIRCULATION',
        'Storm Water Management',
        'SUMP PUMP',
        'Water Treatment',
    ],
};
