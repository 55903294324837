import React, { ReactChild } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from 'components/atoms/TabPannel';
import { Divider } from '@material-ui/core';
import hooks from 'hooks';

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface Props {
    children: ReactChild[];
    tabTitles: string[];
}

const TabContainer: React.FC<Props> = ({ children, tabTitles }) => {
    const classes = hooks.style.useGlobalStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            {children.length !== tabTitles.length ? (
                'Tab Titles and Children need to be the same length for the tabs to work'
            ) : (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {tabTitles.map((title, idx) => (
                            <Tab key={title} label={title} {...a11yProps(idx)} />
                        ))}
                    </Tabs>

                    <Divider />

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        {children.map((child, idx) => {
                            return (
                                <TabPanel
                                    value={value}
                                    index={idx}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`tabPannel${idx}`}
                                    dir={theme.direction}
                                >
                                    {child}
                                </TabPanel>
                            );
                        })}
                    </SwipeableViews>
                </>
            )}
        </div>
    );
};

export default TabContainer;
