import { Storage } from 'aws-amplify';
import { RootState } from 'store/state';

import { v4 as uuid } from 'uuid';
import { crewAPI } from './API';

export const uploadImage = async ({
    file,
    equipmentID,
    buildingID,
}: {
    appDetails: RootState['app'];
    file: File;
    equipmentID?: string;
    buildingID?: string;
}) => {
    const randomFileName = `${uuid()}.${file.name.split('.')[file.name.split('.').length - 1]}`;
    const { key } = (await Storage.put(randomFileName, file, {
        contentType: file.type,
    })) as { key: string };

    const path = equipmentID
        ? `/images/equipment/${equipmentID}`
        : `/images/building/${buildingID}`;
    const body = {
        key,
        contentType: file.type,
    };

    return crewAPI.post({ path, data: body });
};

export const uploadImages = async ({
    files,
    equipmentID,
    buildingID,
}: {
    appDetails: RootState['app'];
    files: File[];
    equipmentID?: string;
    buildingID?: string;
}) => {
    await Promise.all(
        files.map(async file => {
            const randomFileName = `${uuid()}.${
                file.name.split('.')[file.name.split('.').length - 1]
            }`;
            console.log({ randomFileName });
            const { key } = (await Storage.put(randomFileName, file, {
                contentType: file.type,
            })) as { key: string };

            const path = equipmentID
                ? `/images/equipment/${equipmentID}`
                : `/images/building/${buildingID}`;
            const body = {
                key,
                contentType: file.type,
            };

            return crewAPI.post({ path, data: body });
        })
    );
};
