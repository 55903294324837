import hooks from 'hooks';
import React, { useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import './nav-item.scss';

interface Props extends RouteComponentProps {
    title: string;
    links: LinkProps[];
    icon: any;
    rootLink?: string;
}

export interface LinkProps {
    title: string;
    path: string;
    feature: string;
    subfeatures?: string[];
    external?: boolean;
}

const NavItem: React.FC<Props> = ({ title, links, icon, location, rootLink }) => {
    const [showLinks, setShowLinks] = useState<boolean>(false);
    const hasFeature = hooks.user.useHasFeature();

    const showNavLinks = () => {
        setShowLinks(true);
    };
    const hideNavLinks = () => {
        setShowLinks(false);
    };

    return (
        <div className="nav-element" onMouseEnter={showNavLinks} onMouseLeave={hideNavLinks}>
            {rootLink ? (
                <Link to={rootLink}>
                    <div className="icon-container">{icon}</div>
                </Link>
            ) : (
                <div className="icon-container">{icon}</div>
            )}

            {!showLinks ? null : (
                <div className="links-container">
                    {rootLink ? (
                        <Link to={rootLink}>
                            <h1>{title}</h1>
                        </Link>
                    ) : (
                        <h1>{title}</h1>
                    )}
                    <ul className="links">
                        {links.map((link, i) => {
                            if (
                                link.subfeatures
                                    ? !hasFeature(link.feature, link.subfeatures)
                                    : !hasFeature(link.feature)
                            ) {
                                return null;
                            }
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={i}>
                                    {link.external ? (
                                        <a
                                            /* onFocus={onLinkFocus}
                                                onBlur={() => {
                                                    if (i === links.length - 1) {
                                                        setChildLinkFocused(false);
                                                        hideNavLinks();
                                                    }
                                                }} */
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={link.path}
                                            className="child-link"
                                        >
                                            {link.title}
                                        </a>
                                    ) : (
                                        <Link
                                            /* onFocus={onLinkFocus}
                                                    onBlur={() => {
                                                        if (i === links.length - 1) {
                                                            setChildLinkFocused(false);
                                                            hideNavLinks();
                                                        }
                                                    }}
                                                    */
                                            to={{ pathname: link.path, search: location.search }}
                                            className="child-link"
                                        >
                                            {link.title}
                                        </Link>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default withRouter(NavItem);
