import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/state';
import AppTypes from './app.types';

interface AppError {
    message: string;
}

export type AppAction =
    | { type: typeof AppTypes.INIT_APP_FAIL; payload: AppError }
    | { type: typeof AppTypes.INIT_APP_LOADING }
    | { type: typeof AppTypes.INIT_APP_SUCCESS; payload: any }
    | { type: typeof AppTypes.REDIRECT; payload: string }
    | { type: typeof AppTypes.CLEAR_REDIRECT };

export const appRedirect = (redirectURl: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AppAction>) => {
        dispatch({
            type: AppTypes.REDIRECT,
            payload: redirectURl,
        });
    };
};
export const clearAppRedirect = () => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AppAction>) => {
        dispatch({
            type: AppTypes.CLEAR_REDIRECT,
        });
    };
};
