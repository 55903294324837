import React, { useState } from 'react';
import { IconButton, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import './EnhancedTableFilter.scss';
import MultiSelect from 'components/atoms/MultiSelect';

import MapSelect from 'components/atoms/MapSelect';
import operationOptions, { Operation } from './operationOptions';

export interface EnhancedTableFilterProps {
    schema: Field[];
    filters: EnhancedTableFilterI[];
    onFiltersChange: (filters: EnhancedTableFilterI[]) => void;
}
export interface EnhancedTableFilterI {
    field: Field;
    operation: Operation;
    value: (string | number)[];
}
export interface Field {
    COLUMN_NAME: string;
    COLUMN_TYPE: string;
    COLUMN_LABEL?: string;
    IS_NULLABLE: boolean;
    COLUMN_KEY?: string;
    EXTRA?: string;
    OPTIONS?: string[];
    MAP?: Record<string, any>;
}

const EnhancedTableFilter: React.FC<EnhancedTableFilterProps> = ({
    schema,
    filters,
    onFiltersChange,
}) => {
    const [showNewFilter, setShowNewFilter] = useState(false);

    const saveNewFilter = (newFilter: EnhancedTableFilterI) => {
        console.log('saveNewFilter in tableFilters', newFilter);

        onFiltersChange([...filters, newFilter]);
    };

    const removeFilter = (filterIdxToRemove: number) => {
        const temp = [...filters];
        temp.splice(filterIdxToRemove, 1);
        onFiltersChange(temp);
    };

    return (
        <div className="enhancedTableFilter">
            <h2>Filters</h2>
            {filters.map((filter, idx) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="filterRow" key={`filterRow${idx}`}>
                        <TextField
                            value={filter.field.COLUMN_LABEL || filter.field.COLUMN_NAME}
                            label="Field"
                            variant="outlined"
                            disabled
                        />
                        <TextField
                            value={filter.operation.label}
                            label="Operation"
                            variant="outlined"
                            disabled
                        />
                        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                        <RenderInputs newFilter={filter} setNewFilter={() => {}} disabled />
                        <IconButton
                            onClick={() => {
                                removeFilter(idx);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                );
            })}
            {showNewFilter ? (
                <AddFilter
                    schema={schema}
                    onSaveFilter={saveNewFilter}
                    onCancelFilter={() => setShowNewFilter(!showNewFilter)}
                />
            ) : (
                <IconButton
                    aria-label="add new filter"
                    onClick={() => {
                        setShowNewFilter(!showNewFilter);
                    }}
                >
                    <AddIcon />
                </IconButton>
            )}
        </div>
    );
};

export default EnhancedTableFilter;

interface AddFilterProps {
    schema: EnhancedTableFilterProps['schema'];
    onSaveFilter: (newFilter: EnhancedTableFilterI) => void;
    onCancelFilter: () => void;
}

const AddFilter = ({ schema, onSaveFilter }: AddFilterProps) => {
    const [newFilter, setNewFilter] = useState<{
        field: Field | null;
        operation: Operation | null;
        value: { [key: number]: string | number } | string | number | string[];
    }>({
        field: null,
        operation: null,
        value: [],
    });
    const [tempFilter, setTempFilter] = useState({
        field: '',
        operation: '',
        value: [''],
    });

    const sortedSchema = schema.sort((a, b) => {
        const identifierA = (a.COLUMN_LABEL || a.COLUMN_NAME).toLowerCase();
        const identifierB = (b.COLUMN_LABEL || b.COLUMN_NAME).toLowerCase();

        if (identifierA < identifierB) {
            return -1;
        }
        if (identifierA > identifierB) {
            return 1;
        }

        return 0;
    });

    const calculateOperationOptions = (fieldType: string): Operation[] => {
        console.log(`fieldType.split('(')[0]`, fieldType.split('(')[0]);
        return operationOptions[fieldType.split('(')[0]] || operationOptions.varchar;
    };

    const savable = () => {
        const numberOfValues = newFilter?.operation?.inputs.number;
        switch (numberOfValues) {
            case 0:
                return true;
            case 1:
                return typeof newFilter.value[0] !== 'undefined';
            case 2:
                return (
                    typeof newFilter.value[0] !== 'undefined' &&
                    typeof newFilter.value[1] !== 'undefined'
                );
        }
    };

    return (
        <div className="filterAddition">
            <Autocomplete<Field>
                options={sortedSchema}
                getOptionLabel={option => option.COLUMN_LABEL || option.COLUMN_NAME}
                value={newFilter.field}
                onChange={(event: any, newValue: Field | null) => {
                    return setNewFilter({
                        field: newValue,
                        operation: null,
                        value: [],
                    });
                }}
                inputValue={tempFilter.field}
                onInputChange={(event, newInputValue) => {
                    setTempFilter({ ...tempFilter, field: newInputValue });
                }}
                style={{ width: 300 }}
                renderInput={params => <TextField {...params} label="Field" variant="outlined" />}
            />
            {newFilter.field ? (
                <Autocomplete
                    options={calculateOperationOptions(newFilter.field.COLUMN_TYPE)}
                    getOptionLabel={option => option.label}
                    style={{ width: 300 }}
                    renderInput={params => (
                        <TextField {...params} label="Operation" variant="outlined" />
                    )}
                    value={newFilter.operation}
                    onChange={(event: any, newValue: Operation | null) => {
                        return setNewFilter({
                            ...newFilter,
                            operation: newValue,
                        });
                    }}
                    inputValue={tempFilter.operation}
                    onInputChange={(event, newInputValue) => {
                        setTempFilter({ ...tempFilter, operation: newInputValue });
                    }}
                />
            ) : null}
            {newFilter.operation ? (
                <div className="valueInputs">
                    <RenderInputs
                        newFilter={newFilter as EnhancedTableFilterI}
                        setNewFilter={setNewFilter}
                    />
                </div>
            ) : null}
            {newFilter.operation && savable() ? (
                <Button
                    onClick={() => {
                        onSaveFilter({
                            field: newFilter.field!,
                            operation: newFilter.operation!,
                            value: newFilter.value as (string | number)[],
                        });
                        setNewFilter({
                            field: null,
                            operation: null,
                            value: [],
                        });
                    }}
                >
                    Save
                </Button>
            ) : null}
        </div>
    );
};

interface RenderInputProps {
    newFilter: {
        field: Field;
        operation: Operation;
        value: { [key: number]: string | number } | string | string[];
    };
    setNewFilter: (updatedFilter: {
        field: Field;
        operation: Operation;
        value: { [key: number]: string | number } | string | number | string[];
    }) => void;
    disabled?: boolean;
}

const RenderInputs = ({ newFilter, setNewFilter, disabled }: RenderInputProps) => {
    const setMultiSelectValues = (values: string[]) => {
        setNewFilter({ ...newFilter, value: values });
    };

    const setSinglSelectValue = (value: string | number) => {
        setNewFilter({ ...newFilter, value: [value] });
    };

    switch (newFilter.operation.inputs.format) {
        case 'options':
            console.log('newFilter', newFilter.field);
            return (
                <MultiSelect
                    onMultiSelectChange={setMultiSelectValues}
                    values={newFilter.value as string[]}
                    label="Options"
                    options={newFilter.field.OPTIONS as string[]}
                    disabled={disabled}
                />
            );
        case 'map':
            return (
                <MapSelect
                    onChange={setSinglSelectValue}
                    value={newFilter.value as string}
                    label="Options"
                    optionsMap={newFilter.field.MAP!}
                    disabled={disabled}
                />
            );
        default:
            return (
                <>
                    {[...Array(newFilter.operation.inputs.number)].map((n, i) => (
                        <TextField
                            // eslint-disable-next-line react/no-array-index-key
                            key={`filterInput${i}`}
                            label={
                                newFilter?.operation?.inputs.number === 1
                                    ? 'Value'
                                    : ['From', 'To'][i]
                            }
                            value={newFilter.value[i]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const updatedFilter = { ...newFilter };
                                (updatedFilter.value as string[])[i] = event.target.value;
                                setNewFilter(updatedFilter);
                            }}
                            variant="outlined"
                            disabled={disabled}
                        />
                    ))}
                </>
            );
    }
};
