import CityStateList from 'components/template/OCSList';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

const OCSList: React.FC<Props> = ({ location }) => {
    return (
        <div className="cityStateOverrides">
            <h1>City State Overrides</h1>
            <CityStateList location={location} />
        </div>
    );
};

export default OCSList;
