enum MEPTypes {
    GET_MEP_FAIL = 'GET_MEP_FAIL',
    GET_MEP_LOADING = 'GET_MEP_LOADING',
    GET_MEP_SUCCESS = 'GET_MEP_SUCCESS',

    GET_MEP_DETAILS_SUCCESS = 'GET_MEP_DETAILS_SUCCESS',
    GET_MEP_DETAILS_FAIL = 'GET_MEP_DETAILS_FAIL',

    LIST_MEP_SUCCESS = 'LIST_MEP_SUCCESS',
    LIST_MEP_FAIL = 'LIST_MEP_FAIL',

    CREATE_MEP_LOADING = 'CREATE_MEP_LOADING',
    CREATE_MEP_SUCCESS = 'CREATE_MEP_SUCCESS',
    CREATE_MEP_FAIL = 'CREATE_MEP_FAIL',
}

export default MEPTypes;

export interface MEPListItemI {
    mep_id: string;
    mep_job_id: number;
    mep_category: string;
    mep_system: string;
    mep_description: string;
    mep_type: string;
    mep_capacity: string;
    mep_capacity_measure: string;
    mep_lifecycle: number;
    mep_unit_of_measure: string;
    mep_cost_per_unit: string;
    mep_units: number;
    mep_created_date: string;
    mep_created_by: string;
    mep_updated_date: string;
    mep_updated_by: string;
    override_details: string;
    total_multiplier: string;
    total_fixed_amount: string;
    number_overrides?: number;
}

export interface MEPI {
    mep_id: string;
    mep_job_id: number;
    mep_category: string;
    mep_system: string;
    mep_description: string;
    mep_type: string;
    mep_capacity: string;
    mep_capacity_measure: string;
    mep_lifecycle: number;
    mep_unit_of_measure: string;
    mep_cost_per_unit: string;
    mep_units: number;
    mep_created_date: string;
    mep_created_by: string;
    mep_updated_date: string;
    mep_updated_by: string;
}

export type MEPCreateI = MEPI;
