import React, { useEffect, useState } from 'react';
import './App.css';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainBase from 'views/MainBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from './store/createStore';

const persistedState = localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state') || '')
    : {};

export const { store, persistor } = configureStore(persistedState);

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CssBaseline />
                <BrowserRouter>
                    <MainBase />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

const AppWithAuth = () => {
    const [showApp, setShowApp] = useState<boolean>(false);

    useEffect(() => {
        return onAuthUIStateChange(newAuthState => {
            console.log('newAuthState  ', newAuthState);
            console.log(newAuthState === AuthState.SignedIn);
            switch (newAuthState) {
                case AuthState.SignedIn:
                    setShowApp(true);
                    break;
                default:
                    setShowApp(false);
            }
        });
    }, []);

    return (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp />
            {showApp ? <App /> : null}
        </AmplifyAuthenticator>
    );
};

export default AppWithAuth;
