import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getUsers } from 'store/users/users.actions';
import queryString from 'query-string';
import SortTable, {
    getComparator,
    HeadCell,
    OrderDirection,
    stableSort,
} from 'components/organisms/SortTable';
import { TableRow, TableCell, Box, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useUsersLoading, useUsersData } from 'hooks/users';
import { useGlobalStyles } from '../../hooks/useGlobalStyles';

type Props = RouteComponentProps;

type Data = {
    usr_id: string;
    usr_fname: string;
    usr_lname: string;
    usr_email: string;
    view: string;
};

export const UsersList: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles();

    const { pagenum } = queryString.parse(location.search);

    const [page, setPage] = React.useState(Number(pagenum) || 0);
    const [orderDirection, setOrderDirection] = React.useState<OrderDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('usr_fname');
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const isLoading = useUsersLoading();
    const users = useUsersData();
    const mappedUsers = users.map(u => ({
        usr_id: u.usr_id,
        usr_fname: u.usr_fname,
        usr_lname: u.usr_lname,
        usr_email: u.usr_email,
        view: 'show',
    }));

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    const onOrderByChange = (orderByKey: keyof Data, direction: OrderDirection) => {
        setOrderDirection(direction);
        setOrderBy(orderByKey);
    };

    const headCells: HeadCell<Data>[] = [
        { id: 'usr_fname', numeric: false, disablePadding: false, label: 'First Name' },
        { id: 'usr_lname', numeric: false, disablePadding: false, label: 'Last Name' },
        { id: 'usr_email', numeric: true, disablePadding: false, label: 'Email' },
        { id: 'view', numeric: false, disablePadding: false, label: 'View' },
    ];

    const sortedRowData = stableSort<Data>(mappedUsers, getComparator(orderDirection, orderBy));

    const rowData = sortedRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const rowJSX = (u: Data) => (
        <TableRow key={u.usr_id}>
            <TableCell style={{ borderLeft: 'none' }}>{u.usr_fname}</TableCell>
            <TableCell>{u.usr_lname}</TableCell>
            <TableCell>{u.usr_email}</TableCell>
            <TableCell align="center">
                <Button
                    aria-label="View details"
                    className={globalClasses.tableActionButton}
                    component={Link}
                    to={`/users/${u.usr_id}`}
                >
                    <DescriptionIcon />
                </Button>
            </TableCell>
        </TableRow>
    );

    return (
        <Box paddingX="10px">
            <h1>User Management</h1>

            <SortTable<Data>
                rows={rowData}
                headCells={headCells}
                rowJSX={rowJSX}
                totalRowCount={users.length}
                dense
                loadingList={isLoading}
                page={page}
                setPage={setPage}
                orderDirection={orderDirection}
                orderBy={orderBy}
                onOrderByChange={onOrderByChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={setRowsPerPage}
                rowsPerPageOptions={[20, 50, 100].sort((a: number, b: number) => a - b)}
            />
        </Box>
    );
};
