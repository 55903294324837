import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useEquipmentDetails = (equipmentID: string) => {
    const details = useSelector<RootState, RootState['equipment']['equipmentDetails'][0]>(
        state => state.equipment.equipmentDetails[equipmentID]
    );

    return details;
};

export const useEquipmentList = () => {
    const details = useSelector<RootState, RootState['equipment']['equipmentList']>(
        state => state.equipment.equipmentList
    );
    return details;
};

export const useEquipmentListLoaded = () => {
    const loaded = useSelector<RootState, RootState['equipment']['loaded']>(
        state => state.equipment.loaded
    );
    return loaded;
};

export const useEquipmentListLength = () => {
    const details = useSelector<RootState, RootState['equipment']['equipmentListCount']>(
        state => state.equipment.equipmentListCount
    );
    return details;
};

export const useEquipmentSchema = () => {
    const schema = useSelector<RootState, RootState['options']['equipmentTableFilterOptions']>(
        state => state.options.equipmentTableFilterOptions
    );

    return schema;
};

export const useError = () => {
    const error = useSelector<
        RootState,
        { error: RootState['equipment']['error']; message: RootState['equipment']['message'] }
    >(state => ({ error: state.equipment.error, message: state.equipment.message }));

    return error;
};

export const useEquipmentLoading = () => {
    const loading = useSelector<RootState, RootState['equipment']['loading']>(
        state => state.equipment.loading
    );

    return loading;
};

export const useSaved = () => {
    const error = useSelector<
        RootState,
        { error: RootState['equipment']['error']; message: RootState['equipment']['message'] }
    >(state => ({ error: state.equipment.error, message: state.equipment.message }));
    const saved = useSelector<RootState, RootState['equipment']['saved']>(
        state => state.equipment.saved
    );

    return { ...error, saved };
};

export const useEquipmentReplacementCost = () => {
    const totalReplacementCost = useSelector<
        RootState,
        RootState['equipment']['totalReplacementCost']
    >(state => state.equipment.totalReplacementCost);

    return totalReplacementCost;
};

export const useAttachmentDetails = () => {
    const attachment = useSelector<RootState, RootState['equipment']['attachment']>(
        state => state.equipment.attachment
    );
    return attachment;
};

export const useEquipmentOverrideError = () => {
    const error = useSelector<RootState, RootState['equipment']['override']['errorMessage']>(
        state => state.equipment.override.errorMessage
    );
    return error;
};
