import { Auth } from 'aws-amplify';
import { RootState } from 'store/state';
import { ThunkDispatch } from 'redux-thunk';
import { appRedirect } from 'store/app/app.actions';
import { EnhancedTableFilterI } from 'components/organisms/EnhancedTableFilter';
import { needsUpdate } from 'utils';
import { TableFilterSchema } from 'components/organisms/EnhancedTableFilter/schemas';
import actions from 'store/actions';
import { crewAPI, CrewRequestConfig } from 'utils/API';
import MEPOverrideTypes, { MEPOverrideI, MEPOverrideListItemI } from './mepOverrides.types';
import { MEPOverridesDetailsI } from './mepOverrides.state';

interface MEPError {
    message: string;
}

interface ListEquipSuccessPayload {
    records: MEPOverrideListItemI[];
    totalRecordCount?: number;
    totalReplacementCost?: string;
    filters?: TableFilterSchema[];
}

interface ListMepSystemOverridesSuccessPayload {
    mepSystem: string;
    records: MEPOverrideListItemI[];
}

export type MEPOverridesAction =
    | { type: typeof MEPOverrideTypes.GET_MEP_OVERRIDES_FAIL; payload: MEPError }
    | { type: typeof MEPOverrideTypes.GET_MEP_OVERRIDES_LOADING }
    | {
          type: typeof MEPOverrideTypes.GET_MEP_OVERRIDES_SUCCESS;
          payload: { mepOverride: MEPOverrideI[] };
      }
    | {
          type: typeof MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_FAIL;
          payload: {
              [key: string]: {
                  mepOverride: MEPOverrideListItemI;
                  errorMessage?: string;
              };
          };
      }
    | {
          type: typeof MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_SUCCESS;
          payload: { [key: string]: MEPOverridesDetailsI };
      }
    | {
          type: typeof MEPOverrideTypes.LIST_MEP_OVERRIDES_FAIL;
          payload: {
              errorMessage: string;
          };
      }
    | {
          type: typeof MEPOverrideTypes.LIST_MEP_OVERRIDES_SUCCESS;
          payload: ListEquipSuccessPayload;
      }
    | { type: typeof MEPOverrideTypes.CREATE_MEP_OVERRIDES_LOADING }
    | { type: typeof MEPOverrideTypes.CREATE_MEP_OVERRIDES_SUCCESS }
    | {
          type: typeof MEPOverrideTypes.CREATE_MEP_OVERRIDES_FAIL;
          payload: { errorMessage: string };
      }
    | {
          type: typeof MEPOverrideTypes.GET_MEP_SYSTEM_OVERRIDES_SUCCESS;
          payload: ListMepSystemOverridesSuccessPayload;
      }
    | {
          type: typeof MEPOverrideTypes.SET_MEP_OVERRIDES_MAP_SUCCESS;
      }
    | {
          type: typeof MEPOverrideTypes.SET_MEP_OVERRIDES_MAP_ERROR;
          payload: { errorMessage: string };
      };
/*
interface GetMEPOverrideI {
    records: MEPOverrideListItemI[];
    totalRecords: {
        totalRecordCount: number;
    };
    filters?: { [key: string]: (string | null)[] };
}
*/
type GetMEPOverrideI = MEPOverrideListItemI[];

export const getMEPOverrideDetails = (mepOverridesID: string, force?: boolean) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>,
        getState: () => RootState
    ) => {
        const rootState = getState();
        if (rootState.mepOverrides.mepOverridesDetails[mepOverridesID] && !force) {
            return;
        }
        try {
            console.log('getting override ', mepOverridesID);

            const getRequestConfig: CrewRequestConfig = {
                headers: {
                    filter: JSON.stringify({
                        omp_id: mepOverridesID,
                    }),
                },
                path: `overrides/mep`,
            };

            const data = await crewAPI.get<GetMEPOverrideI>(getRequestConfig);

            const detailsObj: {
                [key: string]: {
                    mepOverride: MEPOverrideListItemI;
                };
            } = {};

            data.forEach(override => {
                detailsObj[override.omp_id] = { mepOverride: override };
            });

            dispatch({
                type: MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_SUCCESS,
                payload: detailsObj,
            });
        } catch (error) {
            console.log('error api', error);
            dispatch({
                type: MEPOverrideTypes.GET_MEP_OVERRIDES_DETAILS_FAIL,
                payload: {
                    [mepOverridesID]: {
                        mepOverride: {} as MEPOverrideListItemI,
                        errorMessage: `Error getting the details for a mepOverrides`,
                    },
                },
            });
        }
    };
};

interface ListMEPOverridesProps {
    sort: {
        field: string;
        ascdesc: 'asc' | 'desc';
    };
    filters: EnhancedTableFilterI[];
    limit: number;
    skip: number;
}
export const listMEPOverrides = ({ sort, filters, limit, skip }: ListMEPOverridesProps) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>) => {
        dispatch({
            type: MEPOverrideTypes.GET_MEP_OVERRIDES_LOADING,
        });
        const filter = enhancedTableFiltersToApiFilters(filters);

        console.log('HEADERS', { sort, filter, limit, skip });

        const getRequestConfig: CrewRequestConfig = {
            headers: {
                // sort: JSON.stringify(sort),
                filter,
                // limit,
                // skip,
            },
            path: `overrides/mep`,
        };

        const data = await crewAPI.get<GetMEPOverrideI>(getRequestConfig);

        console.log('data in list equip', data);

        const payload: ListEquipSuccessPayload = {
            records: data,
            totalRecordCount: data.length,
        };

        /*
        if (data.filters) {
            const options = Object.entries(data.filters).map(([key, values]) => {
                return {
                    COLUMN_NAME: key,
                    COLUMN_LABEL: key.slice(4), //  toolTipTranslations[key], // replace when we get
                    COLUMN_TYPE: 'option',
                    IS_NULLABLE: values.includes(null),
                    OPTIONS: values.filter(value => typeof value == 'string') as string[],
                };
            });

            payload.filters = options;
        }
        */

        dispatch({
            type: MEPOverrideTypes.LIST_MEP_OVERRIDES_SUCCESS,
            payload,
        });
    };
};

const enhancedTableFiltersToApiFilters = (filters: EnhancedTableFilterI[]) => {
    // TODO add logic for the comparitor opperations.
    const apiFilters: { [key: string]: (string | number)[] } = {};
    filters.map(({ field, operation, value }) => {
        switch (operation.value) {
            case 'eq':
                apiFilters[field.COLUMN_NAME] = value;
        }
        return null;
    });

    return JSON.stringify(apiFilters);
};

export const createOrUpdateMEPOverride = (mepOverridesData: MEPOverrideListItemI) => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>,
        getState: () => RootState
    ) => {
        dispatch({
            type: MEPOverrideTypes.CREATE_MEP_OVERRIDES_LOADING,
        });

        const state = getState();
        // const isUpdate = mepOverridesData.mepOverrides_id && !!state.mepOverrides.mepOverridesDetails[mepOverridesData.mepOverrides_id]?.mepOverrides;

        try {
            const omp_id = await updateMEPOverride({ mepOverridesData, state, dispatch });

            if (omp_id) {
                await dispatch(getMEPOverrideDetails(omp_id, true));
            }

            dispatch(appRedirect(`/admin/overrides/mep/${omp_id || mepOverridesData.omp_id}`));
        } catch (error) {
            console.log('error in the mepOverrides create or update promise all', error);
            dispatch({
                type: MEPOverrideTypes.CREATE_MEP_OVERRIDES_FAIL,
                payload: {
                    errorMessage: 'Failed to save this Override',
                },
            });
        }
    };
};

const updateMEPOverride = async ({
    mepOverridesData,
    state,
    dispatch,
}: {
    mepOverridesData: MEPOverrideListItemI;
    state: RootState;
    dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>;
}) => {
    const isUpdate =
        mepOverridesData.omp_id &&
        !!state.mepOverrides.mepOverridesDetails[mepOverridesData.omp_id]?.mepOverride;

    const cleanData: { [key: string]: string | number } = {};
    Object.entries(mepOverridesData).forEach(([key, value]) => {
        if (key.startsWith('omp')) {
            cleanData[key] = value;
        }
    });

    delete cleanData.omp_updated_date;
    delete cleanData.omp_updated_by;
    delete cleanData.omp_created_date;
    delete cleanData.omp_created_by;

    const needsUpdates = needsUpdate(
        cleanData,
        state.mepOverrides.mepOverridesDetails[mepOverridesData.omp_id]?.mepOverride
    );
    if (!needsUpdates) {
        console.log('no update needed for mepOverrides', cleanData);
        return;
    }

    const options: CrewRequestConfig = {
        data: cleanData,
        method: isUpdate ? 'PUT' : 'POST',
        path: `overrides/mep`,
    };

    const data = await crewAPI.request(options);

    dispatch({
        type: MEPOverrideTypes.CREATE_MEP_OVERRIDES_SUCCESS,
    });
    if (!isUpdate) {
        return data.new_id;
    }
    return cleanData.omp_id as string;
};

export const getOverridesForMEPSystem = (mepSystem: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>) => {
        const options: CrewRequestConfig = {
            path: `overrides/mepsystem/${mepSystem}`,
        };
        const data = await crewAPI.request<GetMEPOverrideI>(options);

        const payload: ListMepSystemOverridesSuccessPayload = {
            mepSystem,
            records: data,
        };
        dispatch({
            type: MEPOverrideTypes.GET_MEP_SYSTEM_OVERRIDES_SUCCESS,
            payload,
        });
    };
};

export const saveMEPOverrides = ({
    mepID,
    overrideIDArray,
    existingOverrideArray,
}: {
    mepID: string;
    overrideIDArray: string[];
    existingOverrideArray: string[];
}) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, MEPOverridesAction>) => {
        await Auth.currentSession();

        try {
            const newOverrideArray = overrideIDArray.filter(
                id => !existingOverrideArray.includes(id)
            );
            const removeOverrideArray = existingOverrideArray.filter(
                id => !overrideIDArray.includes(id)
            );

            console.log({ newOverrideArray, removeOverrideArray });

            const promiseArray = [
                ...newOverrideArray.map(id =>
                    crewAPI.post({
                        path: `overrides/mep/map`,
                        data: {
                            oem_mep_id: mepID,
                            oem_omp_id: id,
                        },
                    })
                ),
                ...removeOverrideArray.map(id =>
                    crewAPI.delete({
                        path: `overrides/mep/map`,
                        data: { oem_mep_id: mepID, oem_omp_id: id },
                    })
                ),
            ];
            await Promise.all(promiseArray);

            dispatch(actions.mep.getMEPDetails(mepID));

            return;
        } catch (error) {
            console.error('error updating override for MEP', error);
            dispatch({
                type: MEPOverrideTypes.SET_MEP_OVERRIDES_MAP_ERROR,
                payload: {
                    errorMessage: 'Failed to save this Override',
                },
            });
        }
    };
};
