import React, { useState } from 'react';

import RadioButtonsGroup from 'components/atoms/RadioGroup';
import { TextField, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { InspectionI } from 'store/inspections/inspections.types';
import { createInspection } from 'store/inspections/inspections.actions';
import { Link } from 'react-router-dom';
import hooks from 'hooks';

interface Props {
    equipmentID: string;
    editMode?: boolean;
    inspectionDetails: InspectionI;
}

const InspectionDetails: React.FC<Props> = ({ equipmentID, editMode, inspectionDetails }) => {
    const dispatch = useDispatch();

    const classes = hooks.style.useGlobalStyles();

    const [details, setDetails] = useState(inspectionDetails);

    const detailsChanges = (field: string, value: any) => {
        setDetails({ ...details, [field]: value });
    };

    const onSave = () => {
        dispatch(createInspection(details));
    };

    return (
        <div className="InspectionDetails">
            <form
                className={`${classes.root} MuiPaper-elevation1 inspectionCreateForm`}
                noValidate
                autoComplete="off"
            >
                <h3>Status Update</h3>
                <div className="inspectionCreateFormDiv">
                    <RadioButtonsGroup
                        name="lifecycleStatus"
                        value={details.ins_condition}
                        setValue={(status: string) => {
                            if (!editMode) return;
                            detailsChanges('ins_condition', status);
                        }}
                        label="Lifecycle Status"
                        options={[
                            { value: 'Excellent', label: 'Excellent' },
                            { value: 'Good', label: 'Good' },
                            { value: 'Fair', label: 'Fair' },
                            { value: 'Poor', label: 'Poor' },
                            // { value: 'EndOfLife', label: 'End of Life' },
                        ]}
                    />
                    <TextField
                        id="standard-multiline-static"
                        label="Notes"
                        multiline
                        rows={8}
                        required
                        disabled={!editMode}
                        placeholder="Placeholder"
                        value={details.ins_notes}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            detailsChanges('ins_notes', event?.target?.value || '');
                        }}
                    />
                </div>
                {editMode ? (
                    <div className="bottomDetailButtons">
                        <Button variant="contained" color="primary" onClick={onSave}>
                            Save
                        </Button>
                        <Link
                            to={
                                inspectionDetails.ins_id
                                    ? `/assets/inspections/${inspectionDetails.ins_id}`
                                    : `/assets/equipment/${equipmentID}`
                            }
                        >
                            <Button variant="contained" color="secondary">
                                Cancel
                            </Button>
                        </Link>
                    </div>
                ) : (
                    <div className="bottomDetailButtons">
                        <Link to={`/assets/inspections/${inspectionDetails.ins_id}/edit`}>
                            <Button variant="contained" color="primary">
                                Edit
                            </Button>
                        </Link>
                    </div>
                )}
            </form>
        </div>
    );
};

export default InspectionDetails;
