import React, { useEffect, ReactChild } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SimpleAccordion from 'components/atoms/SimpleAccordion';

import './inspectionCreate.scss';
import { InspectionI } from 'store/inspections/inspections.types';
import hooks from 'hooks';
import InspectionDetails from 'components/molecules/InspectionDetails';
import { getEquipmentDetails } from 'store/equipment/equipment.actions';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';

type Props = RouteComponentProps<{ equipmentID: string }>;

const InspectionCreate: React.FC<Props> = ({ match }) => {
    const dispatch = useDispatch();
    const loadingState = hooks.inspections.useInspectionLoadingState();

    const { equipmentID } = match.params;

    useEffect(() => {
        dispatch(getEquipmentDetails(equipmentID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const equipmentDetails = hooks.equipment.useEquipmentDetails(equipmentID);
    const equipment = equipmentDetails?.equipment || {};

    const loading =
        Object.keys(equipmentDetails?.equipment || {}).length === 0 || loadingState.loading;

    console.log({ loading, equipment: equipmentDetails?.equipment });

    const inspectionDefaults: InspectionI = {
        ins_equ_id: equipmentID,
        ins_condition: 'endOfLife',
        ins_notes: '',
        ins_id: '',
        ins_created_by: '',
        ins_created_date: new Date(),
    };

    const sections: {
        children: ReactChild;
        title: string;
        expanded?: boolean;
        ID?: string;
    }[] = equipment
        ? [
              {
                  title: 'Building Details',
                  expanded: true,
                  children: (
                      <div className="buildingDetailSummary">
                          <TextField
                              id="bld_name"
                              label="Building Name"
                              defaultValue={equipment.bld_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="reg_name"
                              label="Region"
                              defaultValue={equipment.reg_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="dis_name"
                              label="Sub Region"
                              defaultValue={equipment.dis_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="sec_name"
                              label="Facility Mgt"
                              defaultValue={equipment.sec_name}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="dis_name"
                              label="Address"
                              defaultValue={`${equipment.bld_address_line1}, ${equipment.bld_city}, ${equipment.bld_zip} `}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="bld_leased_owned"
                              label="Ownership"
                              defaultValue={equipment.bld_leased_owned}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                      </div>
                  ),
              },
              {
                  title: 'Equipment Details',
                  expanded: true,
                  children: (
                      <div className="equipmentDetailSummary">
                          <TextField
                              id="equ_id"
                              label="Equipment Name"
                              defaultValue={equipment.equ_id}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="equ_mep_id"
                              label="MEP ID"
                              defaultValue={equipment.equ_mep_id}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="mep_description"
                              label="Equipment Description"
                              defaultValue={equipment.mep_description}
                              InputProps={{
                                  readOnly: true,
                              }}
                          />
                          <TextField
                              id="total_replacement_cost"
                              label="Total Replacement Cost"
                              defaultValue={
                                  equipment.total_replacement_cost
                                      ? equipment.total_replacement_cost.toFixed(2)
                                      : 0
                              }
                              InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                      <InputAdornment position="start">$</InputAdornment>
                                  ),
                              }}
                          />
                      </div>
                  ),
              },
          ]
        : [];

    const errorAndLoading = () => (
        <>
            {loading ? (
                <div>
                    <CircularProgress />
                </div>
            ) : null}
            {loadingState.error ? (
                <div>
                    <span>{loadingState.message}</span>
                </div>
            ) : null}
        </>
    );

    if (loadingState.loaded && loadingState.currentInspection) {
        return <Redirect to={`/assets/inspections/${loadingState.currentInspection}`} />;
    }

    return (
        <div className="">
            <h2>Create an Inspection</h2>

            <InspectionDetails
                equipmentID={equipmentID}
                inspectionDetails={inspectionDefaults}
                editMode
            />

            {errorAndLoading()}
            {loading ? null : <SimpleAccordion sections={sections} />}
        </div>
    );
};

export default InspectionCreate;
