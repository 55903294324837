import EquipmentTemplateState from './equipmentTemplate.state';
import { EquipmentTemplateAction } from './equipmentTemplate.actions';
import EquipmentTemplateActionTypes, { defaultEquipmentTemplate } from './equipmentTemplate.types';

export const defaultState: EquipmentTemplateState = {
    current: {
        template: defaultEquipmentTemplate,
        isLoading: false,
        loadingError: undefined,
        isSaving: false,
        savingError: undefined,
    },
    templateList: {
        templates: [],
        isLoading: false,
        loadingError: undefined,
    },
};

const equipmentTemplateReducer = (
    state: EquipmentTemplateState = defaultState,
    action: EquipmentTemplateAction
): EquipmentTemplateState => {
    switch (action.type) {
        case EquipmentTemplateActionTypes.GET_TEMPLATE_LOADING:
            return {
                ...state,
                current: {
                    ...state.current,
                    template: defaultEquipmentTemplate,
                    isLoading: true,
                    loadingError: undefined,
                },
            };
        case EquipmentTemplateActionTypes.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    template: action.payload.template,
                    isLoading: false,
                },
            };
        case EquipmentTemplateActionTypes.GET_TEMPLATE_FAIL:
            return {
                ...state,
                current: {
                    ...state.current,
                    isLoading: false,
                    loadingError: action.payload.error,
                },
            };
        case EquipmentTemplateActionTypes.SAVE_TEMPLATE_LOADING:
            return {
                ...state,
                current: {
                    ...state.current,
                    isSaving: true,
                    savingError: undefined,
                },
            };
        case EquipmentTemplateActionTypes.SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    isSaving: false,
                    template: action.payload.template,
                },
            };
        case EquipmentTemplateActionTypes.SAVE_TEMPLATE_FAIL:
            return {
                ...state,
                current: {
                    ...state.current,
                    isSaving: false,
                    savingError: action.payload.error,
                },
            };
        case EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_LOADING:
            return {
                ...state,
                templateList: {
                    ...state.templateList,
                    templates: [],
                    isLoading: true,
                    loadingError: undefined,
                },
            };
        case EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                templateList: {
                    ...state.templateList,
                    templates: action.payload.templates,
                    isLoading: false,
                },
            };
        case EquipmentTemplateActionTypes.GET_TEMPLATE_LIST_FAIL:
            return {
                ...state,
                templateList: {
                    ...state.templateList,
                    isLoading: false,
                    loadingError: action.payload.error,
                },
            };
        case EquipmentTemplateActionTypes.RESET_TEMPLATE_LIST:
            return {
                ...state,
                templateList: defaultState.templateList,
            };
        default:
            return state;
    }
};

export default equipmentTemplateReducer;
