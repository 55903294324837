import {
    Store,
    createStore,
    applyMiddleware,
    MiddlewareAPI,
    Dispatch,
    compose,
    combineReducers,
} from 'redux';
import reduxThunk from 'redux-thunk';

import { persistReducer, persistStore, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import appReducer from './app/app.reducer';
import authReducer from './auth/auth.reducer';
import buildingsReducer from './buildings/buildings.reducer';
import inspectionsReducer from './inspections/inspections.reducer';
import equipmentsReducer from './equipment/equipment.reducer';
import equipmentTemplateReducer from './equipmentTemplate/equipmentTemplate.reducer';
import optionsReducer from './options/options.reducer';
import mepReducer from './mep/mep.reducer';
import mepOverridesReducer from './mepOverrides/mepOverrides.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import cityStatesReducer from './cityStateOverrides/cityStateOverrides.reducer';
import { RootState } from './state';
import { usersReducer } from './users/users.reducer';
import { rolesReducer } from './roles/roles.reducer';
import adminSettingsReducer from './adminSettings/adminSettings.reducer';
import AdminSettingsState from './adminSettings/adminSettings.state';
import { AdminSettingsAction } from './adminSettings/adminSettings.actions';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const middlewares = [reduxThunk];

export type MiddlewareStore = MiddlewareAPI<Dispatch, RootState>;

export function configureStore(initialState?: RootState): {
    store: Store<RootState>;
    persistor: Persistor;
} {
    const middleware = applyMiddleware(...middlewares);

    const rootReducer = combineReducers<RootState>({
        app: appReducer,
        user: userReducer,
        auth: authReducer,
        buildings: buildingsReducer,
        equipment: equipmentsReducer,
        equipmentTemplate: equipmentTemplateReducer,
        inspections: inspectionsReducer,
        options: optionsReducer,
        mep: mepReducer,
        mepOverrides: mepOverridesReducer,
        dashboard: dashboardReducer,
        cityState: cityStatesReducer,
        users: usersReducer,
        roles: rolesReducer,
        adminSettings: persistReducer<AdminSettingsState, AdminSettingsAction>(
            {
                key: 'adminSettings',
                storage,
            },
            adminSettingsReducer
        ),
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(middleware)
    ) as Store<RootState>;

    const persistor = persistStore(store);

    return { store, persistor };
}
