import OMPFullDetails from 'components/template/OMPFullDetails';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MEPOverrideListItemI } from 'store/mepOverrides/mepOverrides.types';

type Props = RouteComponentProps<{ ompID: string; edit?: string }>;

const MEPOverrideCreate: React.FC<Props> = () => {
    const details = {
        omp_multiplier: '0',
        omp_fixed_amount: '0',
    } as MEPOverrideListItemI;

    return (
        <div className="mepOverrideDetails">
            <h1>MEP Override Details</h1>
            <OMPFullDetails editMode details={details} create />
        </div>
    );
};

export default MEPOverrideCreate;
