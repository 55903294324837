import { useSelector } from 'react-redux';
import { RootState } from '../store/state';

export const useBuildingState = () =>
    useSelector<RootState, RootState['buildings']>(state => state.buildings);

export const useBuildingList = () => {
    const list = useSelector<RootState, RootState['buildings']['buildings']>(state => {
        return state.buildings.buildings || [];
    });
    return list;
};

export const useBuildingListLength = () => {
    const list = useSelector<RootState, RootState['buildings']['buildings']>(state => {
        return state.buildings.buildings || [];
    });

    return list.length;
};

export const useBuildingSchema = () => {
    const schema = useSelector<RootState, RootState['options']['buildingTableFilterOptions']>(
        state => state.options.buildingTableFilterOptions
    );

    return schema;
};

export const useOfflineUploadState = () => {
    const state = useSelector<RootState, RootState['buildings']['offlineSync']>(
        state => state.buildings.offlineSync
    );

    return state;
};
