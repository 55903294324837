import { makeStyles } from '@material-ui/core';
import React from 'react';

import './BarChartRow.scss';

export interface BarChartRowProps {
    percentage: number;
    title: string;
    count?: string;
    color: string;
    onClick?: () => void;
}

const BarChartRow: React.FC<BarChartRowProps> = ({ percentage, title, count, color, onClick }) => {
    const useStyles = makeStyles({
        barFill: {
            width: (props: BarChartRowProps) => `${props.percentage * 0.8}%`,
            background: props => props.color,
        },
        barChartBarContainer: {
            '&:hover': {
                cursor: (props: BarChartRowProps) => (props.onClick ? 'pointer' : 'auto'),
            },
        },
    });
    const classes = useStyles({ percentage, title, count, color, onClick });

    return (
        <div className="barChartRow">
            <span className="barChatRowLabel">{title}:</span>

            <div
                className={`${classes.barChartBarContainer} barChartBarContainer`}
                onClick={onClick}
            >
                <div className={`${classes.barFill} barFill`} />
                <span className="barCount">{count || ''}</span>
            </div>
        </div>
    );
};

export default BarChartRow;
