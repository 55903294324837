import { BuildingsAction } from 'store/buildings/buildings.actions';
import { MEPAction } from './mep.actions';
import MEPState from './mep.state';
import MEPTypes from './mep.types';

const defaultState: MEPState = {
    error: false,
    message: '',
    loading: false,
    loaded: false,

    mepDetails: {},
    mepList: [],
    mepListCount: 0,
};

const mepReducer = (
    state: MEPState = defaultState,
    action: MEPAction | BuildingsAction
): MEPState => {
    switch (action.type) {
        case MEPTypes.GET_MEP_LOADING:
            return { ...state, loading: true, loaded: false };
        case MEPTypes.GET_MEP_SUCCESS:
            return { ...state, ...action.payload, error: false, loading: false, loaded: true };
        case MEPTypes.GET_MEP_FAIL:
            return { ...defaultState, ...action.payload, error: true };
        case MEPTypes.GET_MEP_DETAILS_SUCCESS:
            return { ...state, mepDetails: { ...state.mepDetails, ...action.payload } };
        case MEPTypes.GET_MEP_DETAILS_FAIL:
            return { ...state, mepDetails: { ...state.mepDetails, ...action.payload } };

        case MEPTypes.LIST_MEP_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const mepDetails = { ...state.mepDetails };
            action.payload.records.forEach(record => {
                mepDetails[record.mep_id] = { mep: record };
            });

            return {
                ...state,
                mepDetails,
                mepList: [...action.payload.records],
                loaded: true,
                mepListCount:
                    typeof action.payload?.totalRecordCount === 'number'
                        ? action.payload.totalRecordCount
                        : state.mepListCount,
            };

        default:
            return state;
    }
};

export default mepReducer;
