import React, { useState } from 'react';

import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, IconButton, Toolbar, Tooltip } from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';

import './EnhancedTableToolbar.scss';
import EnhancedTableFilter, {
    EnhancedTableFilterProps,
} from 'components/organisms/EnhancedTableFilter';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
    })
);

export interface EnhancedTableToolbarProps {
    tableTitle?: string;
    filterParams?: EnhancedTableFilterProps;
}

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = props => {
    const classes = useToolbarStyles();
    const { tableTitle, filterParams } = props;

    const [openFilter, setOpenFilter] = useState(
        filterParams?.filters ? filterParams.filters.length > 0 : false
    );

    return (
        <Toolbar className={`${clsx(classes.root)} enhancedTableToolbar`}>
            <div className="titleRow">
                {tableTitle ? (
                    <Typography
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {tableTitle}
                    </Typography>
                ) : (
                    <div />
                )}

                {filterParams ? (
                    <Tooltip title="Filter list">
                        <IconButton
                            aria-label="filter list"
                            onClick={() => {
                                setOpenFilter(!openFilter);
                            }}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
            {openFilter && filterParams ? <EnhancedTableFilter {...filterParams} /> : ''}
        </Toolbar>
    );
};
