enum MEPOverrideTypes {
    GET_MEP_OVERRIDES_FAIL = 'GET_MEP_OVERRIDES_FAIL',
    GET_MEP_OVERRIDES_LOADING = 'GET_MEP_OVERRIDES_LOADING',
    GET_MEP_OVERRIDES_SUCCESS = 'GET_MEP_OVERRIDES_SUCCESS',

    GET_MEP_OVERRIDES_DETAILS_SUCCESS = 'GET_MEP_OVERRIDES_DETAILS_SUCCESS',
    GET_MEP_OVERRIDES_DETAILS_FAIL = 'GET_MEP_OVERRIDES_DETAILS_FAIL',

    LIST_MEP_OVERRIDES_SUCCESS = 'LIST_MEP_OVERRIDES_SUCCESS',
    LIST_MEP_OVERRIDES_FAIL = 'LIST_MEP_OVERRIDES_FAIL',

    CREATE_MEP_OVERRIDES_LOADING = 'CREATE_MEP_OVERRIDES_LOADING',
    CREATE_MEP_OVERRIDES_SUCCESS = 'CREATE_MEP_OVERRIDES_SUCCESS',
    CREATE_MEP_OVERRIDES_FAIL = 'CREATE_MEP_OVERRIDES_FAIL',

    GET_MEP_SYSTEM_OVERRIDES_SUCCESS = 'GET_MEP_SYSTEM_OVERRIDES_SUCCESS',

    SET_MEP_OVERRIDES_MAP_SUCCESS = 'SET_MEP_OVERRIDES_MAP_SUCCESS',
    SET_MEP_OVERRIDES_MAP_ERROR = 'SET_MEP_OVERRIDES_MAP_ERROR',
}

export default MEPOverrideTypes;

export interface MEPOverrideListItemI {
    omp_id: string;
    omp_mep_system: string;
    omp_description: string;
    omp_multiplier: string;
    omp_fixed_amount: string;
    omp_created_by: string;
    omp_created_date: string;
    omp_updated_by: string;
    omp_updated_date: string;
}

export interface MEPOverrideI {
    omp_id: string;
    omp_mep_system: string;
    omp_description: string;
    omp_multiplier: string;
    omp_fixed_amount: string;
    omp_created_by: string;
    omp_created_date: string;
    omp_updated_by: string;
    omp_updated_date: string;
}

export type MEPCreateI = MEPOverrideI;
