const tipTranslations = () => {
    const trans: { [key: string]: string } = {};

    fieldArr.forEach(({ fieldname, label }) => {
        trans[fieldname] = label;
    });

    return trans;
};

export default tipTranslations;

const fieldArr = [
    {
        fieldname: 'bld_sec_id',
        label: 'sec id',
        tooltip: 'Building sec id',
    },
    {
        fieldname: 'bld_id',
        label: 'bld id',
        tooltip: 'Unique id for Building record',
    },
    {
        fieldname: 'bld_responder_code',
        label: 'responder code',
        tooltip: 'Building responder code',
    },
    {
        fieldname: 'bld_glc',
        label: 'glc',
        tooltip: 'Building glc',
    },
    {
        fieldname: 'bld_name',
        label: 'name',
        tooltip: 'Building name',
    },
    {
        fieldname: 'bld_address_line1',
        label: 'address line1',
        tooltip: 'Building address line1',
    },
    {
        fieldname: 'bld_address_line2',
        label: 'address line2',
        tooltip: 'Building address line2',
    },
    {
        fieldname: 'bld_city',
        label: 'city',
        tooltip: 'Building city',
    },
    {
        fieldname: 'bld_state',
        label: 'state',
        tooltip: 'Building state',
    },
    {
        fieldname: 'bld_zip',
        label: 'zip',
        tooltip: 'Building zip',
    },
    {
        fieldname: 'bld_date_added',
        label: 'date added',
        tooltip: 'Building date added',
    },
    {
        fieldname: 'bld_sr_portfolio_manager',
        label: 'sr portfolio manager',
        tooltip: 'Building sr portfolio manager',
    },
    {
        fieldname: 'bld_portfolio_manager',
        label: 'portfolio manager',
        tooltip: 'Building portfolio manager',
    },
    {
        fieldname: 'bld_facility_manager',
        label: 'facility manager',
        tooltip: 'Building facility manager',
    },
    {
        fieldname: 'bld_leased_owned',
        label: 'leased owned',
        tooltip: 'Building leased owned',
    },
    {
        fieldname: 'bld_lease_type',
        label: 'lease type',
        tooltip: 'Building lease type',
    },
    {
        fieldname: 'bld_document_location_insurance',
        label: 'document location insurance',
        tooltip: 'Building document location insurance',
    },
    {
        fieldname: 'bld_document__location_occupant_roster',
        label: 'document  location occupant roster',
        tooltip: 'Building document  location occupant roster',
    },
    {
        fieldname: 'bld_document_location_lease_abstracts',
        label: 'document location lease abstracts',
        tooltip: 'Building document location lease abstracts',
    },
    {
        fieldname: 'bld_document_location_emergency_contact_list',
        label: 'document location emergency contact list',
        tooltip: 'Building document location emergency contact list',
    },
    {
        fieldname: 'bld_document_location_contract_copies',
        label: 'document location contract copies',
        tooltip: 'Building document location contract copies',
    },
    {
        fieldname: 'bld_document_location_contractor_listing',
        label: 'document location contractor listing',
        tooltip: 'Building document location contractor listing',
    },
    {
        fieldname: 'bld_sf',
        label: 'sf',
        tooltip: 'Building sf',
    },
    {
        fieldname: 'bld_stories_serviced',
        label: 'stories serviced',
        tooltip: 'Building stories serviced',
    },
    {
        fieldname: 'bld_year_build',
        label: 'year build',
        tooltip: 'Building year build',
    },
    {
        fieldname: 'bld_last_renovation_month',
        label: 'last renovation month',
        tooltip: 'Building last renovation month',
    },
    {
        fieldname: 'bld_last_renovation_year',
        label: 'last renovation year',
        tooltip: 'Building last renovation year',
    },
    {
        fieldname: 'bld_active',
        label: 'active',
        tooltip: 'Building active',
    },
    {
        fieldname: 'bld_tags',
        label: 'tags',
        tooltip: 'Building tags',
    },
    {
        fieldname: 'bld_created_by',
        label: 'created by',
        tooltip: 'User that created Building record',
    },
    {
        fieldname: 'bld_created_date',
        label: 'created date',
        tooltip: 'Date Building record created',
    },
    {
        fieldname: 'bld_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Building record',
    },
    {
        fieldname: 'bld_updated_date',
        label: 'updated date',
        tooltip: 'Date Building record last updated',
    },
    {
        fieldname: 'equ_bld_id',
        label: 'bld id',
        tooltip: 'Equipment bld id',
    },
    {
        fieldname: 'equ_id',
        label: 'Raw Equipment ID',
        tooltip: 'Unique id for Equipment record',
    },
    {
        fieldname: 'equ_alias',
        label: 'alias',
        tooltip: 'Equipment alias',
    },
    {
        fieldname: 'equ_description',
        label: 'description',
        tooltip: 'Equipment description',
    },
    {
        fieldname: 'equ_floor',
        label: 'floor',
        tooltip: 'Equipment floor',
    },
    {
        fieldname: 'equ_room',
        label: 'room',
        tooltip: 'Equipment room',
    },
    {
        fieldname: 'equ_mfg',
        label: 'mfg',
        tooltip: 'Equipment mfg',
    },
    {
        fieldname: 'equ_model',
        label: 'model',
        tooltip: 'Equipment model',
    },
    {
        fieldname: 'equ_vendor',
        label: 'vendor',
        tooltip: 'Equipment vendor',
    },
    {
        fieldname: 'equ_specs',
        label: 'specs',
        tooltip: 'Equipment specs',
    },
    {
        fieldname: 'equ_uses_refrigerant',
        label: 'uses refrigerant',
        tooltip: 'Equipment uses refrigerant',
    },
    {
        fieldname: 'equ_refrigerant_type',
        label: 'refrigerant type',
        tooltip: 'Equipment refrigerant type',
    },
    {
        fieldname: 'equ_dr_rate_percent',
        label: 'dr rate percent',
        tooltip: 'Equipment dr rate percent',
    },
    {
        fieldname: 'equ_ton',
        label: 'ton',
        tooltip: 'Equipment ton',
    },
    {
        fieldname: 'equ_mep_id',
        label: 'mep id',
        tooltip: 'Equipment mep id',
    },
    {
        fieldname: 'equ_business_id',
        label: 'Equipment ID',
        tooltip: 'Equipment ID',
    },

    {
        fieldname: 'equ_mep_quantity',
        label: 'mep quantity',
        tooltip: 'Equipment mep quantity',
    },
    {
        fieldname: 'equ_active',
        label: 'active',
        tooltip: 'Equipment active',
    },
    {
        fieldname: 'equ_in_big',
        label: 'in big',
        tooltip: 'Equipment in big',
    },
    {
        fieldname: 'equ_system_category',
        label: 'system category',
        tooltip: 'Equipment system category',
    },
    {
        fieldname: 'equ_system_name',
        label: 'system name',
        tooltip: 'Equipment system name',
    },
    {
        fieldname: 'equ_btu',
        label: 'btu',
        tooltip: 'Equipment btu',
    },
    {
        fieldname: 'equ_hp',
        label: 'hp',
        tooltip: 'Equipment hp',
    },
    {
        fieldname: 'equ_volt_amp',
        label: 'volt amp',
        tooltip: 'Equipment volt amp',
    },
    {
        fieldname: 'equ_gal',
        label: 'gal',
        tooltip: 'Equipment gal',
    },
    {
        fieldname: 'equ_other',
        label: 'other',
        tooltip: 'Equipment other',
    },
    {
        fieldname: 'equ_approx_yr_installed',
        label: 'approx yr installed',
        tooltip: 'Equipment approx yr installed',
    },
    {
        fieldname: 'equ_pod',
        label: 'pod',
        tooltip: 'Equipment pod',
    },
    {
        fieldname: 'equ_serial_number',
        label: 'serial number',
        tooltip: 'Equipment serial number',
    },
    {
        fieldname: 'equ_notes',
        label: 'notes',
        tooltip: 'Equipment notes',
    },
    {
        fieldname: 'equ_created_by',
        label: 'created by',
        tooltip: 'User that created Equipment record',
    },
    {
        fieldname: 'equ_created_date',
        label: 'created date',
        tooltip: 'Date Equipment record created',
    },
    {
        fieldname: 'equ_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Equipment record',
    },
    {
        fieldname: 'equ_updated_date',
        label: 'updated date',
        tooltip: 'Date Equipment record last updated',
    },
    {
        fieldname: 'dis_reg_id',
        label: 'Portfolio',
        tooltip: 'Portfolio',
    },
    {
        fieldname: 'dis_id',
        label: 'Region ID',
        tooltip: 'Unique id for Region record',
    },
    {
        fieldname: 'dis_name',
        label: 'Region name',
        tooltip: 'Region name',
    },
    {
        fieldname: 'doc_id',
        label: 'doc id',
        tooltip: 'Unique id for Document record',
    },
    {
        fieldname: 'doc_type',
        label: 'type',
        tooltip: 'Document type',
    },
    {
        fieldname: 'doc_entity_type',
        label: 'entity type',
        tooltip: 'Document entity type',
    },
    {
        fieldname: 'doc_entity_id',
        label: 'entity id',
        tooltip: 'Document entity id',
    },
    {
        fieldname: 'doc_awss3bucket',
        label: 'awss3bucket',
        tooltip: 'Document awss3bucket',
    },
    {
        fieldname: 'doc_description',
        label: 'description',
        tooltip: 'Document description',
    },
    {
        fieldname: 'doc_created_by',
        label: 'created by',
        tooltip: 'User that created Document record',
    },
    {
        fieldname: 'doc_created_date',
        label: 'created date',
        tooltip: 'Date Document record created',
    },
    {
        fieldname: 'doc_update_by',
        label: 'update by',
        tooltip: 'Document update by',
    },
    {
        fieldname: 'doc_updated_date',
        label: 'updated date',
        tooltip: 'Date Document record last updated',
    },
    {
        fieldname: 'img_id',
        label: 'img id',
        tooltip: 'Unique id for Image record',
    },
    {
        fieldname: 'img_description',
        label: 'description',
        tooltip: 'Image description',
    },
    {
        fieldname: 'img_orig_aws_bucket',
        label: 'orig aws bucket',
        tooltip: 'Image orig aws bucket',
    },
    {
        fieldname: 'img_thumbnail_aws_bucket',
        label: 'thumbnail aws bucket',
        tooltip: 'Image thumbnail aws bucket',
    },
    {
        fieldname: 'img_small_aws_bucket',
        label: 'small aws bucket',
        tooltip: 'Image small aws bucket',
    },
    {
        fieldname: 'img_lg_aws_bucket',
        label: 'lg aws bucket',
        tooltip: 'Image lg aws bucket',
    },
    {
        fieldname: 'img_entity_id',
        label: 'entity id',
        tooltip: 'Image entity id',
    },
    {
        fieldname: 'img_entity_type',
        label: 'entity type',
        tooltip: 'Image entity type',
    },
    {
        fieldname: 'img_default',
        label: 'default',
        tooltip: 'Image default',
    },
    {
        fieldname: 'img_created_by',
        label: 'created by',
        tooltip: 'User that created Image record',
    },
    {
        fieldname: 'img_created_date',
        label: 'created date',
        tooltip: 'Date Image record created',
    },
    {
        fieldname: 'img_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Image record',
    },
    {
        fieldname: 'img_updated_date',
        label: 'updated date',
        tooltip: 'Date Image record last updated',
    },
    {
        fieldname: 'img_srcKey',
        label: 'srcKey',
        tooltip: 'Image srcKey',
    },
    {
        fieldname: 'img_contentType',
        label: 'contentType',
        tooltip: 'Image contentType',
    },
    {
        fieldname: 'ins_id',
        label: 'ins id',
        tooltip: 'Unique id for Condition report record',
    },
    {
        fieldname: 'ins_equ_id',
        label: 'equ id',
        tooltip: 'Condition report equ id',
    },
    {
        fieldname: 'ins_condition',
        label: 'condition',
        tooltip: 'Condition report condition',
    },
    {
        fieldname: 'ins_notes',
        label: 'notes',
        tooltip: 'Condition report notes',
    },
    {
        fieldname: 'ins_created_by',
        label: 'created by',
        tooltip: 'User that created Condition report record',
    },
    {
        fieldname: 'ins_created_date',
        label: 'created date',
        tooltip: 'Date Condition report record created',
    },
    {
        fieldname: 'ins_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Condition report record',
    },
    {
        fieldname: 'ins_updated_date',
        label: 'updated date',
        tooltip: 'Date Condition report record last updated',
    },
    {
        fieldname: 'mep_id',
        label: 'mep id',
        tooltip: 'Unique id for MEP (mechanical equipment price) industry standard price record',
    },
    {
        fieldname: 'mep_job_id',
        label: 'job id',
        tooltip: 'MEP (mechanical equipment price) industry standard price job id',
    },
    {
        fieldname: 'mep_category',
        label: 'category',
        tooltip: 'MEP (mechanical equipment price) industry standard price category',
    },
    {
        fieldname: 'mep_system',
        label: 'MEP system',
        tooltip: 'MEP (mechanical equipment price) industry standard price system',
    },
    {
        fieldname: 'mep_description',
        label: 'description',
        tooltip: 'MEP (mechanical equipment price) industry standard price description',
    },
    {
        fieldname: 'mep_type',
        label: 'type',
        tooltip: 'MEP (mechanical equipment price) industry standard price type',
    },
    {
        fieldname: 'mep_capacity',
        label: 'capacity',
        tooltip: 'MEP (mechanical equipment price) industry standard price capacity',
    },
    {
        fieldname: 'mep_capacity_measure',
        label: 'capacity measure',
        tooltip: 'MEP (mechanical equipment price) industry standard price capacity measure',
    },
    {
        fieldname: 'mep_lifecycle',
        label: 'lifecycle',
        tooltip: 'MEP (mechanical equipment price) industry standard price lifecycle',
    },
    {
        fieldname: 'mep_unit_of_measure',
        label: 'unit of measure',
        tooltip: 'MEP (mechanical equipment price) industry standard price unit of measure',
    },
    {
        fieldname: 'mep_cost_per_unit',
        label: 'cost per unit',
        tooltip: 'MEP (mechanical equipment price) industry standard price cost per unit',
    },
    {
        fieldname: 'mep_units',
        label: 'units',
        tooltip: 'MEP (mechanical equipment price) industry standard price units',
    },
    {
        fieldname: 'mep_additional_info',
        label: 'additional info',
        tooltip: 'MEP (mechanical equipment price) industry standard price additional info',
    },
    {
        fieldname: 'mep_created_date',
        label: 'created date',
        tooltip: 'Date MEP (mechanical equipment price) industry standard price record created',
    },
    {
        fieldname: 'mep_created_by',
        label: 'created by',
        tooltip:
            'User that created MEP (mechanical equipment price) industry standard price record',
    },
    {
        fieldname: 'mep_updated_date',
        label: 'updated date',
        tooltip:
            'Date MEP (mechanical equipment price) industry standard price record last updated',
    },
    {
        fieldname: 'mep_updated_by',
        label: 'updated by',
        tooltip:
            'User that last updated MEP (mechanical equipment price) industry standard price record',
    },
    {
        fieldname: 'ocs_id',
        label: 'ocs id',
        tooltip: 'Unique id for Override based on city/state location record',
    },
    {
        fieldname: 'ocs_city',
        label: 'city',
        tooltip: 'Override based on city/state location city',
    },
    {
        fieldname: 'ocs_state',
        label: 'state',
        tooltip: 'Override based on city/state location state',
    },
    {
        fieldname: 'ocs_description',
        label: 'description',
        tooltip: 'Override based on city/state location description',
    },
    {
        fieldname: 'ocs_multiplier',
        label: 'multiplier',
        tooltip: 'Override based on city/state location multiplier',
    },
    {
        fieldname: 'ocs_created_by',
        label: 'created by',
        tooltip: 'User that created Override based on city/state location record',
    },
    {
        fieldname: 'ocs_created_date',
        label: 'created date',
        tooltip: 'Date Override based on city/state location record created',
    },
    {
        fieldname: 'ocs_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Override based on city/state location record',
    },
    {
        fieldname: 'ocs_updated_date',
        label: 'updated date',
        tooltip: 'Date Override based on city/state location record last updated',
    },
    {
        fieldname: 'omp_id',
        label: 'omp id',
        tooltip: 'Unique id for Override based on MEP system and capacity record',
    },
    {
        fieldname: 'omp_mep_system',
        label: 'mep system',
        tooltip: 'Override based on MEP system and capacity mep system',
    },
    {
        fieldname: 'omp_description',
        label: 'description',
        tooltip: 'Override based on MEP system and capacity description',
    },
    {
        fieldname: 'omp_multiplier',
        label: 'multiplier',
        tooltip: 'Override based on MEP system and capacity multiplier',
    },
    {
        fieldname: 'omp_fixed_amount',
        label: 'fixed amount',
        tooltip: 'Override based on MEP system and capacity fixed amount',
    },
    {
        fieldname: 'omp_created_by',
        label: 'created by',
        tooltip: 'User that created Override based on MEP system and capacity record',
    },
    {
        fieldname: 'omp_created_date',
        label: 'created date',
        tooltip: 'Date Override based on MEP system and capacity record created',
    },
    {
        fieldname: 'omp_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Override based on MEP system and capacity record',
    },
    {
        fieldname: 'omp_updated_date',
        label: 'updated date',
        tooltip: 'Date Override based on MEP system and capacity record last updated',
    },
    {
        fieldname: 'oem_mep_id',
        label: 'mep id',
        tooltip: 'MEP-MEP_Override lookup mep id',
    },
    {
        fieldname: 'oem_omp_id',
        label: 'omp id',
        tooltip: 'MEP-MEP_Override lookup omp id',
    },
    {
        fieldname: 'oem_created_by',
        label: 'created by',
        tooltip: 'User that created MEP-MEP_Override lookup record',
    },
    {
        fieldname: 'oem_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated MEP-MEP_Override lookup record',
    },
    {
        fieldname: 'oem_created_date',
        label: 'created date',
        tooltip: 'Date MEP-MEP_Override lookup record created',
    },
    {
        fieldname: 'oem_updated_date',
        label: 'updated date',
        tooltip: 'Date MEP-MEP_Override lookup record last updated',
    },
    {
        fieldname: 'reg_id',
        label: 'Portfolio',
        tooltip: 'Portfolio',
    },
    {
        fieldname: 'reg_name',
        label: 'name',
        tooltip: 'Region name',
    },
    {
        fieldname: 'rol_id',
        label: 'rol id',
        tooltip: 'Unique id for User roles in CREW record',
    },
    {
        fieldname: 'rol_description',
        label: 'description',
        tooltip: 'User roles in CREW description',
    },
    {
        fieldname: 'rol_long_description',
        label: 'long description',
        tooltip: 'User roles in CREW long description',
    },
    {
        fieldname: 'sec_dis_id',
        label: 'dis id',
        tooltip: 'Sector assigned to sub region dis id',
    },
    {
        fieldname: 'sec_id',
        label: 'Sub-Region ID',
        tooltip: 'Unique id for sub region record',
    },
    {
        fieldname: 'sec_name',
        label: 'sub region name',
        tooltip: 'sub region name',
    },
    {
        fieldname: 'url_usr_id',
        label: 'usr id',
        tooltip: 'User-Role lookup usr id',
    },
    {
        fieldname: 'url_rol_id',
        label: 'rol id',
        tooltip: 'User-Role lookup rol id',
    },
    {
        fieldname: 'usr_id',
        label: 'usr id',
        tooltip: 'Unique id for User record',
    },
    {
        fieldname: 'usr_email',
        label: 'email',
        tooltip: 'User email',
    },
    {
        fieldname: 'usr_aws_id',
        label: 'aws id',
        tooltip: 'User aws id',
    },
    {
        fieldname: 'usr_tier_type',
        label: 'tier type',
        tooltip: 'User tier type',
    },
    {
        fieldname: 'usr_tier_id',
        label: 'tier id',
        tooltip: 'User tier id',
    },
    {
        fieldname: 'usr_fname',
        label: 'fname',
        tooltip: 'User fname',
    },
    {
        fieldname: 'usr_lname',
        label: 'lname',
        tooltip: 'User lname',
    },
    {
        fieldname: 'usr_title',
        label: 'title',
        tooltip: 'User title',
    },
    {
        fieldname: 'usr_status',
        label: 'status',
        tooltip: 'User status',
    },
    {
        fieldname: 'usr_phone',
        label: 'phone',
        tooltip: 'User phone',
    },
    {
        fieldname: 'usr_created_by',
        label: 'created by',
        tooltip: 'User that created User record',
    },
    {
        fieldname: 'usr_created_date',
        label: 'created date',
        tooltip: 'Date User record created',
    },
    {
        fieldname: 'usr_updated_date',
        label: 'updated date',
        tooltip: 'Date User record last updated',
    },
    {
        fieldname: 'usr_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated User record',
    },
    {
        fieldname: 'usr_is_system_admin',
        label: 'is system admin',
        tooltip: 'User is system admin',
    },
    {
        fieldname: 'usr_is_user_admin',
        label: 'is user admin',
        tooltip: 'User is user admin',
    },
    {
        fieldname: 'ept_equ_id',
        label: 'equ id',
        tooltip: 'Tags assigned to Equipment equ id',
    },
    {
        fieldname: 'ept_tag',
        label: 'tag',
        tooltip: 'Tags assigned to Equipment tag',
    },
    {
        fieldname: 'ept_created_by',
        label: 'created by',
        tooltip: 'User that created Tags assigned to Equipment record',
    },
    {
        fieldname: 'ept_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Tags assigned to Equipment record',
    },
    {
        fieldname: 'ept_created_date',
        label: 'created date',
        tooltip: 'Date Tags assigned to Equipment record created',
    },
    {
        fieldname: 'ept_updated_date',
        label: 'updated date',
        tooltip: 'Date Tags assigned to Equipment record last updated',
    },
    {
        fieldname: 'emm_equ_id',
        label: 'equ id',
        tooltip: 'Equipment-MEP lookup equ id',
    },
    {
        fieldname: 'emm_mep_id',
        label: 'mep id',
        tooltip: 'Equipment-MEP lookup mep id',
    },
    {
        fieldname: 'emm_created_by',
        label: 'created by',
        tooltip: 'User that created Equipment-MEP lookup record',
    },
    {
        fieldname: 'emm_updated_by',
        label: 'updated by',
        tooltip: 'User that last updated Equipment-MEP lookup record',
    },
    {
        fieldname: 'emm_created_date',
        label: 'created date',
        tooltip: 'Date Equipment-MEP lookup record created',
    },
    {
        fieldname: 'emm_updated_date',
        label: 'updated date',
        tooltip: 'Date Equipment-MEP lookup record last updated',
    },
];
